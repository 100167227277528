import React, { useEffect } from "react";

function GoogleMap() {
  const loadMap = () => {
    if (typeof window !== "undefined") {
      const google = window.google;
      const buenosAires = { lat: -34.6118, lng: -58.4173 };
      const map = new google.maps.Map(document.getElementById("map"), {
        center: buenosAires,
        zoom: 10,
      });

      new google.maps.Marker({
        position: buenosAires,
        map: map,
        title: "Buenos Aires, Argentina",
      });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      loadMap();
    }, 1000);
  }, []);

  return (
    <div
      id="map"
      style={{ width: "100%", height: "400px" }}
      className="google-map"
    />
  );
}

export default GoogleMap;
