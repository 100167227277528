import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Logo } from "../styles/shared";

const Container = styled.footer`
  background: #800040;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  //  border-top: 1px solid #d9d9d9;

  p {
    font-size: 20px;
    font-weight: 600px;
    color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 4px;

    span {
      cursor: pointer;
      font-weight: 700px;
      text-decoration: underline;
      font-size: 22px;
    }
  }

  @media (max-width: 500px) {
    height: 200px;
    margin-top: 48px;
    margin-bottom: 80px;

    p {
      font-size: 14px;
      max-width: 300px;

      span {
        font-size: 16px;
      }
    }
  }
`;

const LogoWrapper = styled.footer`
  img {
    width: 60px;
    cursor: pointer;
  }
`;

const Footer = () => {
  const navigate = useNavigate();

  const redirect = () => {
    navigate("/");
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <Container>
      <p>
        Todos los Derechos Reservados © 2024 <br />
        <LogoWrapper>
          <Logo
            src="https://res.cloudinary.com/dlcgloggr/image/upload/v1697740668/logo-19-10-white_jyxkff.png"
            alt="logo"
            width={16}
            onClick={redirect}
          />
        </LogoWrapper>
      </p>
    </Container>
  );
};

export default Footer;
