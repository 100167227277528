import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { keysToSnake } from "../../helpers/humps";
import { useWindowSize } from "../../hooks/windowHook";
import { detectOS } from "../../utils/home";
import { useAnalytics } from "../../hooks/analyticsHook";

const Container = styled.div<{ featured: boolean }>`
  width: 100%;
  display: ${({ featured }) => (featured ? "block" : "block")};
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  > h1 {
    font-size: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  @keyframes moveTriangle {
    0% {
      left: 0;
    }
    50% {
      left: 6px;
    }
    100% {
      left: 0;
    }
  }
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #800040;
  transform: rotate(90deg);
  margin-right: 10px;
  position: relative;
  animation: moveTriangle 2s linear infinite;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 300px;

  @media (min-width: 500px) {
    height: 400px;
  }

  @media (min-width: 1025px) {
    height: 500px;
  }
`;

const ShareLink = styled.button`
  width: 100%;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: #800040;
  color: #ffffff;
  font-weight: 700;
  margin: 0 auto;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 16px;

  @media (min-width: 500px) {
    width: max-content;
  }
`;

const ModalClipBoard = styled.div`
  width: 150px;
  height: 40px;
  background: #008080;
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
`;

interface MediaProps {
  id: string;
  autoPlay: number;
  featured?: boolean;
  seconds?: number;
  title?: string;
  currentVideo?: any;
}

const Media = ({
  id,
  autoPlay,
  featured = false,
  seconds = 0,
  title,
  currentVideo,
}: MediaProps) => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const playerRef = useRef<any>(null);
  const [isVisibleModalClipBoard, setIsVisibleModalClipBoard] = useState(false);
  const url = new URL(window.location.href);
  const { width } = useWindowSize();
  const isMobile = width <= 500;
  const tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  const copyToClipboard = () => {
    track(
      "Link Copied Music",
      keysToSnake({
        pathName: url?.pathname,
        timeZone: tz,
        mobile: isMobile,
        urlVideo: `${url.origin}/musica?t=${currentVideo?.link}`,
        title,
        os: detectOS(),
      })
    );
    const textArea = document.createElement("textarea");
    textArea.value = `${url.origin}/musica?t=${currentVideo?.link}`;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setIsVisibleModalClipBoard(true);
    setTimeout(() => {
      setIsVisibleModalClipBoard(false);
    }, 2000);
  };

  const redirect = () => {
    navigate("/musica");
  };

  return (
    <Container featured={featured} className="media" id="media-container">
      {featured && (
        <h1 onClick={redirect}>
          <Triangle />
          {title}
        </h1>
      )}
      <Iframe
        ref={playerRef}
        id="iframe-youtube"
        src={`https://www.youtube.com/embed/${id}?autoplay=1&start=${seconds}`}
        title="video"
        style={{ border: "none", borderRadius: "12px", overflow: "hidden" }}
        allow="autoplay"
      />
      <ShareLink onClick={copyToClipboard}>
        Copiar enlace a esta canción
      </ShareLink>
      {isVisibleModalClipBoard && (
        <ModalClipBoard>Enlace copiado</ModalClipBoard>
      )}
    </Container>
  );
};

export default Media;
