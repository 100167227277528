import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/home";
import Music from "./components/music";
import News from "./components/news";
import Shorts from "./components/shorts";
import Contact from "./components/contact";
import WhatsAppLink from "./components/whatsAppLink";
import Bible from "./components/bible";
import Beliefs from "./components/beliefs";
import ModalSuggestion from "./components/suggestionModal/ModalSuggestion";

const modalDisplaySuggestion = [
  10, 60, 100, 140, 180, 220, 260, 300, 340, 380, 420,
];

const App = () => {
  const suggestionQuestionCache =
    typeof window !== "undefined" && localStorage.getItem(`suggestionQuestion`)
      ? JSON.parse(localStorage.getItem(`suggestionQuestion`) || "")
      : false;

  const [isVisibleModalSuggestion, setIsVisibleModalSuggestion] =
    useState(false);

  useEffect(() => {
    localStorage.setItem(
      `suggestionQuestion`,
      JSON.stringify({
        suggestionQuestion:
          (suggestionQuestionCache?.suggestionQuestion || 0) + 1,
      })
    );
  }, []);

  useEffect(() => {
    if (
      modalDisplaySuggestion.includes(
        suggestionQuestionCache?.suggestionQuestion
      )
    ) {
      setIsVisibleModalSuggestion(true);
    }
  }, []);

  return (
    <>
      {isVisibleModalSuggestion && (
        <ModalSuggestion onClose={() => setIsVisibleModalSuggestion(false)} />
      )}
      <WhatsAppLink />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/creencias" element={<Beliefs />} />
          <Route path="/musica" element={<Music />} />
          <Route path="/reflexiones" element={<Shorts />} />
          <Route path="/biblia" element={<Bible />} />
          <Route path="/noticias" element={<News />} />
          <Route path="/contacto" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
