export const shorts = [
  /*   
  {
    title: "",
    text: ``,
    author: "Raúl Enrique",
    date: "11/09/2024",
    id: 226,
  }, 
  */ {
    title: "De la Muerte a la Vida",
    text: `Dice Jesús: "De cierto, de cierto os digo, el que cree en mi palabra y cree al que me envió tiene vida eterna y ha pasado de muerte a vida." ¡Qué maravillosa y categórica afirmación de la promesa de vida eterna para aquellos que creemos en Él! También nos llamó bienaventurados, felices, dichosos o afortunados a los que no me vieron y creyeron en mí. Él debe estar primero en el orden de prioridades cuando dice: "Buscad primeramente el reino de Dios y su justicia, y todas las cosas vendrán por añadidura." Él no desampara a sus hijos, porque sus promesas son fieles y verdaderas. Además, con expectativa de vida familiar y eterna, solo Él da sentido, significado y contenido a esta vida temporaria y pasajera. Bendiciones.`,
    author: "Raúl Enrique",
    date: "17/09/2024",
    id: 229,
  },
  {
    title: "La Bondad del Padre Celestial",
    text: `Dice Jesús: "Pedid y se os dará; buscad y hallaréis; llamad y se os abrirá. Porque, ¿qué padre de vosotros, si su hijo le pide pan, le dará una piedra? O si le pide un pescado, ¿le dará una serpiente? O si le pide un huevo, ¿le dará un escorpión?". Hermanos, el Señor Jesús dijo que solo Dios es bueno. Por eso dice: "Si vosotros, siendo malos, sabéis dar buenas dádivas a vuestros hijos, ¿cuánto más vuestro Padre celestial les dará lo que le pidan?". Lo precioso es que Dios da lo verdadero, no las piedras calizas redondas parecidas al pan. Él te da el pan del cielo, que alimenta de verdad tu alma, porque hay muchas mentiras que se parecen mucho a la verdad. Tampoco te daría una serpiente en vez de un pescado, porque también sería un engaño. Mucho menos te daría un escorpión, que se acurruca y parece un huevo. Sino que Él nos da lo que nos hace bien y no mal, como un Padre bueno. ¡Bendiciones!`,
    author: "Raúl Enrique",
    date: "15/09/2024",
    id: 228,
  },
  {
    title: "Bendita Esperanza",
    text: `Hermanos, ¡qué gloriosa esperanza tenemos los cristianos! Vale la pena esforzarnos y movernos en la voluntad del Señor, porque la vida es corta con respecto a la eternidad que viviremos. Tenemos como promesa una bendita esperanza por la cual el Señor nos anima a no desmayar, porque pronto habrá una manifestación gloriosa para los hijos de Dios. Pablo dijo: "He aquí, os digo un misterio: no todos dormiremos, pero todos seremos transformados, porque en un momento, en un abrir y cerrar de ojos, a la final trompeta, los muertos en Cristo serán resucitados incorruptibles y nosotros seremos transformados, porque es necesario que esto corruptible se vista de incorrupción y esto mortal se vista de inmortalidad". Bendiciones.`,
    author: "Raúl Enrique",
    date: "13/09/2024",
    id: 227,
  },
  {
    title: "Decisiones Terrenales, Renovación Celestial",
    text: `Casi todo el tiempo de nuestra vida terrenal y pasajera tomamos decisiones, y depende de si estas son buenas o malas el resultado que obtenemos. Si las decisiones son negativas, nos sentimos mal al grado de que esa situación nos afecta profundamente. Como cristianos, debemos ser determinantes y lanzarnos o abandonarnos a la sobreabundante gracia y misericordia de Dios, que nos ayudará a empezar de nuevo. Siempre comenzamos de nuevo debido a que todavía vivimos en una naturaleza pecaminosa que nos afecta. Esto mismo dijo el apóstol: el hombre exterior se va desgastando; sin embargo, el interior se renueva cada día. Debemos ser revestidos de aquella nuestra habitación celestial, no hecha de manos humanas, sino de la eterna, cuyo constructor y arquitecto es Dios. A Él sea toda la gloria, el imperio y la potencia por los siglos de los siglos. Amén.`,
    author: "Raúl Enrique",
    date: "12/09/2024",
    id: 226,
  },
  {
    title: "La Salvación y el Sentido de la Vida",
    text: `Por lo impredecible y las paradojas de la vida, no es fácil vivir en un mundo cambiante, corrupto e injusto, pero aprendemos a convivir como cualquier ser humano en una sociedad caída, y no dejamos de aprender. Porque, aun así, esta vida es linda cuando pensamos en Dios, en su creación, la naturaleza, el diseño y la idea de la familia, a la que tanto amamos. Además, el regalo de la salvación, la iglesia de Cristo, y los buenos amigos le dan sentido, contenido y significado a nuestra vida por el poder transformador del evangelio, que viene cargado de fe y esperanza. Por esto decía Pablo: "No me avergüenzo del evangelio, porque es poder de Dios para todo aquel que en Él cree". También dijo: "Porque esta leve tribulación momentánea produce en mí un cada vez más excelente y eterno peso de gloria". Porque con Cristo somos más que vencedores. Bendiciones.`,
    author: "Raúl Enrique",
    date: "10/09/2024",
    id: 225,
  },
  {
    title: "He Aquí, Yo Vengo Pronto",
    text: `Dice Jesús: "He aquí, yo vengo pronto, y mi galardón conmigo, para recompensar a cada uno según sea su obra". Al final de Apocalipsis dice: "El Espíritu y la Esposa dicen: 'Ven, Señor Jesús'". Durante la historia de la Iglesia, los cristianos vivían con la expectativa y la esperanza de la venida de Jesús. Mientras tanto, el cristiano vivía como dice Su Palabra: "El justo por la fe vivirá". Pedro dijo que en los últimos días habrá burladores e incrédulos que dirán: "¿Dónde está la promesa de su venida? Porque ya ha pasado tiempo y no viene". Pero la verdad es que, cuando habla de "pronto", se refiere a algo repentino, y eso ocurriría durante el tiempo de las señales del fin, que están ante nuestros ojos, aunque muchos no lo quieren ver ni anunciar. Bendiciones.`,
    author: "Raúl Enrique",
    date: "08/09/2024",
    id: 224,
  },
  {
    title: "El Corazón",
    text: `Dice la Biblia: "Sobre toda cosa guardada, guarda tu corazón, porque de él mana la vida". El hombre bueno, del buen tesoro del corazón, saca buenas cosas; el hombre malo, del mal tesoro de su corazón, saca malas cosas. El corazón es más que una bomba biológica que emana sangre por venas y arterias. Hablando espiritualmente, porque está relacionado con el cuerpo, el alma y el espíritu, que le da vida al alma, y el alma al cuerpo. Es por esto que el Señor dijo que el corazón, a causa del pecado, es malo desde su juventud y necesita ser redimido y transformado por el poder del Evangelio, para que su vida sea como una caja llena de tesoros, mostrando las cualidades del amor que no deben faltar en la vida del cristiano convertido. Bendiciones.`,
    author: "Raúl Enrique",
    date: "07/09/2024",
    id: 223,
  },
  {
    title: "Fe, Esperanza y Determinación",
    text: `Dice la Biblia: "El ánimo del hombre soportará la enfermedad, pero ¿quién levantará al de espíritu angustiado?". También se escribe: "Si fueres flojo en el día de trabajo, tus fuerzas se reducirán". Claro, porque todo depende del espíritu, actitud y determinación con los que actuamos. Por ejemplo, si mi actitud ante las adversidades de la vida es sin fe, sin esperanza y sin ganas, mis defensas biológicas y espirituales disminuirán. Es más, los mismos médicos lo dicen y no se equivocan, porque lo podemos experimentar nosotros mismos en la vida diaria. Por ejemplo, Pablo estaba triste en una ocasión y tenía que predicar. ¿Qué hizo Pablo? ¿Cuál fue su actitud? Dijo esto: "Pues determiné para conmigo mismo no ir a ustedes con tristeza, porque si yo los entristezco, ¿quién será luego el que anime y alegre mi corazón?". Fíjate, fue por encima de la situación, enseñando a los cristianos a actuar con determinación, fe y esperanza. Al Señor sea toda la gloria.`,
    author: "Raúl Enrique",
    date: "06/09/2024",
    id: 222,
  },
  {
    title: "Nuestra Esperanza Más Allá de la Muerte",
    text: `Dice un proverbio: “La vida es demasiado corta para vivirla fuera de la voluntad de Dios”. Eso nos habla de la brevedad de la vida en la tierra de los vivientes. Es una realidad, no solo porque la Biblia lo dice, sino porque lo que vemos todos los días ante la partida de alguien en este mundo. Y no tienes que ser anciano o estar enfermo para morir, porque la muerte es segura y la vida es impredecible. Es como neblina, como sombra o como un rayo, dice su Palabra. Pero nuestra esperanza como cristianos trasciende esta vida temporal y efímera. Fuimos renacidos para una esperanza viva que continúa después de la muerte física, porque si vivimos, para el Señor vivimos, y si morimos, para el Señor morimos. Ya sea que vivamos o muramos, del Señor somos (Rom. 14:8). Por esto dijo el mismo Pablo: “Porque para mí el vivir es Cristo y el morir es ganancia”, porque estar con Él es muchísimo mejor. Bendiciones.`,
    author: "Raúl Enrique",
    date: "05/09/2024",
    id: 221,
  },
  {
    title: "Fe Verdadera",
    text: `Hermanos, hoy quiero hablarles de la importancia de tener una fe duradera, porque la fe viva, bíblica, radical y verdadera no depende solo de condiciones favorables cuando todo va bien. La verdadera fe es aquella que, tanto en la alegría como en la adversidad, se mantiene firme sin fluctuar por encima de las circunstancias, e inclina toda la balanza de la confianza en el Dios que llama las cosas que no son como si fueran. Es por esto que su palabra dice que la fe viene por el oír, y el oír la palabra de Dios, que nos trae información a los oídos físicos y luego es evaluada por la razón, reconociendo en el espíritu su eficacia y poder, que transforma la mente y se somete a la sabiduría de Dios. Dice que "el justo por la fe vivirá", porque no andamos por lo que vemos, sino por lo que no vemos. Porque las cosas que se ven son temporales, pero las que no se ven son eternas. Bendiciones.`,
    author: "Raúl Enrique",
    date: "03/09/2024",
    id: 220,
  },
  {
    title: "Nuestro Verdadero Destino",
    text: `Este hermoso planeta en el cual vivimos y podemos disfrutar de la magnífica creación del Dios Altísimo es como un gran hotel cinco estrellas. Por hermoso que sea, estamos de paso porque no es nuestro destino final. Somos en este mundo como extranjeros y peregrinos. El mismo Señor dijo: "Ustedes están en el mundo, pero no son de este mundo", es decir, nos dice que no somos parte del sistema de este mundo que se organiza sin el Dios verdadero porque nuestra ciudadanía está en el cielo, de donde esperamos a nuestro gran Dios y Salvador Jesucristo. Este mundo es la preparación, la antesala, para entrar a un nuevo orden mundial de características divinas y celestiales porque habrá cielo nuevo y tierra nueva donde mora la justicia. Jesús nos dice: "Bienaventurados los que lavan sus ropas para entrar por las puertas de la ciudad, y los que venzan andarán conmigo en vestiduras blancas en el paraíso de mi Dios". Vale la pena lavar las ropas en la sangre del Cordero de Dios que quita el pecado del mundo. Bendiciones. A Él sea toda la gloria.`,
    author: "Raúl Enrique",
    date: "30/08/2024",
    id: 219,
  },
  {
    title: "Las Verdades Eternas",
    text: `Dice la Biblia: "El justo ve el mal y se aparta, mas el necio se lleva todo por delante." También dice: "Huye el impío sin que nadie lo persiga, mas el justo está firme como un león." ¡Qué maravilla son las verdades inmutables de la Palabra de Dios, que nos enseña que somos salvos por gracia mediante la fe bíblica, radical y verdadera! Nos considera santos y justos, no por nuestras obras o mérito alguno, sino por su sacrificio perfecto hecho en la cruz, que nos libró, nos libra y nos librará. Por eso, dice su Palabra que "el justo por la fe vivirá," en abundancia y escasez, en alegría y adversidad, porque no vivimos por lo que vemos, sino por lo que no vemos. Porque confiamos en sus promesas, que son fieles y verdaderas. Bendiciones.`,
    author: "Raúl Enrique",
    date: "29/08/2024",
    id: 218,
  },
  {
    title: "El Poder de Dios Ante las Sombras del Mundo",
    text: `Cuánta violencia, guerras, enfermedades, muertes, dolor y lágrimas empañan lo maravilloso de la vida en este mundo, tanto que en situaciones de bienestar y libertad individual pensamos que somos egoístas, impotentes e inoperantes al no considerar a millones de personas en el mundo que son traspasadas por la espada del dolor. Pero esto es causa del pecado y de fortalezas espirituales invisibles, debido al mínimo espectro de luz que se mide en nanómetros en el ojo físico. Pero esta es una realidad, el mismo Jesús lo reconoció, por eso Pablo afirma esto y dice: "Fortaleceos en el Señor y en el poder de su fuerza, porque no tenemos lucha contra carne ni sangre, sino contra principados, potestades y huestes espirituales de maldad que habitan en las regiones celestes". Nos llama a someternos a Dios y a resistir al diablo, firmes en la fe, y huirá de nosotros, porque mayor es el que está en nosotros que el que está en el mundo. Porque las armas de nuestras milicias no son carnales, sino poderosas en Dios para la destrucción de fortalezas que el diablo quiere edificar en tu mente para desanimarte. No permitas la duda, porque ese es el objetivo principal del enemigo de tu alma. Pablo dijo: "No le des lugar al diablo". Alguien dijo que es un perro atado; al que se acerca, lo muerde. Vivamos lejos del diablo y cerca del Señor. Bendiciones.`,
    author: "Raúl Enrique",
    date: "28/08/2024",
    id: 217,
  },
  {
    title: "La Biblia",
    text: `La Biblia es, sin dudas, la palabra de Dios. Es el manual de conducta de procedencia divina. Fue escrita por hombres bajo inspiración divina; estos eran hombres santos, usados por Dios, que hablaban Su palabra bajo la autoría, inspiración y dirección del Espíritu Santo. Fue escrita dando información para el correcto funcionamiento de la humanidad, enseñando los valores éticos, morales y espirituales como una guía para el bien y la felicidad de la familia. Además, es útil para redargüir, corregir, enseñar e instruir en justicia, a fin de que el hombre de Dios esté preparado para presentar defensa con mansedumbre ante aquel que demande razón de la esperanza que tenemos en Cristo. No pretende asustar, solo informa y advierte sobre las consecuencias de llevar una vida pecaminosa sin tener en cuenta a Dios y Sus valores eternos. Bendiciones.`,
    author: "Raúl Enrique",
    date: "26/08/2024",
    id: 216,
  },
  {
    title: "Redención y Misericordia",
    text: `Los judíos pidieron crucificar a Jesús y los romanos lo crucificaron. Pero Jesús dijo: "A mí nadie me quita la vida, sino que yo la pongo en sacrificio y tengo poder para volverla a tomar". Ya estaba escrito por el anticipado conocimiento de Dios que moriría en sacrificio en un plan de redención para la humanidad condenada a muerte. Muchos recibieron a Jesús; sin embargo, la nación de judíos y gentiles lo rechazaron. Pero los misterios de Dios son grandes. Dios sujetó en desobediencia a judíos y gentiles para tener misericordia de todos. Pablo se regocija diciendo: "¡Oh, profundidad de las riquezas, de la sabiduría y de la ciencia de Dios! ¡Cuán insondables son sus juicios e inescrutables sus caminos!" Grande es Dios.`,
    author: "Raúl Enrique",
    date: "25/08/2024",
    id: 215,
  },
  {
    title: "El Propósito en el Sufrimiento",
    text: `Dice Job que Dios es bueno, a pesar del sufrimiento. La verdad es que a nadie le gusta pasar por aflicciones y deseamos paz y alegría en la familia. Sin embargo, por alguna razón que Él conoce bien, permite cosas en nuestra vida debido al libre albedrío, que no puede faltar en un Dios de amor. Pero en un mundo de contrastes, en el cual vivimos entre el bien y el mal, tomamos decisiones equivocadas o acertadas, y de ambas debemos extraer un aprendizaje para el presente o para el futuro. Por esto, Su palabra nos dice que a los que aman a Dios, todas las cosas les ayudan a bien. En el presente, no entendemos por qué, ya que afecta las emociones y bloquea la razón, pero es un proceso que viene con el propósito de bendecir tu vida y la mía. Bendiciones.`,
    author: "Raúl Enrique",
    date: "22/08/2024",
    id: 214,
  },
  {
    title: "La Caída de los Imperios y el Poder de la Fe",
    text: `En California, EE.UU., existe un cinturón de fuego llamado por los científicos una zona en peligro permanente, debido a los cambios y movimientos que provocan los terremotos. Siempre en el mundo ha habido cambios y movimientos, no solo físicos, sino también de carácter social, político, religioso, cultural y filosófico, e incluso en materia económica. Por eso los grandes imperios cayeron debido a la decadencia en todos los ámbitos de la vida. Así también, el último imperio en este mundo y su corrupción caerá, porque Dios dice: "Aún una vez más conmoveré no solo la tierra, sino también el cielo, porque habrá remoción de todas las cosas movibles para que queden las inconmovibles." Que tu fe, hermano, permanezca inconmovible en la ROCA de CRISTO.`,
    author: "Raúl Enrique",
    date: "21/08/2024",
    id: 213,
  },
  {
    title: "La Buena Noticia del Evangelio",
    text: `El evangelio es la buena noticia de Dios para salvación a todo aquel que cree, es decir, es el plan magistral del Señor para salvarte de una condenación segura después de la muerte física que trasciende esta existencia pasajera. Su palabra dice que está establecido por Dios que el hombre muera una vez y después el juicio. Ese juicio es inevitable porque después de esta vida, Jesús dijo que solo hay dos caminos: un camino de vida y otro de condenación. Solo hay dos condiciones para cambiar tu destino después de esta vida: Jesús dijo "Arrepentíos y creed en el evangelio." Jesús te dice: "Yo soy la resurrección y la vida; el que cree en mí, aunque esté muerto, vivirá; y el que vive y cree en mí, no morirá eternamente." Bendiciones.`,
    author: "Raúl Enrique",
    date: "19/08/2024",
    id: 212,
  },
  {
    title: "La Evidencia del Orden Divino",
    text: `Este planeta hermoso en el que vivimos es producto del poder, la creatividad y el buen gusto del Todopoderoso. Como dijo alguien, hay que ser necio para creer que Dios no existe, con las evidencias que existen de todo lo creado. Por eso su palabra concluye: "Dice el necio en su corazón: 'No hay Dios'". Porque, ante las muchas evidencias de su lógica y perfección, ni siquiera es necesario ver a Dios para creer en Él, debido a que donde existe orden, existe una mente ordenadora. Hasta un niño lo entiende. Ante semejante Dios vivo, único y verdadero, ¿cómo no rendirnos en adoración y gratitud? Su palabra dice: "Todas las cosas por Él fueron hechas". Así que existe un propósito con este mundo, el cual es la antesala para entrar a un nuevo orden mundial de características divinas, porque cosas que ojo no vio ni oído oyó son las que Dios ha preparado para los que lo aman. Ánimo, habrá un mundo nuevo.`,
    author: "Raúl Enrique",
    date: "13/08/2024",
    id: 211,
  },
  {
    title: "La Fe en la Incertidumbre de la Vida y la Muerte",
    text: `La muerte es segura. Jesús habló con honestidad de esta realidad que se presenta con la enfermedad porque, además, la vida es impredecible debido a las paradojas de la vida como resultado de la libre elección. Nos preguntamos: ¿por qué suceden cosas que nos cuesta entender? ¿Por qué cuando muere un niño vive un anciano? ¿Por qué hay gente mala que hace mucho daño y vive, mientras otros que obran bien mueren? Su palabra dice que hay justos a quienes les suceden cosas como si hicieran obras de injustos, y hay impíos que viven como si fueran justos. Su palabra dice que, aun así, a los hijos de Dios les irá bien, además con perspectiva de vida eterna. Por eso su palabra nos enseña a tener una fe bíblica radical y verdadera. Esta clase de fe cree en Jesús, quien te dice: "Yo soy la resurrección y la vida; el que cree en mí, aunque esté muerto, vivirá". Jesús te dice: "Cree solamente y verás la gloria de Dios". Bendiciones.`,
    author: "Raúl Enrique",
    date: "11/08/2024",
    id: 210,
  },
  {
    title: "El Destino Humano",
    text: `Vivimos en un mundo de tiempo y espacio; el otro es el mundo del espíritu y las verdades eternas. Fuimos creados como seres inteligentes con la capacidad de razonar, tener conciencia, percepción, memoria y pensamientos. Con estas capacidades, sabemos que no somos producto de un eructo biológico ni de una explosión cósmica, porque las bombas no construyen, solo destruyen. El cuerpo es una maquinaria de ingeniería genética y biológica perfecta; solo que enfermamos y morimos por causa del pecado. Pero Dios envió a Jesús para que en vida puedas cambiar tu destino después de la muerte física, aceptando la salvación por gracia que Dios te ofrece. De lo contrario, tu destino será la condena eterna. Solo en Jesús hay salvación. No hay ciencia, filosofía, tradición, culturas, educación ni religión que te puedan librar. Porque decirle no a Jesús es decirle no a Dios y a la vida.`,
    author: "Raúl Enrique",
    date: "08/08/2024",
    id: 209,
  },
  {
    title: "Jesús: Más que una Señal, Él es el Mensaje",
    text: `Alguien le dice a Jesús: "Maestro, muestra una señal", como si Jesús no hubiera hecho nada delante de sus ojos, solo que no lo reconocían. Jesús les dice: "La generación mala y adúltera demanda señal, pero señal no le será dada sino la señal del profeta Jonás." Aquí Él no habla del adulterio físico, sino del adulterio espiritual, porque se alejaron de Dios en pos de otros dioses. Y para ellos hay una señal: la de Jonás, que llevó el mensaje de Dios a Nínive, y al oírlo, todos se arrepintieron. Porque Jonás llevó el mensaje, pero Jesús es el mensaje, y ellos no creyeron. También dijo: "La reina del sur vino de los confines de la tierra para oír la sabiduría del rey, y he aquí, más que Salomón, porque Él es poder y sabiduría de Dios."`,
    author: "Raúl Enrique",
    date: "07/08/2024",
    id: 208,
  },
  {
    title: "El Engaño de la Vanidad y la Verdad Eterna",
    text: `Dice el Rey Salomón: "Vanidad de vanidades, todo es vanidad y aflicción de espíritu." Pero, ¿qué es vanidad? La vanidad es una trampa sutil que puede atraparnos y desviar la atención de lo que realmente importa en la vida. Pero el Señor, en su palabra, nos enseña cómo escapar de su influencia en nuestras vidas. La vanidad es buscar sentido y propósitos en cosas materiales, temporales y superficiales, en vez de buscar el propósito de la vida en Dios, que es el autor de la vida. Por esto, Dios dice: "No améis al mundo ni las cosas que están en el mundo, porque el mundo y sus deseos pasan, pero el que hace la voluntad de Dios permanece para siempre." Eso sí que no es vanidad: servir al Señor. Bendiciones.`,
    author: "Raúl Enrique",
    date: "06/08/2024",
    id: 207,
  },
  {
    title: "Libre Albedrío",
    text: `Mientras trabajaba, me vinieron palabras a la mente. El ser humano no se puede esconder de su destino. Nuestro cuerpo morirá, pero nuestra alma vivirá eternamente. Hay un Creador, y Él nos da una nueva oportunidad para elegir, gracias al sacrificio de Jesús. La decisión, gracias al libre albedrío, ahora es nuestra nuevamente: o lo seguimos, o solo “creemos”, o lo rechazamos. La primera es la que demanda quien ideó, construyó y dio vida a este universo. De eso depende el destino de nuestra alma. No se elige por miedo, se elige para corresponder al amor de Dios, se elige el bien.`,
    author: "Leandro Emanuel",
    date: "27/07/2024",
    id: 206,
  },
  {
    title: "Venciendo el Mal con el Bien",
    text: `Hermano, aunque el mal no descansa, el bien nunca se cansa porque tiene su esperanza en Dios. Su palabra nos dice: "No seas vencido de lo malo, sino vence el mal con el bien. Y si tu enemigo tiene hambre, dale de comer; y si tiene sed, dale de beber." La verdad es que para nosotros, en un estado de naturaleza humana, nos cuesta mucho actuar así porque se necesita un elevado nivel espiritual para actuar así con nuestros enemigos. Porque bien podemos hacerlo con alguien a quien amamos, pero Jesús nos dice que mérito tendríamos si solo amamos a los que nos aman. Es por esto que el Señor nos llama a tener un elevado nivel espiritual para mirar a otros con el amor y la misericordia del Señor. La verdad es que a mí en particular me impresiona la bondad y el amor de Dios para los que lo rechazan, por eso Él es digno de toda gloria y honor por los siglos.`,
    author: "Raúl Enrique",
    date: "25/07/2024",
    id: 205,
  },
  {
    title: "Renueva Tus Fuerzas en Dios",
    text: `Tal vez estás pasando por una situación difícil y dices: "Dios, no puedo más. Ayúdame porque la vida nos abruma". A veces pasa que salimos de una situación y entramos en otra; pareciera que todo es adversidad y nos paralizamos cansados. Además, los dardos del maligno en la mente traen desánimo, ese es su propósito principal. Pero un hijo de Dios sabe que para cada situación de la vida hay una promesa. Mira cuál es la promesa para esta ocasión: dice, "¿No has sabido que el Dios eterno no desfallece ni se fatiga con cansancio, y su entendimiento no hay quien lo alcance? Él da esfuerzo al cansado y multiplica las fuerzas del que no tiene ninguna, porque los que esperan en Jehová tendrán nuevas fuerzas y volarán como las águilas."`,
    author: "Raúl Enrique",
    date: "23/07/2024",
    id: 204,
  },
  {
    title: "Evita las Distracciones del Mundo",
    text: `Dice la Biblia que tengamos puestos los ojos en Jesús, el autor y consumador de nuestra fe, y que pongamos la mira en las cosas de arriba y no en las de la tierra, porque vuestra vida está escondida con Cristo. No debemos desenfocarnos con los entretenimientos del mundo que nos llama desde el camino ancho del que habló Jesús, por donde van todos los que se pierden porque se dejan llevar por la corriente de este mundo donde el diablo usa a los perdidos para engañarte e invitarte de manera sutil. Por eso, dice Pablo, las malas compañías corrompen las buenas costumbres. Esto puede suceder por medio de amigos, parientes o cristianos mundanos o carnales que no toman en serio el evangelio de Cristo y tienen sus lámparas vacías de contenido. Bendiciones.`,
    author: "Raúl Enrique",
    date: "22/07/2024",
    id: 203,
  },
  {
    title: "Más Allá de lo Temporal",
    text: `En un mundo lleno de ansiedad, dolor y desesperanza, muchos buscan la felicidad en diversas formas y lugares. Sin embargo, como creyentes sabemos que la verdadera felicidad no se encuentra en las cosas temporales de esta vida, sino que la verdadera felicidad se encuentra en la relación e intimidad con Dios. Esto no es un pensamiento o una expresión de deseo que nace de nosotros solo para consolarnos; definitivamente no, porque el mismo Señor Jesús fue categórico cuando una mujer le dijo: "Maestro, felices los senos que te alimentaron", hablando de María. Pero Jesús le responde: "Más bien, bienaventurados, o felices, los que oyen la palabra de Dios y la guardan o la atesoran en sus corazones". Él es nuestra felicidad.`,
    author: "Raúl Enrique",
    date: "21/07/2024",
    id: 202,
  },
  {
    title: "Jesús, el Amigo que Nunca Falla",
    text: `En Juan 15:14, el Señor dice: "Ustedes son mis amigos si hacen lo que yo les mando. Ya no los llamo siervos, porque el siervo no está al tanto de lo que hace su amo; los he llamado amigos, porque todo lo que le oí decir a mi Padre se los he dado a conocer a ustedes." Hermanos, aunque en este mundo podemos contar con buenos amigos, o al menos con personas que desean ser buenos amigos, sin embargo, a causa de nuestras imperfecciones, imaginaciones, percepciones o malas interpretaciones, cabe la posibilidad de que el que hoy es tu amigo, mañana pueda ser tu enemigo. Eso lo podemos ver todos los días en la vida diaria, en personas que fueron defraudadas por los que antes llamaban amigos. Solo habla con Jesús de los dolores de tu alma. Él NO FALLA.`,
    author: "Raúl Enrique",
    date: "20/07/2024",
    id: 201,
  },
  {
    title: "El Verdadero Poder del Amor",
    text: `El perfecto amor echa fuera todo temor. El verdadero amor no tiene competencia, es invencible y no hay relación con Dios, matrimonio, noviazgo o amistades que no funcionen cuando la fuerza del amor está presente en el centro de una relación, motivada por el vuelo puro del corazón hacia tu semejante. Porque Dios es la fuente del amor perfecto, que nos dio la capacidad de amar y la necesidad de ser amados. Sus características son estas: el amor es sufrido, es benigno, todo lo puede, todo lo cree, todo lo soporta; no hace nada indebido, no se jacta, no tiene rencor, no tiene envidia, no busca lo suyo propio, no se goza del mal, llora con los que lloran y ríe con los que ríen. Así que el amor verdadero vive para siempre.`,
    author: "Raúl Enrique",
    date: "17/07/2024",
    id: 200,
  },
  {
    title: "La Verdadera Fe",
    text: `En lo que respecta a la religión, la cultura, la vida social y moral, existe una confusión con respecto a lo que es la fe. El mundo cree tener razón; cada uno hace su interpretación, como si hubiera muchas verdades, muchas clases de fe. Por esto, Pablo dijo: "Examínate para ver si estás en la fe verdadera", porque la fe no es de todos. Claro, porque la fe bíblica es única, verdadera y radical, y no tiene términos medios: o sos de Cristo o no lo sos. Él dijo: "Conocerás la verdad y la verdad te hará libre", porque solo la fe en Jesús es el único medio para alcanzar la fe verdadera. La fe verdadera tiene tres elementos que aseguran que estás en la fe: Razón, porque está basada en la apropiación de la justicia de Cristo. Doctrina presente del evangelio. Esperanza futura, o sea, convicción firme de la esperanza bendita.`,
    author: "Raúl Enrique",
    date: "15/07/2024",
    id: 199,
  },
  {
    title: "Esforzarse por la Vida Eterna",
    text: `Alguien le preguntó a Jesús: "Maestro, ¿son pocos los que se salvan?" El Señor le responde con una metáfora y le dice: "Esforzaos a entrar por la puerta angosta, porque ancha es la puerta que lleva a la condenación y muchos son los que van por ella. Pero angosto es el camino que lleva a la vida y pocos son los que van por él." Hermanos, esforzaos es ir más allá de nuestras fuerzas esperadas con una fuerza extra que el Señor nos da cuando nos rendimos a Él. Es como cuando una mujer tiene un niño y puja más allá de la fuerza esperada porque tiene esa capacidad natural. Es ir hasta la agonía, nunca retroceder, siempre ir hacia adelante y no permitir que el mundo nos lleve por la puerta y el camino ancho de sus mentiras y engaños.`,
    author: "Raúl Enrique",
    date: "11/07/2024",
    id: 198,
  },
  {
    title: "La Verdad en la Era de la Tecnología",
    text: `Vivimos en un mundo donde la tecnología ha crecido en gran medida. Vivimos en la era de la inteligencia artificial. A través de las redes existen todo tipo de mensajes tendientes a traer nuevas convicciones a la gente que vive una situación de desesperanza ante un mundo que se desmorona cada vez más.Entre todos esos mensajes, existe solo uno al que debemos prestar atención, porque viene del cielo cargado de verdades que pueden transformar nuestras vidas.Este poder puede satisfacer las necesidades espirituales de la gente. El Evangelio nos enseña a no ser niños fluctuantes llevados por todo viento de doctrina, por estrategias de hombres que emplean con astucia las artimañas del error, con herejías y falsedades fuera de la iglesia cristiana.Bendiciones`,
    author: "Raúl Enrique",
    date: "10/07/2024",
    id: 197,
  },
  {
    title: "Jesús y la Posesión Demoníaca",
    text: `El Señor liberó a un pobre hombre que estaba poseído por demonios. Si bien es cierto que no todas las enfermedades tienen que ver con espíritus, sin embargo, existe la posesión demoníaca, sino Jesús no hubiera expulsado a esos espíritus. Lo que pasaba es que antes la gente, cuando se enfermaba, le atribuía todo a un espíritu demoníaco por el desconocimiento médico. Pero con la medicina moderna se diagnostican muchas causas de enfermedades orgánicas y mentales. Con estos avances, no quiere decir que los espíritus demoníacos no existan, porque sería como decir que el diablo no existe. El Señor liberó a este hombre del poder del diablo, como lo hace hoy con todos aquellos que están esclavizados por el poder del pecado.`,
    author: "Raúl Enrique",
    date: "09/07/2024",
    id: 196,
  },
  {
    title: "Jesús y la Samaritana",
    text: `Jesús le dice a una mujer que salió a buscar agua en un pozo: "Mujer, dame de beber". Ella le dice: "¿Cómo siendo tú judío me pides a mí de beber que soy samaritana?", porque había enemistad entre ambos pueblos. Jesús le dice algo que también te dice a ti y a todos los que creen en Él: "Si supieras quién es el que te dice 'dame de beber', tú le pedirías a Él y Él te daría el agua de vida que salta para vida eterna". El agua en sentido espiritual es vida, y el Señor es la fuente de agua viva que puede saciar la sed más profunda de tu alma y la mía, que le da sentido, significado y contenido a nuestras vidas, porque el agua de su palabra corre como agua viva que fluye limpia, pura y fresca, que sacia para siempre nuestras vidas. Bendiciones.`,
    author: "Raúl Enrique",
    date: "08/07/2024",
    id: 195,
  },
  {
    title: "El Poder de la Oración",
    text: `Pacientemente esperé a Jehová, y se inclinó a mí y oyó mi clamor. Me hizo sacar del pozo de la desesperación, puso mis pies sobre peña y enderezó mis pasos. Luego puso en mi boca cántico nuevo y alabanza a nuestro Dios. Verán esto muchos y temerán. David era un rey que lo tenía todo, pero además sabía que tenía al Dios vivo, al cual en sus momentos de necesidad espiritual podía clamar, al que oye la oración de sus hijos. Porque hay cosas en esta vida que no se logran con poder, con riquezas ni fama, porque la necesidad del hombre viene de adentro del corazón. Estaba deprimido, pero clamó a Dios y fue oído y elevado hasta la roca de Cristo, donde se afirmaron sus pies y cantó alabanza al que vive por todos los siglos. AMÉN.`,
    author: "Raúl Enrique",
    date: "07/07/2024",
    id: 194,
  },
  {
    title: "La Única Puerta a la Vida Eterna",
    text: `Dice Jesús: "No todo el que me dice 'Señor, Señor' entrará en el reino de los cielos, sino los que hacen la voluntad de mi Padre que está en el cielo". Esto demuestra que no se puede servir a dos señores, pues amarás a uno y odiarás al otro. Jesús dijo que solo hay dos puertas: una ancha y otra angosta; dos caminos, dos grupos de personas: los que siguen al dragón y los que siguen al cordero. Y solo hay dos destinos: el cielo o el infierno. Así de contundentes son sus palabras, porque no existe término medio ni purgatorio, como cree la Iglesia Católica, el humanismo y el universalismo. Tampoco la gente que cree que todos van al cielo sin reconocer a Cristo en su vida, porque nadie va al Padre si no es por Jesús, que es tu pasaje a la vida.`,
    author: "Raúl Enrique",
    date: "04/07/2024",
    id: 193,
  },
  {
    title: "Luz en la Oscuridad",
    text: `Dios dice: "Si dieres tu pan al hambriento y saciares al alma afligida, en las tinieblas nacerá tu luz y tu oscuridad será como el mediodía. El Señor te pastoreará siempre y en las sequías saciará tu alma. Le dará vigor a tus huesos y serás como huerto de riego y manantial de aguas que nunca faltan." Jesús dijo que tendrías el agua de vida que salta para vida eterna, porque el que beba de su agua nunca más volverá a tener sed. Él no ha venido a vaciar tu vida, sino a llenarla de abundancia para que compartas vida con otros y, sobre todo, ayudes al necesitado que carece de las necesidades básicas. Siempre habrá oportunidad para ayudar al que menos tiene, y no hagas tocar trompetas; basta con que lo vea el Señor. Bendiciones.`,
    author: "Raúl Enrique",
    date: "02/07/2024",
    id: 192,
  },
  {
    title: "La Familia y el Verdadero Amor",
    text: `La familia fue idea del Creador, por lo que es de procedencia divina. Él es la fuente de todo amor, quien nos dio la capacidad de amar y la necesidad de ser amados. Y no solo eso, Él mismo nos enseña cómo amar a la familia y al prójimo, perfeccionándonos en el amor, ya que, por ser imperfectos, no podemos amar perfectamente como deseamos. Sin embargo, la motivación de mi corazón debe estar inclinada para amar con el amor de Cristo. Amar a la familia es algo muy natural, pero para amar a nuestros semejantes se necesita una dosis más de amor, que se logra con una semilla de fe en el Señor, con raíces de esperanza que darán el fruto del verdadero amor, el cual debe crecer con la ayuda y obediencia a quien es la fuente del amor que no tiene competencia.`,
    author: "Raúl Enrique",
    date: "01/07/2024",
    id: 191,
  },
  {
    title: "La Belleza de la Vida en la Fe y la Esperanza",
    text: `En este mundo se vive hoy más que nunca como en un desierto sin agua, agobiados por el cansancio, casi sin fuerzas, sedientos en sentido espiritual. Porque el ser humano es espíritu, alma y cuerpo que posee emociones, reacciones y acciones que salen a la superficie transformadas en tristeza, dolor y amargura por lo que sucede en este mundo de sufrimiento que nos toca vivir. Pero aun así, la vida es hermosa porque viene cargada de esperanza para las personas que le creen al Señor y sus promesas de un mundo nuevo, donde no habrá más lágrimas, dolor ni muerte. Porque Él es el agua de vida en medio del desierto, que nos dice: "El que tenga sed, venga a mí y beba, y de su interior correrán ríos de agua viva que saltarán para vida eterna."`,
    author: "Raúl Enrique",
    date: "30/06/2024",
    id: 190,
  },
  {
    title: "La Llamada del Señor a la Humildad y la Fe",
    text: `El Señor nos llama a la humildad, a la oración y a la búsqueda de su presencia. Una demostración de su humildad es que, siendo Dios, se hizo siervo; además, siendo rico, se hizo pobre para que, por sus promesas, seamos ricos en fe. Como dijo Pablo: "andamos como pobres, pero enriqueciendo a muchos; como no teniendo nada, mas poseyéndolo todo". Con humildad logramos que el Señor incline su rostro hacia nosotros, porque Él está con los espíritus humildes y no tiene que ver con riquezas ni carencias ni con sabiduría. Tiene que ver con una actitud del corazón que ama a Dios y su voluntad. Él dice: "el cielo es mi trono y la tierra es estrado de mis pies; pero miraré al que es pobre y humilde de espíritu y tiembla a mi palabra". Bendiciones.`,
    author: "Raúl Enrique",
    date: "28/06/2024",
    id: 189,
  },
  {
    title: "La Esencia del Amor",
    text: `El amor se describe como la virtud suprema de la vida cristiana. El Señor nos presenta una visión profunda y transformadora de su amor. Porque la supremacía del amor no tiene competencia, porque es mayor que todos los dones juntos y nada es superior al amor, que es eterno y nada seré sin amor, que le da verdadero significado a nuestras acciones y palabras. Porque todo el conocimiento y la fe sin amor son inútiles. Lo dice su palabra: todas nuestras palabras y acciones deben nacer de un corazón motivado por el amor, que nos eleva por encima de todo contexto y circunstancias, porque el amor no especula ni calcula, no busca lo suyo propio. Por el contrario, es el vuelo puro del corazón hacia otros. Así de bello es el AMOR.`,
    author: "Raúl Enrique",
    date: "26/06/2024",
    id: 188,
  },
  {
    title: "La Grandeza de Dios y su Cercanía con los Humildes",
    text: `Un escéptico dijo en un reportaje que creer que Dios, el todopoderoso, tenga relación con sus criaturas sería como pretender tocar la Vía Láctea con las manos o querer llegar al sol. Imposible. Pero mira lo que dice Dios mismo: "Porque así dice el Alto y Sublime, el que habita la eternidad, cuyo nombre es El Santo: Yo habito en la altura y la santidad, y también con el quebrantado y humilde de espíritu, para vivificar el espíritu de los humildes y vivificar a los de corazón quebrantado." Isaías 57:15.¿Te das cuenta, hermano, cómo Él mismo se ocupa de manera personal de los humildes de espíritu? Porque a los soberbios los mira desde lejos. No se trata de riquezas ni carencias, sino de un corazón humilde de espíritu que espera del Señor. El mismo Jesús dijo: "Bienaventurados los pobres de espíritu, porque de ellos es el Reino de los Cielos." Solo Él es digno de adoración. Bendiciones.`,
    author: "Raúl Enrique",
    date: "25/06/2024",
    id: 187,
  },
  {
    title: "Levántate y Resplandece",
    text: `¿Estás cansado, desanimado y sin fuerzas? No te rindas, no decaiga tu semblante, porque te diré lo que dice el Señor de tu vida en Isaías 60:1: "Levántate y resplandece, porque ha venido tu luz y la gloria de Jehová ha nacido sobre ti". Porque la luz resplandece sobre las tinieblas, y Jesucristo es quien resplandeció en tu corazón e iluminó tu camino para que puedas ver con claridad el camino que te lleva a la vida. Vivamos con Él, caminemos con Él, viajemos por este mundo con Él y llegaremos a destino con Él. Él nos dice: "Si retrocedieres, no agradará a mi alma". Pero nosotros no somos de los que retroceden para perdición, sino de los que se adelantan para preservación del alma. Porque sin fe es imposible agradar a Dios. Bendiciones.`,
    author: "Raúl Enrique",
    date: "24/06/2024",
    id: 186,
  },
  {
    title: "Fortaleza en Tiempos de Cambio Cultural",
    text: `En estos tiempos críticos que nos toca vivir, en medio de un cambio cultural donde la tendencia es dejar de lado a Dios y los verdaderos valores familiares que hacen fuertes a la familia y sus generaciones, en medio de un mundo estresante y difícil, te animo como hermano en Cristo. Cuando sientas que ya no puedes más, que tienes pocas fuerzas para dar el siguiente paso, descansa y mira a tu alrededor y considera al que te creó y creó este universo con este planeta hermoso. Te darás cuenta de que todo fue hecho con amor y buen gusto para que disfrutes reconociendo su poder, porque en Él habita esa fuerza que necesitamos. Él te dice hoy: "Yo doy esfuerzo al cansado y multiplico las fuerzas del que no tiene ninguna". Él te ayuda.`,
    author: "Raúl Enrique",
    date: "23/06/2024",
    id: 185,
  },
  {
    title: "El Engaño del Adversario",
    text: `Dice Dios que el diablo anda alrededor nuestro buscando a quien devorar. Imagínate a un león con hambre, rechinando sus colmillos, listo para saciarse de sus víctimas. Jesús dijo sin rodeos que el diablo, aunque no lo vemos por ser espiritual, vino para matar, hurtar y destruir. Sabemos por su Palabra los efectos que provoca, trastornando al mundo que está siendo engañado por él, haciéndole creer a la gente que no existe, para que la gente atribuya al Señor el sufrimiento. Su principal propósito es engañar y manipular, como a Eva. Tal como hacen hoy los falsos profetas. Dice Proverbios que la gente actúa como necia y después se enojan con el Señor. Dios nos llama a ser libres del engaño de nuestro adversario, el diablo.`,
    author: "Raúl Enrique",
    date: "21/06/2024",
    id: 184,
  },
  {
    title: "El Amor de Dios",
    text: `Dios es amor y nos creó con la capacidad de amar y la necesidad de ser amados. Además, Él es la fuente de todo amor y el mundo se sostiene por su amor. Aunque exista el mal, nunca podrá contra el amor, que es más fuerte que la muerte. Jesús venció a la muerte en la cruz mostrando su amor hacia nosotros porque, siendo aún pecadores, Cristo entregó su vida para salvarnos de la condenación. Su amor es sufrido, benigno, todo lo puede, todo lo cree, todo lo soporta, no hace nada indebido, no se jacta, no tiene rencor, no tiene envidia, no busca lo suyo propio, no se goza del mal, llora con los que lloran y ríe con los que ríen. Es paz, templanza, mansedumbre, comprensión, benignidad, justicia, fe y bondad. Él es amor y siempre te amará.`,
    author: "Raúl Enrique",
    date: "19/06/2024",
    id: 183,
  },
  {
    title: "Solo Dios hace al hombre feliz",
    text: `Intentar encontrar la felicidad sin Dios, el que te dio el don de la vida. Sería como correr detrás del viento para alcanzarlo, es imposible porque él es la fuente del amor y la felicidad, por eso los cristianos cantamos solo Dios hace al hombre feliz. Como el Señor desea tu felicidad y la de tu familia te invita a que razones y seas sabio en tu libre albedrío. Diciendo a los cielos y a la tierra llamo por testigo contra ti que he puesto delante de vosotros la vida y la muerte entre la bendición y la maldición. Él te da la vida para que vivas tú y tus descendientes. Te das cuenta hermano, Él respeta tu libre decisión, pero te aconsejas que elijas la vida. El mismo Jesús dijo, separados de mí nada puedes hacer. Pero la felicidad no tiene que ver con logros humanos, sino con tener a Cristo que te da vida aquí y esperanza de vida ETERNA EN EL FUTURO. Bendiciones.`,
    author: "Raúl Enrique",
    date: "17/06/2024",
    id: 182,
  },
  {
    title: "La Aurora de los Justos",
    text: `Los entendidos resplandecerán aunque el mundo se cubra de oscuridad. Nuestra luz será más clara que el mediodía, porque el camino de los justos es como la luz de la aurora que va en aumento hasta que el día queda establecido. Porque el Señor es la luz que alumbra a todo hombre. Él dijo: "Yo soy la luz del mundo, el que a mí viene no andará en tinieblas, sino que tendrá la luz de la vida". Por la cual debemos caminar seguros, sin tropiezos, sin temor ni dudas, porque su palabra es como antorcha que alumbra en lugares oscuros y va hasta lo más profundo de tu alma. Cuando somos alcanzados por la verdad que nos revela con claridad el estado de nuestro corazón y lo frágiles, vulnerables e impotentes que somos. PERO ÉL NOS AYUDA EN TODO.`,
    author: "Raúl Enrique",
    date: "14/06/2024",
    id: 181,
  },
  {
    title: "Renovación Diaria en el Señor",
    text: `El Señor Jesús nunca prometió una vida sin problemas, pero sí nos aseguró su presencia, ayuda y victoria. Además, sabemos que el que siembra con lágrimas cosechará con alegría. Por ser Jesús la verdad encarnada de Dios, fue honesto al decir la pura verdad. Es más, dijo que en el mundo tendremos aflicción, pero que debemos confiar porque Él ha vencido al mundo. Pablo también dijo: "Tengo por cierto que las aflicciones del tiempo presente no son comparables con la gloria venidera que en nosotros ha de manifestarse". Porque yo sé en quién he creído y, aunque este hombre exterior se va desgastando, sin embargo, el interior se renueva cada día. Esperando ser revestido de este cuerpo mortal a un cuerpo incorruptible de gloria. Porque estar ausente del cuerpo es estar presente en el Señor. El mismo Señor dijo: "Yo soy la resurrección y la vida; el que cree en mí, aunque esté muerto, vivirá; y el que vive y cree en mí no morirá eternamente, pues ha pasado de muerte a vida". Con Él, tu nombre está escrito en el libro de la vida. Porque Él es tu pasaje a la verdadera vida. A Él sea la gloria eterna. Porque todas sus promesas son un SÍ y un AMÉN.Bendiciones.`,
    author: "Raúl Enrique",
    date: "13/06/2024",
    id: 180,
  },
  {
    title: "El Regocijo de Atesorar la Palabra de Dios",
    text: `Una mujer se conmovió grandemente al escuchar al maestro. En su regocijo, le dijo al Señor: "Bienaventurados los senos que te alimentaron". Y el Señor le responde: "Más bienaventurados son los que oyen y guardan la palabra de Dios". ¡Qué maravilloso es nuestro Dios, que te llama dichoso, feliz, doblemente afortunado si atesoras sus palabras en tu corazón! Hermanos, ahí lo tenemos. El Señor ama y desea nuestra felicidad, que solo la podemos alcanzar en Él. Pero esta felicidad no depende de circunstancias externas o de lo que logramos o no en nuestras vidas, porque si así fuera, sería imposible debido al sufrimiento y las injusticias que nos rodean. Alguien dijo una vez: "Coloca a Dios al principio y serás feliz hasta el final". Bendiciones.`,
    author: "Raúl Enrique",
    date: "12/06/2024",
    id: 179,
  },
  {
    title: "El Verdadero Alivio para la Sed Espiritual",
    text: `Dice Dios: dos males hizo mi pueblo: me dejaron a mí, fuente de agua viva, y cavaron para sí cisternas rotas que no retienen agua. Este es un mundo sediento de agua viva que solo Dios te puede dar, porque Él es la fuente de agua que puede saciar la sed del alma. Como dijo David: "Como el ciervo brama por las corrientes de las aguas, así clama por ti, oh Dios, el alma mía, porque mi alma tiene sed, sed del Dios vivo". Este es un mundo seco que no tiene nada que ofrecer; tampoco puede saciar la sed de tu alma porque una cosa es el cuerpo que necesita del agua física y otra la sed del alma que necesita de Dios, que sacia tu vida. Este mundo es pobre del agua fresca, limpia y pura de la palabra de Dios, que sacia de verdad tu vida. Bendiciones.`,
    author: "Raúl Enrique",
    date: "11/06/2024",
    id: 178,
  },
  {
    title: "La Importancia del Conocimiento Bíblico",
    text: `Nosotros, los cristianos, debemos pedir a Dios que nos dé, como dijo Pablo, espíritu de inteligencia y discernimiento espiritual, para que no seamos niños fluctuantes llevados por todo viento de doctrina, por estratagemas de hombres que para engañar emplean con astucia las artimañas del error. También dice que el diablo es perito arquitecto en engañar con sus ministros, que se visten como ángel de luz y tendrán apariencia de piedad, pero negarán la eficacia y el poder de Dios. Hermanos, Santiago dice que pidamos sabiduría al Señor porque él es generoso. Pero ese conocimiento no viene solo por orar, sino por estudiar las sagradas escrituras con la ayuda del Espíritu Santo, quien te iluminará para que entiendas y no seas engañado por falsos profetas. Vayamos a lo profundo de las verdades coralinas de su palabra y jamás serás engañado. Él es bueno. Bendiciones.`,
    author: "Raúl Enrique",
    date: "10/06/2024",
    id: 177,
  },
  {
    title: "La Familia: Un Don de Dios",
    text: `Hermanos, la familia es una idea de Dios y no solo una idea, sino un don hecho realidad. Es un vínculo de amor invisible que va más allá de este mundo, pero en el orden de valores y prioridades, el Señor debe estar por encima aun de la familia, que amamos tanto hasta lo profundo de nuestro ser. Porque Él es quien sustenta y le da vida a la familia, por la cual se proyecta la humanidad. Y si actuamos con inteligencia y consideramos el amor de Dios para con la familia, nos damos cuenta de que, para ser una familia fuerte, unida y próspera, contamos con su apoyo infalible en medio de una generación maligna y perversa en la cual vivimos, que ha permitido la disgregación y la degradación familiar. Pero en Cristo hay futuro.`,
    author: "Raúl Enrique",
    date: "09/06/2024",
    id: 176,
  },
  {
    title: "El Gozo de Estar en Cristo",
    text: `Hermoso es decir y vivir como dijo el apóstol Pablo: porque con Cristo estoy juntamente crucificado, y ya no vivo yo, más Cristo vive en mí, y lo que vivo en la carne, lo vivo en la fe del Hijo de Dios, que me amó y se entregó a sí mismo por mí. Y ahora de ambas cosas estoy puesto en estrecho: si quedarme para beneficio del Evangelio o partir y estar con Cristo, que es muchísimo mejor. ¿Te das cuenta, hermano? Pablo no pensaba solo en términos terrenales, porque aun en esta vida dura que vivió, nunca perdió el gozo ni la felicidad que vivía en Cristo. Sino que también tenía el anhelo ardiente de estar con Jesús, confiando en sus promesas. Por eso les decía a los hermanos que aprendían de él: Regocijaos siempre en el Señor. Bendiciones.`,
    author: "Raúl Enrique",
    date: "08/06/2024",
    id: 175,
  },
  {
    title: "Fortaleza en la Debilidad",
    text: `Dice Dios en Joel 3:10: "Diga el débil: fuerte soy". El Señor tenía soldados entrenados para la guerra, pero cuando se trataba del pueblo que se veía amenazado por el enemigo, debían luchar los agricultores y campesinos, gente que se sentía débil, sin fuerzas, con temor e incertidumbre, contrario a los soldados bien entrenados. Pero el Señor los anima diciendo que, aunque eran débiles, debían alentarse y confiar porque la diferencia está en el Dios vivo que ellos tenían y que también tenemos nosotros. Porque es el mismo Dios que va como poderoso gigante delante de ti para fortalecerte en la hora de las pruebas de la vida. Tenemos que romper con la burbuja de la autosuficiencia y dejar lugar para que opere la Gracia de Cristo.`,
    author: "Raúl Enrique",
    date: "06/06/2024",
    id: 174,
  },
  {
    title: "La Buena Noticia de Salvación",
    text: `Pablo dijo: "El Evangelio es el poder de Dios para salvar". Hermanos, el Evangelio es la buena noticia de salvación de una condenación segura, pues todos pecamos y estábamos destituidos de la gloria de Dios. El Evangelio tiene un poder transformador que puede cambiar nuestras vidas, devolviéndonos nuestra imagen y semejanza de Dios, que fue perdida por Adán al heredar nosotros la imagen pecaminosa de él y su mujer Eva. Cuando nos arrepentimos, creemos y reconocemos a Jesús en nuestras vidas, hay un nuevo nacimiento y nos vestimos bajo la imagen del nuevo hombre, el segundo Adán, en Jesús. Por eso dice Pablo: "La paga del pecado es muerte, pero el regalo de Dios es vida eterna en Cristo Jesús, Señor nuestro". Porque si alguno está en Cristo, nueva criatura es; las cosas viejas pasaron y en Él son todas hechas nuevas. Bendiciones, y si querés, compartí esta reflexión con otros que la puedan necesitar y ser alcanzados por la gracia del Señor Jesús y ser vestidos del nuevo hombre según Dios, para alabanza de su nombre. Solo en Él hay esperanza de vida.`,
    author: "Raúl Enrique",
    date: "05/06/2024",
    id: 173,
  },
  {
    title: "Discerniendo la Fe",
    text: `Dice Pablo: "La fe viene por el oír y el oír la palabra de Dios." Hermano, esto es un eslabón en la cadena. Las frases van unas con otras. Habla de la fe y dice que viene por el oír. En lo que respecta a la religión, hay tres tipos de fe: la verdadera, la fe relativa y la fe falsa. Por ejemplo, la fe bíblica es verdadera y radical. No hay términos medios ni lugar para grises, para tibios o para penumbras. O eres cristiano o no lo eres. O somos cabritos o somos ovejas. O eres del Cordero o eres del Dragón. La fe falsa la podemos ver desde lejos: el islam y otras religiones orientales y asiáticas. Pero la más hereje y peligrosa es la fe relativa, que es como un vaso de agua con gotas de veneno. Usan siete verdades de la Biblia para meter tres herejías, como el caso de la falsa prosperidad, y engañan a la pobre gente que busca de Dios, haciendo show por dinero. Jesús les dirá: "Apartaos de mí, hacedores de maldad." Que Él nos guarde.lesia. Así que sirvamos a Dios con alegría de corazón. Bendiciones.`,
    author: "Raúl Enrique",
    date: "04/06/2024",
    id: 172,
  },
  {
    title: "Señales de los Tiempos",
    text: `Hermanos queridos, tenemos que reconocer que estamos viviendo en tiempos de cumplimientos proféticos y los cristianos podemos discernir a través de la palabra de Dios que no falla. El clima que se está viviendo en el mundo desde que comenzó la pandemia es como un aviso de lo que vendrá debido a los cambios de paradigmas que se dieron en este tiempo: cambios de carácter social, político, religioso y cultural. Porque esto es la antesala de un nuevo orden mundial de características terrenales que terminará con la intervención del Señor Jesús como Rey de Reyes y Señor de Señores. Pero antes se cumplirá nuestra bendita esperanza, o sea, el arrebatamiento de la Iglesia. Así que sirvamos a Dios con alegría de corazón. Bendiciones.`,
    author: "Raúl Enrique",
    date: "03/06/2024",
    id: 171,
  },
  {
    title: "El Propósito de Dios para la Humanidad",
    text: `Muchos se preguntan cuál será el propósito de Dios para el hombre. Cuando hablamos del hombre, también hablamos de la mujer, de la humanidad toda. Pero, por lo que vemos en la Biblia, podemos ver que el propósito de Dios es darle a la humanidad que cree y vive en Jesús, el centro de su propósito, la felicidad eterna. En San Juan 3:16 dice: "Porque de tal manera amó Dios al mundo, que ha dado a su Hijo unigénito, para que todo aquel que en él cree no se pierda, mas tenga vida eterna". Ahí lo podemos ver: el Señor te da vida eterna y, con ello, calidad de vida permanente, que no la vas a encontrar en este mundo pasajero y caído. Pero, mientras caminamos por este mundo con Cristo, vamos de triunfo en triunfo y de gloria en gloria. Bendiciones.`,
    author: "Raúl Enrique",
    date: "02/06/2024",
    id: 170,
  },
  {
    title: "Dos Caminos, Dos Destinos",
    text: `Dice Dios: bienaventurado el varón que no anduvo en consejo de malos, ni anduvo en camino de pecadores, ni en silla de escarnecedores se ha sentado, sino que en la ley de su Dios medita de día y de noche. Será como árbol plantado junto a corrientes de agua que a su tiempo da fruto y su hoja nunca cae. Pero no será así para el malo, que es como una rama seca llevada por el viento. Y no habrá paz para el impío. Hermanos, este pasaje nos habla de dos caminos diametralmente opuestos, porque no hay más opciones: uno es andar por el camino del bien y el otro por el camino del mal. Jesús dijo que solo hay dos puertas de entrada, dos caminos, dos grupos de personas y dos destinos. Jesús nos llama a ser sabios y elegir la vida.`,
    author: "Raúl Enrique",
    date: "01/06/2024",
    id: 169,
  },
  {
    title: "La Fuerza del Amor Divino",
    text: `Las muchas aguas no podrán apagar el fuego del amor porque el amor es más fuerte que la muerte. Jesús es la bondad de Dios, o sea, el corazón de Dios en la tierra. Es la mayor expresión del amor en este mundo porque, gracias a Dios, el pecado y la muerte no pudieron apagar el fuego del amor para salvar tu vida y la mía, entregando su vida en ofrenda y sacrificio para que por su justicia y por medio de la fe, y no por obras, recibas la gracia inmerecida e incondicional que te amó como si fueras el único en este planeta. Ahora nos queda a nosotros corresponder a ese amor infinito que te amó eternamente, como dice su palabra. Que su amor sea derramado como un perfume en tu vida y la mía, ahora y siempre. Bendiciones.`,
    author: "Raúl Enrique",
    date: "31/05/2024",
    id: 168,
  },
  {
    title: "La Búsqueda de la Plenitud",
    text: `¿Te ha pasado sentir que todo está “bien” en tu actualidad, que dentro de todo no está ocurriendo nada que altere tus días, y aun así sentir que falta algo y hay inquietud? Eso sucede cuando no tenemos presente a Dios en nuestra vida. Podemos buscar, pensar y hacer cualquier actividad, pero esa sensación real no va a desaparecer. Nacimos para adorar a Dios en espíritu y en verdad. Hay un vacío que solo puede ser llenado por el Creador; nadie más tiene la llave de esa puerta. No lo olvidemos nunca.`,
    author: "Leandro Emanuel",
    date: "30/05/2024",
    id: 167,
  },
  {
    title: "Esfuérzate y Sé Valiente",
    text: `Dice Dios: "Mira que te mando que te esfuerces y seas valiente. No temas ni desmayes, porque yo soy Jehová tu Dios que estoy contigo dondequiera que vayas." Hermanos, aquí el Señor le da una orden a su siervo para que no sea menos de lo que debe ser y lo llama a ser un hombre de valor, que no tenga miedo ni claudique o se rinda, porque él mismo dice: "Yo estoy contigo". Además, su palabra nos dice: "Si Dios es por nosotros, ¿quién contra nosotros?" Porque las batallas de la vida nunca acaban y nunca las gana el más fuerte, como dijo alguien, sino aquel que en ningún momento duda de que Dios es quien nos da la victoria, porque con Cristo somos más que vencedores. Pero esto de vencedores no es una expresión de deseo o una utopía o un dicho; es una realidad total en Cristo, porque con Él todo es posible. Bendiciones.`,
    author: "Raúl Enrique",
    date: "29/05/2024",
    id: 166,
  },
  {
    title: "Desánimo: Un Enemigo Invisible en la Vida Humana",
    text: `Desde la caída de Adán, la humanidad sufre el desánimo y sabemos muy bien que este puede ir más allá, al grado de la depresión, la tristeza extrema y el trastorno de ansiedad, ataques de pánico y otros trastornos psicológicos, que predisponen el cuerpo a diversas enfermedades debido a las defensas biológicas bajas o muy bajas. Todos de alguna manera somos vulnerables al desánimo, aunque alguien trate de disimular. Pero esto no viene de Dios ni de nosotros mismos, porque a veces, con todas las condiciones de vida favorables, nos asalta el desánimo. Porque es un espíritu y viene del mismo infierno. Ese espíritu de desánimo viene para frenar y detener tu actitud de vida, pero Jesús vino a darte vida en abundancia.`,
    author: "Raúl Enrique",
    date: "28/05/2024",
    id: 165,
  },
  {
    title: "El Poder de la Palabra Viva",
    text: `Dice Jesús: "Cielo y tierra pasarán, pero mis palabras jamás pasarán". ¿Por qué no pasarán? Porque su palabra es viva y eficaz, y más cortante que toda espada de doble filo, que penetra hasta partir el alma, las coyunturas y los tuétanos, y discierne los pensamientos y las intenciones del corazón. No hay ninguna cosa creada que no sea manifiesta en su presencia. Eso significa que cada palabra del Señor tiene vida, y la vida actúa en nosotros. Por eso Él dice: "Sin mí nada puedes hacer". Tengas o no tengas logros humanos, porque si ganases todo el mundo sin Cristo, sería todo una vanidad y una vida vacía de contenido y significado, sin esperanza. Pero Él mismo también dijo: "Yo les doy vida, y vida en abundancia, para que disfruten de todo lo bueno que Él les provee y compartan con otros las riquezas de su gracia y, después, el reino de los cielos".`,
    author: "Raúl Enrique",
    date: "26/05/2024",
    id: 164,
  },
  {
    title: "El Verdadero Camino a Dios",
    text: `Dice la Biblia: "Hay caminos que al hombre le parecen derechos, pero su fin es camino de muerte". Hablando de caminos, existe una famosa frase que dice: "Todos los caminos conducen a Roma". Desde el punto de vista religioso, se argumenta que a Dios se puede llegar por cualquier camino, con cualquier tipo de doctrina. Pero ese pensamiento está muy lejos de la verdad. El mismo Jesús dijo: "Yo soy el camino, la verdad y la vida. Nadie va al Padre sino por mí, y al que a mí viene, yo no lo echo fuera". El enemigo de nuestra alma trae confusión de manera sutil y astuta para que crean que pueden ir por el universalismo, el ecumenismo, el islamismo y todos los "ismos". Pero Isaías dice: "Por tus espaldas, a tus oídos, alguien te dirá: 'Este es el camino, no te apartes ni a diestra ni a siniestra de él'". Es el Espíritu Santo quien te habla y te dice que JESÚS ES EL CAMINO DE VIDA. Bendiciones."`,
    author: "Raúl Enrique",
    date: "25/05/2024",
    id: 163,
  },
  {
    title: "Fuerza y Valor en Dios",
    text: `Hermanos queridos: El don de la vida es un verdadero milagro y respirar es una dicha, una alegría indescriptible porque amamos la vida que El Señor nos dio más allá de esta vida física. Porque aunque haya adversidades, también existen momentos de felicidad cuando nos reunimos en familia, teniendo a Jesús como centro de nuestras vidas. Además, es un privilegio que nos da la dicha de alcanzar el propósito de su compañía y su bendición a todos como hermanos en Cristo. Así que el Señor nos dice: "Mira que te mando que te esfuerces y seas valiente; no temas ni desmayes, porque yo soy Jehová tu Dios, que te esfuerzo y voy contigo a dondequiera que vayas. Porque mi presencia irá contigo y te daré descanso. Aunque no lo veas, no se dormirá el que guarda tu vida, porque estará delante de ti como un poderoso gigante para cuidarte y rodearte de su misericordia."`,
    author: "Raúl Enrique",
    date: "24/05/2024",
    id: 162,
  },
  {
    title: "La Paz del Señor: Un Regalo Eterno",
    text: `Dijo el Señor: "Mi paz les dejo y mi paz les doy, pero no como el mundo la da, sino que mi paz permanece para siempre". Hermanos, es la paz que sobrepasa todo entendimiento, es esa paz que en lo profundo de nuestra alma experimentamos los cristianos al saber que antes éramos enemigos de Él, pero ahora, por su justicia y su gracia, vivimos en amistad con Él y moriremos en amistad con Él. Así que, cuando le decimos a los hermanos "paz del Señor", les estamos diciendo: "Te deseo todo aquello que contribuye a tu bien y al descanso de tu alma". Bendiciones.`,
    author: "Raúl Enrique",
    date: "23/05/2024",
    id: 161,
  },
  {
    title: "Preparados para el Cumplimiento de las Profecías",
    text: `Vivimos en tiempos con señales y cumplimiento de profecías bíblicas. El Señor Jesús nos llama a estar despiertos y preparados para enfrentar cualquier situación que se nos presente como cristianos. El libro de Apocalipsis dice que Jesús abrirá los sellos y, según los teólogos, ya está en cumplimiento el cuarto sello con guerras y hambruna. Solo quedan tres sellos: apostasía, el Anticristo y la persecución. Pero la iglesia será quitada antes de la ira, porque habrá una gran tribulación donde 144,000 judíos ungidos, más los dos testigos, terminarán de predicar el evangelio final. Jesús vendrá como Rey de Reyes y Señor de Señores. Los judíos clamarán por Jesús y todo Israel será salvo. Luego sonarán siete trompetas y se derramarán las siete copas de la ira de Dios sobre un mundo impío, y empezará el milenio bajo el gobierno de Jesús sobre todos los salvos. Hermosa esperanza.`,
    author: "Raúl Enrique",
    date: "23/05/2024",
    id: 160,
  },
  {
    title: "El Plan de Dios para un Mundo en Tinieblas",
    text: `Hermanos, es imposible que en un mundo caído y corrupto como este, que además es un reino de tinieblas espiritual donde el diablo es el gobernador de este siglo, como dijo Jesús, puedas sentirte pleno, sin problemas, sin temores, sin preocupaciones, en medio de la violencia y todo lo que ya conocemos. Este mundo no tiene futuro, va de mal en peor porque está bajo sentencia y maldición. Por esto, el Señor ideó un plan de salvación con la buena noticia del Evangelio para salvarte, por medio de Jesús, de la ira que vendrá sobre este mundo. Aquí nunca habrá felicidad perdurable para nadie, ni para ricos ni para pobres, ni para sabios ni para ignorantes, porque estamos de paso. Solo Jesús nos da salvación y vida ETERNA.`,
    author: "Raúl Enrique",
    date: "22/05/2024",
    id: 159,
  },
  {
    title: "Jesús y la Importancia del Amor Fraternal",
    text: `Dice Jesús: "Oíste que fue dicho a los antiguos: 'No matarás', pero yo os digo que cualquiera que le llame necio o fatuo a su hermano quedará expuesto al infierno de fuego." Hermanos, estas palabras del Señor erizan la piel con respecto al trato que debemos tener entre hermanos. Nos dice que debe haber absoluto amor y respeto entre hermanos y, además, perdonar al otro cuando hay diferencias. Jesús dijo: "En esto conocerán que son mis discípulos, si se aman unos a otros." Porque la gente decía: "Mientras no lo mate, puedo enojarme y ofender o maltratar al otro." Pero Jesús va más allá, estableciendo una nueva pauta moral. "Porque ay de aquel que hace tropezar a uno de mis pequeños y le cierran las puertas del reino." Porque, claro, si los ángeles hacen fiestas en el reino de los cielos cuando un pecador se arrepiente, ¡cuánto más nosotros cuando alguien entrega su vida a Cristo! Si te gustó, compártelo. Bendiciones.`,
    author: "Raúl Enrique",
    date: "21/05/2024",
    id: 158,
  },
  {
    title: "La Promesa del Galardón Divino",
    text: `Dice Dios: no pierdas tu confianza, que tiene grande galardón. Hermanos, Enoc caminó con Dios en fe y fue tal el agrado de Dios que lo traspuso para no ver muerte, o sea, se lo llevó con Él. Pero antes de haber sido traspuesto tuvo testimonio de haber agradado a Dios en fe, porque el que se acerca al Señor debe creer en su presencia, porque Él es galardonador de los que lo buscan. Además, creer es confiar y confiar da como resultado obediencia. Jesús te dice: He aquí, yo vengo pronto y mi galardón conmigo para recompensar a cada uno según sea su obra. Hagamos nuestro servicio al Señor con alegría, porque es hacer tesoros en el cielo. Sabemos que la salvación no es por obras, pero el Señor premiará a sus hijos con cinco coronas: la de vida, la de justicia, la de incorrupción, la de gloria y la corona de gozo. Jesús dijo: Mira que ninguno tome tu corona. Bendiciones.`,
    author: "Raúl Enrique",
    date: "20/05/2024",
    id: 157,
  },
  {
    title: "El Agua Física y el Agua Espiritual",
    text: `El pueblo judío daba gracias a Dios por el agua física, mirando al cielo porque sin el agua no existe la vida. Con el agua nos hidratamos, calmamos la sed, nos bañamos y lavamos todo lo que necesita limpieza. Podemos estar un tiempo sin comer, pero no podemos estar mucho tiempo sin beber agua debido a que nuestro cerebro necesita más hidratación. Necesitamos el agua física y somos agradecidos a Dios por ella. De la misma manera, necesitamos del agua espiritual que va más allá del cuerpo. Jesús observó cómo agradecían a Dios por el agua, se puso de pie y levantó su voz diciendo: "El que tenga sed, venga a mí y beba, y de su interior correrán ríos de agua viva que salte para vida eterna." Solo Él calma la sed del alma.`,
    author: "Raúl Enrique",
    date: "19/05/2024",
    id: 156,
  },
  {
    title: "Un Futuro Celestial",
    text: `El pasado es para aprender de los errores y no repetir lo malo. El presente es para vivir, actuar y desarrollarnos en fe y esperanza en medio de una sociedad crítica y difícil, esperando el futuro que aún no conocemos, con convicción y paciencia, a la espera de las promesas futuras dadas por Dios. Además, contamos con las arras del espíritu en este tiempo como adelanto de los poderes venideros. Las promesas cristianas tienen que ver con un nuevo orden mundial de características celestiales y divinas. Por eso, nosotros no esperamos que este mundo cambie, porque este mundo es un mundo de tinieblas donde solo brilla la luz del Señor y su iglesia verdadera. Así que lo mejor aún está por venir. Bendiciones.`,
    author: "Raúl Enrique",
    date: "18/05/2024",
    id: 155,
  },
  {
    title: "La Verdadera Victoria en Cristo",
    text: `Dice el apóstol Pablo: porque con Cristo somos más que vencedores. Porque nuestra victoria, hermanos, no está en lo que puedas obtener de este mundo pasajero. Como dijo el Señor Jesús: ¿de qué le sirve al hombre ganar todo el mundo y perder su alma en el infierno? Nada podemos llevar de este mundo porque todo le pertenece a Dios. Hermanos, la verdadera victoria de los cristianos es vivir en Él, es caminar en Él, es viajar por la vida con Él y llegar a destino con Él. Jesús debe ser el centro de nuestra vida, Él es nuestro objetivo porque en Él está la esperanza viva de la salvación de nuestra familia y de vivir la eternidad con todos los cristianos que aman al Señor.`,
    author: "Raúl Enrique",
    date: "17/05/2024",
    id: 154,
  },
  {
    title: "De la Sequedad a la Plenitud",
    text: `Dice la Biblia que un hombre tenía seca una mano. Creen que era albañil, pero a pesar de su situación, no dejaba de ir al templo. Seguramente cruzaba sus manos por vergüenza y se ubicaba entre los últimos, triste, deprimido, sin ganas de seguir adelante. Pero así como este hombre, existen muchas vidas secas hoy, con tristeza y dolor en el alma por los problemas de la vida. Pero como Jesús le dijo a este hombre, ven, ponte en medio para que todos te vean. Jesús le dijo extiende tu mano, lo tocó y la mano recobró vida, él lo sanó, comenzó a fluir la sangre por toda su mano. Así fluye la vida cuando tienes un encuentro con Jesús. Tu vida nunca más será igual, porque la vida de Jesús fluirá por tu vida y lo que estaba seco vivirá. Porque si alguno está en Cristo, nueva criatura es; las cosas viejas pasaron y en Él son todas hechas nuevas. Extiende tu vida al Señor y vivirá eternamente. Bendiciones.`,
    author: "Raúl Enrique",
    date: "16/05/2024",
    id: 153,
  },
  {
    title: "La Fuerza del Evangelio",
    text: `Dice el apóstol Pablo: "No me avergüenzo del evangelio porque es poder de Dios para todo aquel que cree." El evangelio es la buena noticia de Dios y viene cargado del poder del cielo como una dinamita para llegar a destino, para alcanzar al perdido, al sufrido, al que ha perdido la fe, la esperanza, al que se siente solo, triste, deprimido, despreciado, esclavizado, explotado, encarcelado. No debemos avergonzarnos de ese plan de salvación magistral del eterno por medio de su hijo, trayendo verdadera liberación, esperanza, paz y la promesa de inmortalidad del alma, porque el mensaje de la cruz es locura para los que se pierden, pero para los que se salvan es poder de Dios, porque para el que cree, todo es posible. Jesús es tu Salvador.`,
    author: "Raúl Enrique",
    date: "15/05/2024",
    id: 152,
  },
  {
    title: "Desenmascarando al Diablo",
    text: `Recuerdo un hecho policial: aquí, un fotógrafo fue asesinado por tomar una foto a un empresario poderoso, que nadie conocía excepto su círculo íntimo, porque para llevar adelante sus negocios le convenía el anonimato, para no ser reconocido como culpable de corrupción. Así es como opera un personaje de la vida real y espiritual conocido como Diablo, que quiere decir calumniador. Jesús dijo que es mentiroso y padre de mentira. Además, es homicida desde el principio y engaña a la humanidad desde el anonimato, haciéndole creer que él no existe, culpando a Dios de los sufrimientos. Pero Jesús dijo claramente que él vino a matar, hurtar y destruir, pero yo he venido a deshacer las obras del diablo. Que anda como león rugiente buscando a quien devorar. Pero Dios nos dice: resistid al diablo firmes en la fe y huirá de vosotros. Bendiciones.`,
    author: "Raúl Enrique",
    date: "14/05/2024",
    id: 151,
  },
  {
    title: "La Revelación del Reino",
    text: `Los discípulos le preguntan a Jesús: "Señor, ¿por qué nos hablas en parábolas?" Jesús les responde: "Porque a vosotros os es dado a conocer los misterios del reino." ¡Qué gran privilegio nos da el Señor al ser receptores de sus misterios! Porque Él es el Dios de la revelación para sus hijos. A la gente que lo seguía, les hablaba en parábolas porque estas exigen un grado de reflexión. Pero a sus discípulos les habla claramente. El Señor les dice: "Lo que os digo en secreto, proclamadlo a la luz, porque no hay nada oculto que no salga a la luz." Por esto, Pedro dijo: "Vosotros sois nación santa, real sacerdocio, pueblo escogido por Dios para anunciar las virtudes de aquel que nos llamó de las tinieblas a su luz admirable." No dejemos de predicar su evangelio, que cambia, transforma y vivifica el alma del sediento.`,
    author: "Raúl Enrique",
    date: "13/05/2024",
    id: 150,
  },
  {
    title: "La Verdadera Felicidad",
    text: `La felicidad es un estado de grata satisfacción física y espiritual. El mundo está en la búsqueda de la misma, y es natural que así sea. Los filósofos más famosos, sobre todo los griegos, no se ponían de acuerdo acerca del significado de la frase tan anhelada por todo ser humano. Pero buscar la felicidad en el sistema de este mundo es como buscar algo que no existe; jamás la vas a encontrar porque no existe. La verdadera felicidad no tiene principio ni fin; es algo verdadero y continuo. Solo se halla en Dios. Ahora, como cristiano, no busco la felicidad porque ya la encontré en Jesús. Solo que ahora debo aprender a vivir en felicidad, porque la felicidad de un día para mí es como la felicidad de toda la vida. Bendiciones.`,
    author: "Raúl Enrique",
    date: "11/05/2024",
    id: 149,
  },
  {
    title: "Alerta ante las Trampas",
    text: `¡Hermanos! Algo que ha crecido en materia de comunicación es el lenguaje informático y, como resultado de esto, existen muchos tipos de mensajes, de toda clase: buenos, no muy buenos y otros muy malos, que no convienen. Como profesantes de la fe, en esto por lo que muchos volverán a las fábulas y al berenjenal. La Biblia nos advierte que no nos dejemos engañar por medio de filosofías y huecas sutilezas, y nos mantengamos firmes en la profesión de nuestra esperanza en Cristo. Porque existen doctrinas de demonios que quieren engañar aún a los escogidos. Porque hay muchos falsos profetas con mentiras y herejías que se parecen mucho a la verdad, como un billete falso. Pero nosotros tenemos la palabra profética más segura, que alumbra como antorcha en lugares oscuros, porque las tinieblas desaparecen ante la luz de su palabra.`,
    author: "Raúl Enrique",
    date: "10/05/2024",
    id: 148,
  },
  {
    title: "La Evidencia de la Existencia Divina",
    text: `Un periodista le preguntó a un hombre de la cultura si creía en Dios. Él contestó que no veía evidencias de su existencia. Y recordé el salmo 53:1-4, que dice "el necio en su corazón no hay Dios". El mismo Dios lo llama necio por ser un necio moral, por no razonar ni considerar lo que tiene ante sus ojos. Ahora veamos la revelación gradual del Señor a la humanidad: 1) Se revela por la creación y la naturaleza. 2) Por la conciencia. 3) Por medio de la ley perfecta. 4) Por Jesús como el Verbo divino. 5) Por todas las escrituras. 6) Por los cristianos. 7) Por los testimonios. Además del universo, que funciona con precisión total, porque donde existe orden, existe un ordenador u organizador. Pablo dijo que su eterno poder y deidad se hacen claramente visibles, siendo entendidos por medio de las cosas hechas, de modo que no tienen excusas para no creer. Bendiciones.`,
    author: "Raúl Enrique",
    date: "09/05/2024",
    id: 147,
  },
  {
    title: "El Amor Inmenso de Dios",
    text: `De tal manera amó Dios al mundo que ha dado a su Hijo unigénito para que todo aquel que en Él cree no se pierda, mas tenga vida eterna. (Juan 3:16) Su palabra nos habla de la inmensidad de su amor, por la cual tomó la decisión de salvar sin acepción de personas al decir "todo aquel". Pero debido a la libre decisión de cada ser humano, serán salvos y libres de condenación aquellos que creen y aceptan el regalo de Dios para salvación. Por el contrario, no creer y rechazar a Jesús significa muerte y además condenación, porque la ira de Dios está y estará en tu vida. (Lee Juan 3:36) Debido a que Dios es Juez justo, no dejará sin castigo toda injusticia y aberraciones cometidas en este mundo. Jesús te perdona y te da vida ETERNA.`,
    author: "Raúl Enrique",
    date: "08/05/2024",
    id: 146,
  },
  {
    title: "Iluminados por el Espíritu Santo",
    text: "Dice Dios: Los entendidos resplandecerán como el sol. ¿Quiénes son los entendidos? Son aquellos iluminados por el Espíritu Santo, capaces de comprender las señales de los tiempos en que vivimos. Nos exhorta a prepararnos para su venida, llevando una vida vigilante en armonía con su voluntad, ya que su juicio vendrá como un lazo sobre el mundo, sorprendiendo como ladrón en la noche. Así como en los días de Noé, debemos estar atentos y no dejarnos llevar por la aparente tranquilidad del mundo, pues no comprenden lo que está por venir. Bendiciones.",
    author: "Raúl Enrique",
    date: "06/05/2024",
    id: 145,
  },
  {
    title: "La Inmutabilidad de la Palabra Divina",
    text: "Dice Jesús: Cielo y tierra pasarán, pero mis palabras jamás pasarán. Porque ni una jota ni una tilde pasará de mi palabra. Hermanos, la letra jota es la letra más insignificante del alfabeto griego y una tilde es un punto. De manera que el Señor dice: Ni la letra más insignificante ni siquiera un punto pasará de mi palabra. El Señor dice: He aquí, yo envío mi palabra y no volverá a mí vacía y sin resultados, sino que hará lo que yo quiero. Un filósofo llamado Voltaire, francés, que odiaba a los cristianos y la Biblia, dijo: En un año haré desaparecer la Biblia. Pero un cristiano lo escuchó decir eso y al poco tiempo Voltaire murió y el cristiano compró su casa a los herederos y la llenó de Biblias. Su palabra es viva y eficaz.",
    author: "Raúl Enrique",
    date: "05/05/2024",
    id: 144,
  },
  {
    title: "El Poder Transformador de la Fe",
    text: "Dice Pablo: Cuando fui a vosotros, no fui con palabras persuasivas de humana sabiduría, sino con demostración de espíritu y poder, para que vuestra fe no esté fundamentada en la sabiduría de los hombres, sino en el poder de Dios. Hermanos, la fe viene por el oír, y el oír la palabra de Dios, que es limpia, pura y fresca, que fluye y entra por vuestros oídos físicos a las cámaras más íntimas de vuestro espíritu. Al recibir información de Su palabra muy adentro nuestro, hay neuronas cognitivas que engloban la conciencia, la percepción, el pensamiento y la memoria. Entonces, el don de la razón hace una evaluación de la información que recibís y da como resultado la convicción de fe para la salvación por gracia en Cristo Jesús. Bendiciones.",
    author: "Raúl Enrique",
    date: "04/05/2024",
    id: 143,
  },
  {
    title: "Israel: El reloj profético.",
    text: `Dice Jesús de la higuera, aprended la parábola. Cuando su rama está tierna y brotan las hojas, sabéis que el verano está cerca. Pero cuando veáis a Jerusalén rodeada de ejércitos, levantad vuestras cabezas porque vuestra redención se acerca, hermanos. Una parábola es un relato terrenal con un significado espiritual. ¡Israel! Estuvo y está rodeado de enemigos, pero como cristianos tenemos que saber que Israel es el reloj profético de Dios y floreció cuando fue reconocido como un estado judío en 1948. Ahora, Israel es una potencia con tecnología de punta. Como vemos, está siendo amenazado de ser borrado del mapa; esto es una señal más que demuestra que estamos viviendo en tiempos del fin porque el Diablo, con sus ejércitos, se levantará contra el pueblo judío. Pero entrarán en la angustia de Jacob hasta que clamen a Jesús por salvación. Bendiciones.`,
    author: "Raúl Enrique",
    date: "02/05/2024",
    id: 142,
  },
  {
    title: "Resucitar con Cristo",
    text: `Así como Cristo murió en debilidad y resucitó en poder, así también nosotros moriremos en debilidad pero también resucitaremos en poder, porque el poder de su resurrección que actúa en nosotros. Muchos preguntan ¿pero cómo es que resucitaremos? Resucitar es ponerse de pie nuevamente, por ejemplo, Pablo dice que es como una semilla que se siembra. Así es la resurrección de los muertos. Se siembra en corrupción y resucitará en incorrupción. Se siembra en deshonra y resucitará en gloria. Se siembra en debilidad y resucitará en poder. Pablo dice: He aquí os digo un misterio: no todos dormiremos, porque en un pestañeo seremos transformados para ser arrebatados al cielo con Cristo. ¡Qué gloriosa esperanza la de los cristianos!`,
    author: "Raúl Enrique",
    date: "01/05/2024",
    id: 141,
  },
  {
    title: "Alerta y Esperanza",
    text: `Tal como su palabra lo dice, tristemente el mundo va de mal en peor. Los cristianos debemos estar alertas y vigilantes ante los hechos que pasan en este mundo, y aumentan desde que la pandemia puso al mundo en un caos total. Ahora hay guerras y rumores de guerras, hablando en términos mundiales, tal como Jesús lo dijo, y otros males que vendrán como principio de dolores, pero aún no es el fin. Se aproxima un derrumbe social, cultural, político y religioso a gran escala. Nosotros no esperamos que el mundo cambie, esperamos nuestra bendita esperanza: que la Iglesia sea quitada del mundo. Él vendrá como ladrón en la noche para los que no lo esperan y están distraídos con la incredulidad de este mundo. Ánimo, Él vendrá.`,
    author: "Raúl Enrique",
    date: "30/04/2024",
    id: 140,
  },
  {
    title: "Promesas y Fidelidad",
    text: `Job, siervo de Dios, dice: Si tú de mañana buscares y rogares al Todopoderoso, si fueres limpio y recto, ciertamente luego se despertará por ti y hará próspera tu justicia. Aunque tu principio haya sido pequeño, tu postrer estado será más grande que el primero. Hermanos, no hay duda de que nuestro Dios es un Dios de promesas y además es fiel a sus promesas, porque no es hombre ni hijo de hombre para que mienta ni se arrepienta. Dice Pablo: Sea Dios veraz, aunque todo hombre sea mentiroso. Solo tenemos que movernos en línea con su voluntad y Él hará que vivamos confiados y expectantes ante el futuro que nos espera a los cristianos de un nuevo mundo, y disfrutar de su palabra que es como antorcha que alumbra en la oscuridad.`,
    author: "Raúl Enrique",
    date: "29/04/2024",
    id: 139,
  },
  {
    title: "No te rindas",
    text: `Estás triste, cansado, agobiado por las exigencias, los problemas y las pruebas de la vida. No te rindas, no aflojes la cuerda de tu vida, porque si fueres flojo en el día de trabajo, tus fuerzas se reducirán, dice Su palabra. Quiere decir que si vivimos la vida sin fe, sin esperanzas, sin ganas, tus pocas fuerzas se reducirán aún más. Basados en el consejo del Señor de sobreponernos por encima de las circunstancias de la vida, porque aunque tu vida pase por un desierto, Él tiene el agua de vida que sacia tu alma. Él te dice hoy: "Jehová el Señor te pastoreará siempre, y en las sequías saciará tu alma, Él le dará vigor a tus huesos y serás como huerto de riego y manantial de vida que salte para vida eterna". Bendiciones.`,
    author: "Raúl Enrique",
    date: "28/04/2024",
    id: 138,
  },
  {
    title: "La Palabra que Alimenta",
    text: `A mi familia, a hermanos en Cristo, a los amigos, vecinos y conocidos, y a quienes leen mis reflexiones espirituales que hago cada día para animarme y animarlos con la preciosa semilla de la palabra del Altísimo. Porque su palabra es vida, sino mira lo que dice el Señor: No solo de pan vivirá el hombre, sino de toda palabra que sale de la boca de Dios, porque para estos días dice el Señor: Enviaré no hambre de pan ni sed de agua, sino de oír mi palabra, que es el alimento que necesita tu alma y la mía. Porque el regalo de la vida es hermoso, a pesar de las malas acciones de un mundo caído, nos plantea un desafío en medio de los problemas de la vida y nos llama a ser valientes, porque con Cristo todo es posible.`,
    author: "Raúl Enrique",
    date: "27/04/2024",
    id: 137,
  },
  {
    title: "Confiando en las Promesas",
    text: `Dice la palabra de Dios, en Lamentaciones 3:22. Por tu misericordia no hemos sido consumidos, porque nunca decayeron tus misericordias; nuevas son cada mañana. Grande es tu fidelidad. Qué hermosas promesas del Señor para sus hijos, los que confían en Él. Uno cree porque confía y porque confía, obedece. Porque el justo por la fe vivirá. La esperanza cristiana y el regocijo interior no dependen de las circunstancias exteriores, esto tiene que ver con una relación de intimidad con el Señor, que nos ayuda a sobreponernos por encima de tribulaciones y pruebas de la vida, porque sabemos que nuestro galardón está por delante, garantizado por aquel que hace las promesas, porque todas sus promesas son un sí y un amén en Él, porque Él no falla.`,
    author: "Raúl Enrique",
    date: "26/04/2024",
    id: 136,
  },
  {
    title: "El Amor y la Justicia de Dios",
    text: `El amor de Dios es tan amplio que nos dio el libre albedrío, lo cual demuestra que no es un dictador que ama la libertad, producto de su amor. En un reino de justicia debe existir la libertad. Siendo Dios soberano, siempre actúa con justicia aunque no entendamos sus métodos o formas. Nadie se escapa de sus juicios. Él no abusa de su poder, pero por ser Dios también es fuego consumidor. Horrible cosa es caer en manos de un Dios vivo, santo y amante de la justicia, que no tendrá por inocente al malvado, dice su palabra. Para esto envió a Jesús para que no te pierdas. Además, nos dejó su palabra escrita de procedencia divina como un manual de conducta para conocer su perfecta voluntad y para que nos vaya bien en la vida.`,
    author: "Raúl Enrique",
    date: "25/04/2024",
    id: 135,
  },
  {
    title: "La Importancia de la Misericordia",
    text: `El Señor es lento para la ira y grande en misericordia. Por esto nos enseña diciendo: bienaventurados los misericordiosos, porque ellos alcanzarán misericordia. Porque juicio sin misericordia se hará con el que no practica la misericordia, y la misericordia triunfa sobre el juicio. El Señor Jesús enseñó que la misericordia siempre debe ser lo primero ante las necesidades de la vida. Por eso, mientras Jesús predicaba, sus discípulos tuvieron hambre y comieron del trigo, lo cual no estaba permitido hacer según la ley. Le dijeron a Jesús por qué lo permitía. Jesús les recordó que David, ante su necesidad, comió del pan de la proposición que solo podían comer los sacerdotes, y les dijo: 'Misericordia quiero y no sacrificio'. Bendiciones.`,
    author: "Raúl Enrique",
    date: "23/04/2024",
    id: 134,
  },
  {
    title: "El Poder del Amor",
    text: `El amor es lo que sostiene el universo, el mundo y la belleza de la vida que disfrutamos. Pareciera que el mal es triunfante porque es noticia ante lo ilógico de su maldad. Pero el mal, con respecto al amor, es minoría, por ser irracional e inexplicable, pero el mal es temporal y pasajero. En cambio, el amor por hacer el bien es algo lógico y eterno. Además, el mal nunca podrá vencer al amor, que es el antídoto contra el mal, el odio y el rencor. El verdadero amor es entrega, es el vuelo puro del corazón hacia otros. Él mismo ofreció su vida por la tuya y la mía. El mal nunca triunfará porque Dios es la fuente del amor que jamás deja de ser. Es por esto que su palabra dice que su misericordia es para siempre. Bendiciones.`,
    author: "Raúl Enrique",
    date: "21/04/2024",
    id: 133,
  },
  {
    title: "Solidaridad ante el Sufrimiento Universal",
    text: `Hermanos, la enfermedad, la muerte, el dolor y las lágrimas no tienen límites ni fronteras. El Señor dice que el amor llora con los que lloran y ríe con los que ríen. Hoy vemos el sufrimiento de miles de personas que son víctimas de violencia, de guerras que son muy crueles. Millones de personas dejan sus casas buscando huir del peligro, con niños, ancianos, jóvenes sin futuro. Porque el dolor y las lágrimas no hacen excepción de personas, las lágrimas de un judío son las mismas que las de un palestino, iraní, ruso o ucraniano. Dolor es dolor. Hay profecías que deben cumplirse, eso no lo podemos cambiar, pero debemos orar en nuestra intimidad al Señor para que Dios fortalezca a cristianos y no cristianos que sufren. Bendiciones.`,
    author: "Raúl Enrique",
    date: "20/04/2024",
    id: 132,
  },
  {
    title: "Separación del Mundo",
    text: `Dice Dios: No améis las cosas que están en el mundo. Si alguno ama al mundo, el amor del Padre no está en él. Porque todo lo que hay en el mundo, el deseo de la carne, el deseo de los ojos y la vanagloria de la vida, no proviene del Padre, sino del mundo. También dice: Si alguno se hace amigo del mundo, se constituye enemigo de Dios. El Señor nos enseña a mantener firmes la profesión de nuestra esperanza, puestos los ojos en Jesús, sin dejarnos moldear por el mundo con entretenimiento no cristiano, con música de origen mundano que no proviene del Padre. Porque el que tiene el Espíritu de Dios sabe lo que edifica a la iglesia, porque de lo contrario puede ser fuego extraño que Dios no tolera.`,
    author: "Raúl Enrique",
    date: "19/04/2024",
    id: 131,
  },
  {
    title: "El Lenguaje Puro del Evangelio",
    text: `Dice: Dios. En los últimos tiempos, le daré a los pueblos un lenguaje puro. Ese lenguaje puro es el lenguaje del Evangelio, la buena noticia de la salvación. Porque sin Jesús y el Evangelio no hay posibilidad de salvación. El hombre fue sentenciado a muerte eterna por el mismo Dios. También dijo que está establecido por Él que el hombre muera una vez y después el juicio. Por eso Jesús no adulaba a la gente, porque se trata de algo muy serio, y los confrontaba con la verdad, diciendo: arrepentíos y creed en el Evangelio. Porque el que creyere y fuere bautizado será salvado, pero el que no creyere será condenado. Así que si alguno les predica otro evangelio, sea anatema o maldito, porque el Evangelio de Jesús es el verdadero.`,
    author: "Raúl Enrique",
    date: "18/04/2024",
    id: 130,
  },
  {
    title: "Navegando en un Mundo Crítico",
    text: `El mundo en el que vivimos es crítico, caótico y difícil. Pablo dijo que vendrán días peligrosos, pero como cristianos debemos aprovechar bien el tiempo porque los días son malos. A medida que pasa el tiempo, el mundo irá de mal en peor, porque ya entró en un escenario de tiempos proféticos como Dios lo decretó y no hay vuelta atrás. Este mundo, como lo conocemos, pasará porque es como la antesala para entrar a un nuevo orden mundial de características divinas, donde habrá cielo nuevo y tierra nueva, donde morará la justicia, el cual Dios preparó para que sea habitada por sus hijos. Así que ánimo, porque lo mejor aún está por venir. El Señor dijo: velad y orad para que haya aceite en tu lámpara y arda el fuego de Dios en tu vida.`,
    author: "Raúl Enrique",
    date: "17/04/2024",
    id: 129,
  },
  {
    title: "Entre el Luto y el Banquete",
    text: `Dice el predicador: Mejor es ir a la casa del luto que a la casa del banquete. Hermanos, esto parecería que fuéramos amargados o aguafiestas, pero no. El mensaje es que a la hora de reflexionar sobre la vida, esos momentos son los que nos muestran, al mirar a alguien que falleció, más allá de la tristeza que esto provoca, vemos la vanidad de lo temporal, efímeros y pasajeros que somos en esta vida tan corta, que nos hace pensar que deberíamos ser sencillos, humildes, solidarios, amantes de Dios, de la familia y de todo lo bueno. Porque lo importante no es solo quien estuvo en tus días de alegrías y fiestas, sino quien está en tus días difíciles de dolor y lágrimas. El Señor nos promete su consuelo y su presencia. Bendiciones.`,
    author: "Raúl Enrique",
    date: "15/04/2024",
    id: 128,
  },
  {
    title: "La Época Más Gloriosa: Recibir al Señor en Tu Corazón",
    text: `Cuando recibiste al Señor en tu corazón por la fe, entraste en la época más gloriosa de tu vida. Entraste al camino, la verdad y la vida. Ya no tienes que buscar otro camino porque ya estás en Él. Tampoco otra verdad, porque Jesús es la verdad absoluta. Menos la vida, porque Él es espíritu y vida. Ya eres parte de la naturaleza divina, por eso el Señor te llama bienaventurado, doblemente afortunado por tener un nuevo nacimiento, porque si alguno está en Cristo, nueva criatura es; las cosas viejas pasaron y en Él son todas hechas nuevas. Esta vida nadie te la puede quitar ni cambiar, se hizo realidad porque en lo profundo de tu vida y tu ser resonó su amor y ahora su misericordia y su verdad te seguirán en tu vida siempre.`,
    author: "Raúl Enrique",
    date: "14/04/2024",
    id: 127,
  },
  {
    title: "La Fortaleza en la Presencia Divina",
    text: `¿A quién tengo yo en el cielo sino a ti? Si estoy contigo, ya nada quiero en la tierra. Podrán desfallecer mi cuerpo y mi espíritu, pero Dios fortalece mi corazón. Él es mi herencia eterna. Salmo 73:25-26. Si vemos el contexto de estas palabras, podemos ver que el salmista, por su naturaleza pecaminosa como la nuestra, se vio tentado a envidiar la prosperidad de los malos. Pero al reflexionar bien en la vida, se da cuenta de que lo que desea es la presencia de Dios en su vida, porque de verdad Él es nuestra máxima fortaleza. Estar cerca de Él es lo mejor para mi vida, por ser bondadoso con nosotros y más valioso que cualquier otra cosa en este mundo. Solo Él le da significado a tu vida y a la mía. ¡A Él sea toda la gloria! Bendiciones.`,
    author: "Raúl Enrique",
    date: "11/04/2024",
    id: 126,
  },
  {
    title: "La advertencia de Jesús sobre los falsos profetas",
    text: `Dice Jesús: No todo el que me dice Señor, Señor entrará en el reino de los cielos, sino el que hace la voluntad de mi Padre que está en los cielos. Porque muchos me dirán en aquel día: Señor, nosotros predicamos, echamos demonios, hicimos milagros. Entonces les declararé: Apartaos de mí, hacedores de maldad. Al fuego eterno preparado para el diablo y sus ángeles. Hoy, como antes, existen falsos profetas. Jesús dijo que al final se multiplicarán porque la gente tendrá comezón de oír la palabra de Dios, pero hay manipuladores profesionales que tendrán apariencia de piedad para engañar por ganancias deshonestas, dice Pablo: Apártate de los tales. Son ciegos guías de ciegos. Ambos caerán al pozo. Dios nos libre del maligno.`,
    author: "Raúl Enrique",
    date: "08/04/2024",
    id: 125,
  },
  {
    title: "El amor justo de Dios",
    text: `Hermanos queridos, nuestro Dios no hace acepción de personas. Él es amor y además es Juez justo. Su propósito es que todos los hombres procedan al arrepentimiento, pero existe un libre albedrío, es decir, libre decisión de reconocer a Dios en su vida y el plan magistral del evangelio y su poder para salvar. Además, Él nos aconseja para nuestro bien y el de nuestra familia. Mira lo que Él dice a los cielos y a la tierra, los llamo por testigos que he puesto delante de vosotros la vida y la muerte, la bendición y la maldición, y añade con una ayuda para decidir con certeza que escojan la vida, como diciendo "yo sé lo que te conviene". Por supuesto, el futuro de tu vida y la mía solo lo puede conocer Él, que es eterno y verdadero. Bendiciones.`,
    author: "Raúl Enrique",
    date: "07/04/2024",
    id: 124,
  },
  {
    title: "La Fortaleza en la Fe",
    text: `El rey David dijo: Hubiera yo desmayado, sino creyere que veré la bondad de Jehová el Señor en la tierra de los vivientes. El siervo Job dijo: Porque yo sé que mi Redentor vive. El apóstol Pablo dijo: Porque yo sé en quién he creído. Jesús le dice a Pedro: ¿También tú te quieres ir? Él le dice: ¿A quién iremos, Señor? Si solo tú tienes palabras de vida eterna. Pedro se llamaba Simón, que quiere decir inestable, pero el Señor le puso Pedro, que significa piedra. Él ya no sería débil y cambiante, sino tan fuerte como una piedra porque sería parte de la roca que es Cristo. Tú también, hermano, puedes decir que eres débil ante el Señor, pero él fortalece tu vida ante el sistema de este mundo, con su poder y su amor para darte vida eterna.`,
    author: "Raúl Enrique",
    date: "06/04/2024",
    id: 123,
  },
  {
    title: "La Liviana Carga del Amor",
    text: `Al de corazón quebrantado no despreciarás tú, oh Dios. Por eso nos invita y dice: Vengan a mí todos los que están cansados y cargados, y los haré descansar. Aprendan de mí, que soy manso y humilde de corazón, y hallarán descanso para sus almas. Porque mi yugo es fácil y ligera mi carga, pues mis mandamientos no son gravosos, no lastiman, no hacen daño, y si los llevamos con amor, será algo liviano. Él, como carpintero, fabricaba yugos para los animales y tenía cuidado de que sean suaves y perfectos, y se adapten bien, de modo que no lastimen el cuello del animal. Cuanto más cuidado tiene el Señor de sus hijos, que nos enseña que si llevamos todo con amor, será algo liviano, porque no será una carga, sino una bendición.`,
    author: "Raúl Enrique",
    date: "04/04/2024",
    id: 122,
  },
  {
    title: "Cristo, Nuestra Fuente",
    text: `Te invitamos a nuestras reuniones como Iglesia de Cristo para compartir la palabra de Dios, que es el verdadero pan del cielo. Él dijo: El que come de mi pan nunca tendrá hambre, hablando espiritualmente, y el que beba de mi agua no tendrá sed jamás, porque el que cree en mí, como dice las Escrituras, no tenga sed. Venga a mí y beba, y de su interior correrán ríos de agua viva, agua que fluye limpia, pura y fresca, sin virus, sin bacterias, sin parásitos, sin contaminación. Jesús dijo que cualquiera que tome agua natural volverá a tener sed, pero el que beba de las aguas que yo le daré no tendrá sed jamás, porque será una fuente de agua viva que saltará para vida eterna. Bendiciones.`,
    author: "Raúl Enrique",
    date: "01/04/2024",
    id: 121,
  },
  {
    title: "Resurrección y la Esperanza",
    text: `Por la mañana tuvimos una hermosa reunión de adoración, celebrando y recordando el sacrificio de Cristo, nuestro Señor, para salvarnos de una condenación segura. Precisamente porque resucitó es que celebramos y esperamos su retorno. Los cristianos del primer siglo vivían expectantes en el poder de su resurrección, y el anhelo de su venida los mantenía vivos y fuertes para presentar al incrédulo un mensaje con un poder transformador, ya que este mensaje era un mensaje de esperanza viva, que tocaba las fibras más íntimas de un corazón deseoso de escuchar el poder de su palabra, que es limpia, pura y fresca, y que daba un verdadero sentido y contenido al hombre y a la mujer que estaban sin esperanzas. Bendiciones.`,
    author: "Raúl Enrique",
    date: "31/03/2024",
    id: 120,
  },
  {
    title: "Jesús y los Signos del Fin",
    text: `Escatología bíblica es el estudio del fin del mundo y las últimas cosas que sucederán. Podemos ver con total seguridad el cumplimiento preciso de las profecías. Desde el 2020, con la pandemia, el mundo ya no es el mismo; un pequeño virus microscópico puso al mundo al revés, donde el encierro y el pánico se apoderaron del mundo. Jesús dijo que habría grandes cambios que darían paso a un escenario de catástrofe mundial antes de su venida. Además, dijo que Israel sería protagonista por ser el reloj profético de Dios. De todos esos acontecimientos, también dijo que en medio de todo eso aparecería el anticristo exigiendo adoración a todo el mundo. Pero Jesús le pondrá fin al diablo, al anticristo y al falso profeta. Bendiciones.`,
    author: "Raúl Enrique",
    date: "30/03/2024",
    id: 119,
  },
  {
    title: "El Perfecto Amor de Jesús",
    text: `Bueno es vivir la vida en fe, esperanza y amor, pero la fe pasa por ser solo un medio para alcanzar la salvación. La esperanza deja de ser esperanza cuando se hace realidad lo esperado, pero el amor es eterno porque nunca deja de serlo. Así de poderoso es el vasto amor de Dios. El Señor Jesús es el perfecto amor, es la mayor expresión del amor de Dios, entregando a su propio hijo en rescate para librarnos de la condenación. Así como no podemos entender a Dios con nuestra mente finita, tampoco entendemos la dimensión del amor de Dios en Jesús. Su amor es un misterio de piedad que se entregó por vos y por mí a la muerte, pero resucitó, y ahora el poder de su resurrección actúa en nosotros para darnos la victoria. Bendiciones.`,
    author: "Raúl Enrique",
    date: "29/03/2024",
    id: 118,
  },
  {
    title: "El Gran Reinicio: Preparándonos para un Nuevo Orden Mundial",
    text: `Seguramente has escuchado sobre el Gran Reinicio por parte de las autoridades de la ONU. Esto implica comenzar de cero nuevamente. Pero para esto debe haber un Nuevo Orden Mundial con un solo gobierno, una moneda y una religión. Precisamente eso es lo que hará el Anticristo, ese personaje llamado la Bestia, que la gente seguirá por su poder y carisma hasta que se revele su engaño exigiendo adoración, y todo aquel que no lo adore ni se marque con el 666 no podrá comprar ni vender. Lo dice la Biblia; todo se prepara para un Nuevo Orden Mundial. Imagínate la maldad de Hitler; este será peor porque gobernará al mundo entero. Por eso, antes de que esto se desate, la Iglesia de Cristo será arrebatada al cielo, muy lejos de aquí. Bendiciones.`,
    author: "Raúl Enrique",
    date: "28/03/2024",
    id: 117,
  },
  {
    title: "La Revelación de Dios",
    text: `Dice Dios: Yo soy el que anuncia desde el principio las cosas por venir y doy a conocer por anticipado lo que aún no ha sucedido. (Isaías 46:10). También dice en Amós 3:7 que no hará nada el Señor sin revelar sus secretos a sus siervos los profetas. El Señor nos avisa para que estemos atentos a las señales de los tiempos, como dijo Jesús, porque de acuerdo a su palabra estamos viviendo en tiempos de cumplimientos proféticos. No es para asustarnos ante las cosas que vendrán sobre este mundo, porque nosotros seremos guardados por el poder de Dios (Apocalipsis 3:10) antes que caiga la ira de Dios sobre este mundo. Así que sirvamos a Dios con temor y reverencia, unidos con nuestros hermanos de la fe como el cuerpo de Cristo, nuestro Señor.`,
    author: "Raúl Enrique",
    date: "27/03/2024",
    id: 116,
  },
  {
    title: "El Gozo que Sólo Dios Puede Dar",
    text: `Dice David: Como el siervo clama por las corrientes de las aguas, así clama por ti, oh Dios, el alma mía. Mi alma tiene sed, sed del Dios vivo. Parecía que nada le faltaba en la vida al Rey, pero le faltaba algo: la satisfacción de un alma sedienta, seca, vacía, sin contenido, que traía a su vida temor que lo paralizaba y no lo dejaba ser feliz en plenitud. Necesitaba el gozo y la alegría que solo Dios puede dar, por ser la fuente de vida que te da valor para seguir con el poder de su amor. Es por esto la importancia del Señor Jesús en tu vida y en la mía. Cuando Él levanta su voz y te invita a beber de su agua gratuita. El que tenga sed, venga a mí y beba, y de su interior correrán ríos de agua viva. Solo Él sacia tu vida.`,
    author: "Raúl Enrique",
    date: "26/03/2024",
    id: 115,
  },
  {
    title: "La Esencia del Cristianismo",
    text: `El fundamento del cristianismo tiene que ver con tres aspectos elementales: primero, con la muerte de Jesús; segundo, con la resurrección de Jesús; y tercero, con el poder de su resurrección. Y existen muchas evidencias de la misma. Cuando las mujeres fueron a visitar la tumba de Jesús, vieron que estaba vacía, pero dos ángeles estaban en pie en la tumba y les dijeron a las mujeres: ¿Por qué buscáis al que vive entre los muertos? Él no está aquí, ha resucitado. Te das cuenta de que si Jesús no hubiera resucitado, seríamos una de todas las religiones muertas del mundo. Estaríamos muertos, vacíos, sin esperanza, con una vida vacía de contenido y significado. Pero porque Él vive, nosotros también viviremos. ¡A Él sea toda la gloria!`,
    author: "Raúl Enrique",
    date: "24/03/2024",
    id: 114,
  },
  {
    title: "Oración al Señor Todopoderoso",
    text: `Señor, mi Dios Todopoderoso, que habitas en la altura y en la eternidad, que contemplas toda la tierra desde tu santo trono, para salir en favor de aquellos que te buscan. Señor, tu misma palabra dice que tú eres único, porque antes de ti no hubo Dios y después de ti tampoco. Señor, por eso dirijo a ti mi oración, mi adoración y alabanza en el nombre poderoso de tu hijo amado, Jesús. Te agradezco por tu Espíritu Santo, por tu palabra, por tus promesas fieles y verdaderas, por la familia, por la iglesia, por la salud, por el sustento de cada día. Oramos por aquellos que sufren, por los que padecen necesidad, por los que están tristes y agobiados. Llegue a ellos la salvación como llegó a nosotros, solo por tu gracia y tu misericordia. Bendiciones.`,
    author: "Raúl Enrique",
    date: "18/03/2024",
    id: 113,
  },
  {
    title: "El Papel Sagrado de la Familia en el Plan de Dios",
    text: `La familia es una idea de Dios y es de procedencia divina. Podemos amar a nuestra familia de manera muy natural y disponer todo nuestro amor por el bienestar de cada uno de los miembros de nuestra familia, deseando la felicidad. Como una estructura de apoyo en el plan de Dios, para ayudarnos a resistir el mal y fortalecernos en estos días de confusión y oposición de las fuerzas del mal, que ponen en peligro la estructura familiar de toda la vida, con cambios culturales contrarios a valores de la familia y del mismo Dios, quien la estableció como una bendición a la humanidad, viviendo de acuerdo con su voluntad divina. Jesús dijo que una casa dividida no puede permanecer, pero si estamos unidos en Cristo, vendrán tempestades pero nuestra casa estará fundamentada y firme en la roca de Cristo. Bendiciones.`,
    author: "Raúl Enrique",
    date: "13/03/2024",
    id: 112,
  },
  {
    title: "Confianza en el Maestro: Aceptando los Misterios Divinos",
    text: `Dios es tan grande que no tenemos capacidad de imaginación con nuestra mente finita para captar la increíble sabiduría de Dios, en quien habitan los tesoros de la sabiduría. Cuando vemos la manifestación de su poder por medio del universo y la vida en el planeta que fue preparado para ser habitado por el hombre, con el alimento necesario para la vida, en la diversidad de sus maravillas. Aunque a veces suceden cosas que no entendemos bien por qué suceden, solo debemos confiar en el Maestro y recibir de sus beneficios. Por ejemplo, puede que no entendamos cómo funciona un aparato electrónico y sus principios, pero nada nos impide acceder a sus beneficios. Así también, no entendemos bien cómo obra Dios y su Espíritu Santo, pero nada nos impide recibir los beneficios de su gracia por medio de la fe en Cristo Jesús, nuestro amado Salvador. Bendiciones.`,
    author: "Raúl Enrique",
    date: "12/03/2024",
    id: 111,
  },
  {
    title: "La voz en el desierto",
    text: `El Señor dice: Voz que clama en el desierto, levanta tu voz. Si le dice el profeta, ¿pero qué tengo que decir, Señor? Dile al pueblo que toda carne es como hierba y toda gloria como flor que se marchita, porque el viento del Señor se las lleva, porque la hierba se seca y la flor se cae, pero mi palabra jamás pasará. Está claro que Dios quiere que el hombre se dé cuenta de que de nada le sirve el orgullo, la soberbia, la altivez, la necedad y la insensatez, porque el hombre es mortal y corto de vida, que debe reconocer al Dios altísimo que puede darle vida cuando se arrepiente de sus pecados y se humilla. El Señor dice: El cielo es mi trono y la tierra estrado de mis pies, pero miraré al pobre y humilde de espíritu que tiembla a mi palabra.`,
    author: "Raúl Enrique",
    date: "11/03/2024",
    id: 110,
  },
  {
    title: "La importancia de la Fe en la Vida Espiritual",
    text: `Porque sin fe es imposible agradar a Dios. Es imposible porque sin fe no le das valor a las necesidades del alma y el espíritu. Puedes creer que todo pasa por el cuerpo. Por eso se dice comamos y bebamos que mañana moriremos, disfrutemos de la vida que es una sola. Hay una canción que dice la vida es un carnaval, nada más lejos de la verdad y la realidad de un mundo que va de mal en peor. El Señor quiere que disfrutemos lo bueno en Él, porque el mundo y sus deseos pasan, pero el que hace la voluntad de Dios permanece para siempre. En cambio, el sistema de este mundo pasará lejos de Dios. Aunque el hombre logre fama, poder o riquezas, si pensamos que ya no están y se marchitaron con su gloria, ya nadie los recuerda. Bendiciones.`,
    author: "Raúl Enrique",
    date: "10/03/2024",
    id: 109,
  },
  {
    title: "La Libertad en Cristo",
    text: `Jesús dijo: conoceréis la verdad y la verdad os hará libres, porque si el Hijo del Hombre os liberta, seréis verdaderamente libres. No conocer la verdad de algo es no conocer la realidad de algo. Los hombres pueden conocer alguna faceta de la verdad, como el caso de Isaías, que descubrió la santidad de Dios, Oseas la misericordia de Dios, o Amós la justicia social. Pero Jesús es la verdad total y absoluta, por eso dice que con Él seremos verdaderamente libres, porque si crees que eres libre sin el perdón de los pecados por su sacrificio perfecto, estás equivocado. Porque él dice que si no crees en mí, estás aún en tus pecados, y si te vas de este mundo sin arrepentimiento y fe en Él, serás condenado. Lo dice Dios, no yo.`,
    author: "Raúl Enrique",
    date: "09/03/2024",
    id: 108,
  },
  {
    title: "La Verdad y la Seguridad Eterna en Jesús",
    text: `Muchas personas seguían a Jesús por los milagros que hacía, pero cuando el Señor habló de la verdad de su palabra, muchos retrocedieron. Pedro estaba a su lado y Jesús le dijo: ¿También tú te quieres ir, Pedro? Él le respondió: No, Señor, ¿a dónde iremos si solo tú tienes palabras de vida eterna? ¡Qué gran verdad dijo Pedro! Porque Jesús dijo: Porque yo vivo, vosotros también viviréis. Porque yo soy la resurrección y la vida; el que cree en mí, aunque esté muerto, vivirá. Porque el que oye mi palabra y cree al que me envió, tiene vida eterna. El Señor es la verdad, la esperanza, la paz y la inmortalidad del alma. Sus promesas son fieles y no están basadas en algo hipotético, porque lo hipotético no es algo seguro. Pero Jesús es seguridad eterna.`,
    author: "Raúl Enrique",
    date: "07/03/2024",
    id: 107,
  },
  {
    title: "Fe y Oración",
    text: `La fe es una frase muy corta pero de grandes dimensiones. Tanto qie puede alcanzar lo sobrenatural como consecuencias eternas. Por esto dice la Biblia que la oración del justo puede mucho. El mismo Señor dijo: Si tuvieras fe como un grano de mostaza y le dijeras a ese monte pásate al otro lado, se pasará . Por supuesto, es una metáfora para avivar tu fe cuando tienes problemas en la vida que para ti y para mí pueden ser tan grandes como una montaña. Él nos invita a clamar a él en oración y no tiene que ser con un lenguaje religioso, sino de manera natural, con corazón humilde y sincero. Él nos promete que nos oirá. Mira lo que dice: Clama a mí y yo te responderé y te enseñaré cosas grandes y ocultas que tú no conoces.`,
    author: "Raúl Enrique",
    date: "06/03/2024",
    id: 106,
  },
  {
    title: "Responsabilidad Familiar en la Fe",
    text: `El apóstol Pablo estaba preso por predicar el Evangelio y el carcelero tomó la mala decisión de suicidarse. Entonces, Pablo le dice: No te hagas daño, todos estamos aquí. Aquel hombre fue impactado por Pablo, quien le preguntó: ¿Qué debo hacer para ser salvo?' Pablo le dijo: Se salvó tú y será salva toda tu casa. Esto significa que él daría testimonio a su familia y, si creían, todos tendrían la promesa de ser salvos. Que seas predicador o pastor no hace salva a tu familia, a no ser que se conviertan al Señor. Es mi responsabilidad como padre de familia enseñar y guiar para que nuestros hijos tengan una experiencia personal con Jesús y sean salvos. Porque, como dijo Jesús, habrá conflicto en la familia. Leer Mateo 10:34-36. Bendiciones.`,
    author: "Raúl Enrique",
    date: "05/03/2024",
    id: 105,
  },
  {
    title: "La Importancia de Guardar el Corazón",
    text: `Dice Dios: Yo soy el que escudriña la mente y el corazón, porque el corazón del hombre es malo desde su juventud. Jesús dijo que del corazón salen los malos pensamientos, los adulterios, las fornicaciones, los hurtos, los falsos testimonios y las blasfemias. Dijo: Estas cosas son las que contaminan al hombre. Por eso, dice: Sobre toda cosa guardada, guarda tu corazón, porque de él mana la vida. El corazón es mucho más que una bomba biológica que emana sangre por venas y arterias. Necesitamos un corazón regenerado por Dios. El Señor dice: Quitaré el corazón de piedra y les daré un corazón de carne, sensible, con motivaciones puras. Porque el hombre bueno, del buen tesoro de su corazón, saca cosas buenas que le hacen bien al alma. Bendiciones.`,
    author: "Raúl Enrique",
    date: "04/03/2024",
    id: 104,
  },
  {
    title: "La Confianza Verdadera",
    text: `Dios dice: Maldito el hombre que confía en el hombre, y bendito es el hombre que confía en Dios, porque Dios no es hombre para que mienta ni se arrepienta. Solo Él es digno de confianza. Él es perfecto en lo que hace y dice, solo crea cosas perfectas. En cambio, el ser humano, aunque haya mejorado su estilo de vida, todavía lucha contra el mal por ser un ser con una naturaleza imperfecta y falible. Por eso, no podemos tener confianza plena en un hombre o mujer, por más carismáticos que sean. Debemos filtrar a los hombres a la luz de su palabra. Jesús dijo que por sus frutos se conoce el buen árbol, sino podríamos ser ciegos que siguen a otro ciego. Es verdad que hay gente con motivaciones puras, pero también hay muchos engañadores de la fe. Bendiciones.`,
    author: "Raúl Enrique",
    date: "03/03/2024",
    id: 103,
  },
  {
    title: "El Poder y la Justicia de Dios",
    text: `Si ves opresión de pobres y perversión de derechos y de justicia en la provincia, no te maravilles de ellos, porque sobre el alto vigila otro más alto. Eclesiastés 5:8. Para el Juez eterno, ninguna injusticia quedará impune, porque no tendrá por inocente al malvado. Su palabra dice que en Él no hay mudanza ni sombra de variación. Nadie escapa de la mirada de aquel que todo lo ve. Él no cambia en sus atributos. Él es Dios vivo, es espíritu, es tres veces Santo, es Juez Eterno, es Amor, es Soberano Rey y Señor. Es fuego consumidor, es omnipotente, omnipresente, omnisciente. Es Alto y Sublime, que habita la eternidad, y en la altura es tan poderoso que su espíritu habita en tu pequeño corazón, por la fe para darte vida. Isaías 57:15. Bendiciones.`,
    author: "Raúl Enrique",
    date: "01/03/2024",
    id: 102,
  },
  {
    title: "La Sabiduría Divina",
    text: `La Biblia dice que el mundo no conoció a Dios por la sabiduría. Agradó a Dios salvar a los creyentes por la locura de la predicación. Pero esta locura no es el ridículo que hacen falsos maestros. Esta locura deja de ser locura cuando el Espíritu Santo convence al pecador del pecado, de la justicia y del juicio. Entonces se abren los ojos del espíritu al arrepentirse de sus pecados. Ahí te das cuenta de que la predicación no es una locura. Por el contrario, es pura sabiduría de Dios que prende a los sabios en su propia astucia. Porque el hombre natural no percibe las cosas del espíritu. Pero ahora que el Espíritu Santo te abrió los ojos del entendimiento, puedes ver con claridad la infinita sabiduría de Dios para salvar.`,
    author: "Raúl Enrique",
    date: "29/02/2024",
    id: 101,
  },
  {
    title: "Jesús: El Pasaje a la Vida Eterna",
    text: `El Evangelio es el poder de Dios para salvar a todo aquel que cree. Es la buena noticia de la salvación. Sin Jesús, estaríamos en un estado de condena, porque está establecido que el hombre muera una vez y después el juicio. Porque la paga del pecado es muerte, pero el regalo de Dios es vida eterna. La salvación es un regalo de Dios que Jesús pagó con el precio de su sangre para librarte del juicio y la ira de Dios que vendrá. Los que aceptaron el sacrificio de Jesús como un regalo de salvación ya son libres, sin dudas, del juicio que vendrá. Por tu nombre, está escrito en el libro de la vida. Pero los que no estén inscritos sufrirán la condena. El Evangelio es el plan magistral de Dios para salvarte. Jesús es tu pasaje a la Vida.`,
    author: "Raúl Enrique",
    date: "28/02/2024",
    id: 100,
  },
  {
    title: "La Felicidad",
    text: `La Felicidad. La filosofía del mundo dice que es alcanzar el nivel más alto en su autorrealización. Pero que depende de cada persona y su comportamiento. Leí a hombres de la cultura y filosofía tratando de definir la felicidad, pero con definiciones diferentes: Platón, Sócrates, Freud. Pero la Biblia nos arroja luz en el tema, y no es fanatismo religioso, es coherencia. Para los cristianos, la felicidad es reconocer que somos criaturas que encontramos equilibrio en relación con Dios, con los demás y con nosotros mismos, es encontrar el camino haciendo alianza con el bien. Jesús dice: Felices los que oyen la palabra de Dios y la guardan. Esta felicidad no es una expresión de deseo, es una realidad presente y eterna. Bendiciones.`,
    author: "Raúl Enrique",
    date: "27/02/2024",
    id: 99,
  },
  {
    title: "Todo lo Puedo en Cristo: Fortaleza y Paciencia",
    text: `Dice Pablo: Todo lo puedo en Cristo que me fortalece. Eso significa que aún lo que me cuesta lograr o se demora, puedo soportarlo y tener paciencia, que es el arte de saber esperar aquello que se demora o me parece que se demora, porque todo tiene su tiempo, dijo el predicador. Porque con Cristo somos más que vencedores, porque los que aman a Dios, todas las cosas les ayudan a bien, porque las batallas de la vida nunca acaban y nunca las gana el más fuerte, sino el que en ningún momento duda de que es Dios quien da la victoria. Así que no te rindas por las situaciones difíciles. El Señor nos anima y nos dice: "Mira que te mando que te esfuerces y seas valiente. No temas porque yo soy Jehová, tu Dios, que voy contigo a dondequiera que vayas". Bendiciones.`,
    author: "Raúl Enrique",
    date: "26/02/2024",
    id: 98,
  },
  {
    title: "La Belleza del Amor de Dios",
    text: `La belleza del amor de Dios es que puede conducirnos más allá de esta vida, más allá de nuestra capacidad de imaginación, porque además esta clase de amor de Dios tiene el poder de lograr la felicidad eterna. Por eso, Pablo dice con total certeza que cosas que ojo no vio, ni oído oyó, ni han subido al corazón del hombre, son las que Dios ha preparado para los que lo aman. Por eso mismo, Jesús reitera una y otra vez lo que significa ser un cristiano bienaventurado. Por eso, el Señor dice: Felices los pobres de espíritu, porque de ellos es el Reino de los Cielos; Felices los puros de corazón, porque ellos verán a Dios; Bienaventurados los misericordiosos, porque ellos alcanzarán misericordia. Él es tu esperanza viva. Bendiciones.`,
    author: "Raúl Enrique",
    date: "25/02/2024",
    id: 97,
  },
  {
    title: "El Poder Transformador de la Palabra",
    text: `Dice Dios: He aquí, yo envío mi palabra y no volverá a mí vacía y sin resultados, porque así como cae la lluvia en la tierra y hace germinar la semilla y da pan al que come, así será mi palabra que sale de mi boca. Hará lo que yo quiero, dice el Señor. Fíjate que toda palabra enviada por hombres con autoridad tiene algún grado de poder, porque las palabras, decían los judíos, son como una unidad de energía cargada de poder, es decir, es como una bala que sale hacia su destino. Imagínate el poder de la palabra de Dios que creó y ordenó el universo. ¿Qué quiere decir "uni"? Significa "de única" y "verso" significa "verbo", o sea, palabra. Universo igual a Única Palabra. Como cristianos, tenemos como única arma ofensiva la espada del Espíritu. Bendiciones.`,
    author: "Raúl Enrique",
    date: "23/02/2024",
    id: 96,
  },
  {
    title: "La Palabra de Dios como Medicina",
    text: `La medicina moderna descubrió hace poco tiempo que los problemas emocionales y mentales inciden en enfermar el cuerpo, debido a que bajan las defensas biológicas. Pero hace 2.600 años, el rey Salomón escribió que el corazón alegre constituye un buen remedio, más el espíritu triste seca los huesos. También dijo que la palabra de Dios es medicina para nuestro cuerpo y refrigerio para nuestros huesos. Por supuesto, no quiere decir que no vamos a enfermar, pero sí podemos evitar enfermedades de carácter emocional que son muchas. Solemos decir: "No te hagas mala sangre por la diabetes", o "no te enojes que te sube la presión arterial" o "te hace mal al corazón", etc. El Señor nos enseña a no vivir enojados y con tristeza. Bendiciones.`,
    author: "Raúl Enrique",
    date: "22/02/2024",
    id: 95,
  },
  {
    title: "Fortaleza en la Fe: No Temas, Dios Está Contigo",
    text: "Dice Dios: No temas, no desmayes porque yo estoy contigo. Te fortaleceré, porque yo te ayudo con el poder de mi diestra. Isaías 41:10. Hermanos, el temor es una reacción de la emoción, además, es universal en parte. Es favorable porque de manera instintiva nos alerta de algún peligro. Pero el Señor sabe, por su sabiduría infinita, que en medio de luchas sentimos temor hasta por miedos imaginarios que quizás nunca ocurran. Esa clase de temor nos paraliza, nos desenfoca y no nos deja avanzar en paz, debilitando nuestro espíritu. Por eso, Él nos llama a confiar, a no temer, porque Él nos dará descanso. Él es el poder que nos levanta y nos da seguridad, tanto que afirma nuestros pies. Porque no se dormirá el que guarda tu alma. Bendiciones.",
    author: "Raúl Enrique",
    date: "21/02/2024",
    id: 94,
  },
  {
    title: "Guiados por la Voz del Buen Pastor",
    text: "Dice Jesús: Mis ovejas oyen mi voz y me siguen. Hermanos, ¡Qué hermoso cuadro es la imagen del buen pastor y las ovejas que lo siguen! Las ovejas del Señor comen pastos frescos y verdes, beben agua limpia porque el pastor las guía a fuentes de agua y al alimento verdadero. Él dice: Entrarán, saldrán y hallarán pastos, habla de su rebaño. Él, como buen pastor, nos guía al alimento verdadero, al pan del cielo y a beber del agua viva que es la palabra de Dios, limpia, pura y fresca, que corre y fluye sin contaminación alguna. Por eso dice David: 'Jehová es mi pastor, nada me faltará; en lugares de delicados pastos me hará descansar y junto a aguas de reposo me pastoreará. Él es mi presente, mi posesión y mi protector. Bendíciones.",
    author: "Raúl Enrique",
    date: "20/02/2024",
    id: 93,
  },
  {
    title: "Jesús y su Reino: La perla más Preciosa",
    text: "Dijo Jesús: El Reino de los Cielos es semejante a un mercader que busca buenas perlas. Habiendo hallado una perla preciosa, fue y vendió todas las que tenía y la compró. Una parábola es un relato terrenal con un significado espiritual. Esto nos habla del inmenso valor que tiene para nosotros Jesús y su reino, que está por encima de todos aquellos valores que creíamos que eran de valor para nosotros. Pero estábamos en la búsqueda de algo precioso que me hiciera feliz al saber que soy parte de la perla más preciosa del universo, la cual es Jesús y su reino, que nos habla de su pureza, brillo y consistencia, al grado de sumarnos con los valores familiares que él mismo nos ha dado. Gracias a Dios por su don inefable. Bendiciones.",
    author: "Raúl Enrique",
    date: "19/02/2024",
    id: 92,
  },
  {
    title: "La Esperanza Bendita",
    text: "Generación va y generación viene, pero la tierra permanece para siempre. Unos nacen y otros mueren. Vanidad de vanidades, todo es vanidad, dijo el predicador. Lo dice desde el punto de vista observador humano, pero qué triste sería que después de ser bendecidos con la existencia de la familia a quien amamos tanto, terminara todo en vanidad y sin esperanzas de vida. Pero esto es la antesala de la entrada al Reino de los cielos, porque tenemos una esperanza bendita, la cual el Señor mismo con voz de mando, con voz de arcángel y trompeta de Dios, descenderá del cielo y los muertos en Cristo resucitarán primero, y luego nosotros, los que quedamos, seremos arrebatados juntamente con ellos en el aire. Fiel es Él.",
    author: "Raúl Enrique",
    date: "18/02/2024",
    id: 91,
  },
  {
    title: "La Promesa de Luz en los Días Malos",
    text: "En el día del bien, goza del bien; en el día malo, considéralo. ¿Por qué tengo que considerar el día malo? Porque vivimos en un mundo de espacio físico. Hasta la venida de Jesús, el mundo estaba en oscuridad espiritual total, pero el pueblo de Israel, que estaba en tinieblas, vio gran luz. Jesús iluminó este mundo de oscuridad. Él dijo: Yo soy la luz del mundo; el que a mí viene no andará en tinieblas, porque tendrá la luz de la vida. Por eso existe un contraste entre la luz de Jesús y las tinieblas, pero las tinieblas nunca podrán vencer a la luz que nos muestra que las tinieblas son pasajeras. Así que disfrutemos la vida teniendo como centro la luz de Jesús, que nos alumbra y nos alumbrará en el cielo, porque ahí todo es luz. Bendiciones.",
    author: "Raúl Enrique",
    date: "17/02/2024",
    id: 90,
  },
  {
    title: "El Camino del Justo",
    text: "Deleitate en Jehová y él te concederá las peticiones de tu corazón. O sea, disfruta, regocíjate en Él y te oirá, porque muchas son las aflicciones del justo, pero de todas ellas te librará el Señor. Ser justo no es ser perfecto ni sin pecados, más bien es ser justificado por la justicia de Cristo. Su bondad nos libró de condena y ahora no hacemos del pecado una práctica; por el contrario, vivimos un estilo de vida cristiano en armonía con su voluntad. El Señor, como un buen padre, nos invita y nos dice: Pedid y se os dará, buscad y hallaréis, llamad y se os abrirá, porque todo aquel que pide recibe, el que busca halla y al que llama se le abre. Porque mis pensamientos hacia ustedes son de bien y no de mal. Bendiciones.",
    author: "Raúl Enrique",
    date: "16/02/2024",
    id: 89,
  },
  {
    title: "Fortaleza en Dios",
    text: "El que habita al abrigo del Altísimo morará bajo la sombra del Omnipotente. Esto significa que al intimar con Él, vivir con Él, es estar amparado y protegido bajo la protección de Dios y las fuerzas del cielo. Es estar bajo la cobertura, el poder y la guía del Espíritu Santo aquí en la tierra. Esta protección de Dios se extiende más allá de lo que nos suceda en este mundo físico, porque los cristianos no estamos exentos de situaciones o circunstancias adversas. Por eso, Pablo nos dice: Fortaleceos en el Señor y en el poder de su fuerza, y vestíos de toda la armadura de Dios, porque las armas de nuestra milicia no son carnales, sino poderosas en Dios para la destrucción de fortalezas. Su palabra es viva y eficaz. ¡Bendiciones!",
    author: "Raúl Enrique",
    date: "15/02/2024",
    id: 88,
  },
  {
    title: "Evitando el engaño de los falsos milagros",
    text: "Hermanos, en este tiempo difícil que nos toca vivir, en medio de una sociedad que ha trastocado los valores fundamentales de la familia y de la sociedad en general, debemos orar por todos los hombres y mujeres para que haya arrepentimiento ante Dios en las familias, en la ciudad y en la nación. Para que haya conversión, nuevo nacimiento. Sino, de nada va a servir que solo pidas por milagros o por lo material, o por cosas que son pasajeras y que ni siquiera, en muchos casos, son objetos de agradecimiento a Dios en la Iglesia. Ni siquiera asisten a las reuniones. Por eso hay mucha gente que es engañada con los falsos milagros. Jesús dijo que busquemos primeramente el reino de Dios y su justicia, y lo demás será añadido. Bendiciones.",
    author: "Raúl Enrique",
    date: "14/02/2024",
    id: 87,
  },
  {
    title: "Jesús: La Fuente de Vida y Sabiduría",
    text: "Dice la Biblia: El que quiera ser sabio, hágase ignorante primero, porque el principio de la sabiduría es el temor a Dios. Júntate con sabios y serás sabio, pero júntate con necios y serás necio, porque en la multitud de consejeros está la sabiduría que sobrepasa a la necedad, como la luz a las tinieblas. Corrige al sabio y te amará, pero corrige al necio y te odiará. En cuanto a mí, que me corrija el sabio será un excelente bálsamo, porque el avisado ve el mal y se aparta, más el simple o necio cae en la trampa del maligno. Jesús dijo que el diablo vino para matar, hurtar y destruir a la humanidad, es lo que vemos todos los días, pero Jesús dice: Yo he venido para que tengan vida, y vida en abundancia. ¡JESÚS ES VIDA!",
    author: "Raúl Enrique",
    date: "13/02/2024",
    id: 86,
  },
  {
    title: "El Reconocimiento de la Bancarrota Espiritual",
    text: "Dice Jesús: Felices los pobres de espíritu, porque de ellos es el Reino de los cielos. Hermanos, feliz es el hombre que se dio cuenta de su total destitución, que está en bancarrota espiritual, que ya no puede bajar más pero se reconoce como tal, que ha elevado su mirada al único Dios que lo podía levantar y hacer revivir su espíritu. Pudo dejar de lado su orgullo, altivez y soberbia, que reconoce en Dios que Él mira de lejos a los soberbios pero atiende a los humildes de corazón. En Isaías 57:15 dice el Señor: Porque así dijo el Alto y Sublime, el que habita la eternidad, cuyo nombre es Santo: Yo habito en la altura y la santidad, y con los quebrantados y humildes de espíritu, para hacer vivir el espíritu de los humildes. Bendiciones.",
    author: "Raúl Enrique",
    date: "12/02/2024",
    id: 85,
  },
  {
    title: "Vivir y Morir para el Señor",
    text: "Dice la Biblia: Porque si vivimos, para el Señor vivimos; y si morimos, para el Señor morimos. Así que, sea que vivamos, o que muramos, del Señor somos (Romanos 14:8). Esto significa que ni en vida ni en la muerte nada nos podrá separar de ese vínculo de amor eterno en Él. Esa es la paz que sobrepasa todo entendimiento. Si nos vamos de este mundo, estamos en amistad con Él, ya no somos enemigos; por el contrario, ahora somos hijos. Por esto dijo Pablo: Justificados, pues, por la fe, tenemos paz para con Dios por medio de nuestro Señor Jesucristo (Romanos 5:1), y nada nos podrá separar de su amor. Él nos invita y nos anima a vivir y morir en Él, porque Él es el camino a la vida eterna. Él te dice: El que guarda mi palabra, nunca verá muerte; porque ha pasado de muerte a vida (Juan 8:51).",
    author: "Raúl Enrique",
    date: "11/02/2024",
    id: 84,
  },
  {
    title: "Esperanza en tiempos de decadencia",
    text: 'En medio de un mundo caótico en el que nos toca vivir, demostrado no solo por dichos sino que lo podemos ver en la realidad donde la decadencia moral, social, política, cultural, religiosa y económica de este sistema en el que de antemano estamos avisados por Jesús que esto pasaría, donde el amor de muchos se enfriará a causa de la maldad en todo el mundo. El Señor nos llama a redoblar los esfuerzos para mantener viva la profesión de nuestra esperanza en Él. Su palabra nos dice: "No pierdas vuestra confianza, que tiene grande galardón, porque aún un poquito y el que ha de venir vendrá". Hermanos, sin duda alguna, el Señor volverá por su iglesia. Vivamos sin temor, pero expectantes ante la proximidad de su venida. Bendiciones.',
    author: "Raúl Enrique",
    date: "10/02/2024",
    id: 83,
  },
  {
    title: "El Poder de Ver con los Ojos del Espíritu",
    text: "Pablo oraba para que sean abiertos los ojos de nuestro entendimiento y dijo: No andamos por lo que vemos, sino por lo que no vemos, lo que significa captar la mayor cantidad de luz hasta penetrar en los misterios inagotables de Cristo, que nos revela su palabra e ilumina nuestro camino. Eliseo, profeta de Dios, era perseguido por un gran ejército que quería matarlo. Su siervo se asustó y Eliseo le dijo: No temas, porque más son los que están por nosotros que por ellos. Eliseo le pidió a Dios que le abriera los ojos del espíritu a su siervo. Dios le abrió los ojos y vio ejércitos celestiales que rodeaban al enemigo. El siervo solo podía ver al enemigo, pero Eliseo podía ver con los ojos del espíritu ejércitos de ángeles de su lado.",
    author: "Raúl Enrique",
    date: "03/02/2024",
    id: 82,
  },
  {
    title: "La Hermosa Esperanza que Nos Da el Señor",
    text: "Dice Jesús: Yo soy el pan de vida. El que viene a mí nunca tendrá hambre, y el que en mí cree no tendrá sed jamás, porque yo soy el pan vivo que descendió del cielo. Y todo aquel que ve al Hijo y cree en Él tendrá vida eterna, y yo lo resucitaré en el día postrero. ¡Qué hermosa esperanza nos da el Señor! Porque siendo Dios, se manifestó en carne. Por eso, es el pan de vida que también es agua viva. Te das cuenta de que el pan, al igual que el agua, es esencial para la vida física. Es por esto que Él es esencial como el pan físico para tener vida y vida eterna. Necesitamos a Jesús para ser libres de una condenación segura a causa del pecado. Abrázate a Jesús por la fe en Él, porque no se trata de religión, se trata de una persona: Jesús.",
    author: "Raúl Enrique",
    date: "02/02/2024",
    id: 81,
  },
  {
    title: "En Cristo Estamos Completos",
    text: "El Señor Jesús predicaba y hacía milagros, y la gente le seguía. Pero cuando empezó a hablar de la necesidad de cambios, algunos dejaron de seguirlo. Se quedó con Pedro y le dice: ¿Tú también te quieres ir, Pedro? Pedro le responde: ¿A quién iremos, Señor, si solo tú tienes palabras de vida eterna?. ¡Qué gran verdad dijo Pedro, porque en ningún otro hay salvación! Él es nuestro único Salvador, porque en Él está puesta nuestra esperanza de vida eterna. Para vos y tu familia, que amas tanto como yo a la mía, porque en Él estamos completos. Con Él lo tenemos todo, sin Él nada. Quieras o no, nos vamos de este mundo con la esperanza bendita de ver a Jesús y a nuestros seres queridos. No te rindas, porque con Cristo todo es posible.",
    author: "Raúl Enrique",
    date: "01/02/2024",
    id: 80,
  },
  {
    title: "Dos Caminos, Dos Destinos",
    text: "Dice la Biblia: Habiendo Dios hablado en otros tiempos por los profetas, hoy nos habla por medio de su Hijo. Pero, ¿qué dice Jesús con respecto al destino de la humanidad después de la muerte física? Él dice: Entrad por la puerta estrecha, porque ancho y espacioso es el camino que lleva a la perdición, y muchos son los que van por él. Pero angosto es el camino que lleva a la vida, y pocos son los que van por él. Fíjate que no hay camino intermedio ni purgatorio. Estás en el camino a la vida y el paraíso si estás en Cristo, o bien estás en el camino ancho que te lleva a la perdición y condenación. Jesús no habla con rodeos. O sea, hay dos puertas, dos caminos, dos grupos de personas y dos destinos. Si estás en Cristo, estás en camino a la vida. Bendiciones.",
    author: "Raúl Enrique",
    date: "31/01/2024",
    id: 79,
  },
  {
    title: "La Travesía de recordar a Dios en medio del olvido diario",
    text: "Hoy pensaba que a veces nos sorprendemos por una persona famosa o popular que puede tener una o dos habilidades llamativas. ¿Qué será tener enfrente al que creó todo y puede hacer lo que quiera cuando quiera, si quiere, no? Nuestra carne tiende a buscar hacernos olvidar a Dios cada día, pero cuando decidimos que Él es nuestro Dios, algo adentro nos lleva a sentir que algo falta cada día. Recordémoslo de cualquier manera justa, pero hagámoslo. Muchas veces no sentimos si no vemos, y una de nuestras luchas debe ser, ser conscientes de que hay alguien tan poderoso que genera una atracción que ni nuestra mente puede imaginarlo. Si decidimos seguirlo, algún día lo veremos. Dios te bendiga.",
    author: "Leandro Emanuel",
    date: "30/01/2024",
    id: 78,
  },
  {
    title: "Restauración en la Adversidad",
    text: "Dice Dios: He aquí mi siervo. No quebrará la caña cascada, ni apagará el pabilo que humea. Sabemos que la vida es linda y más si Jesús es el centro de nuestra vida, pero la vida nos plantea un desafío con situaciones y circunstancias en el contexto de un mundo cada vez más difícil que debemos enfrentar con valor y amortiguar los golpes de la vida con la fortaleza de Dios. Quizás estuvimos como una caña hueca a punto de quebrarnos del todo, pero Cristo no permitió que tu vida se pierda; por el contrario, reparó y sanó tus heridas. O cuando el pabilo o la vela que humea y está a punto de apagarse, el Señor a tiempo avivó esa llama de vida que estaba a punto de apagarse. Te devolvió la llama de una esperanza viva.",
    author: "Raúl Enrique",
    date: "29/01/2024",
    id: 77,
  },
  {
    title: "Nuevo comienzo para el Alma con Jesús",
    text: "Dice la Biblia: Jehová te pastoreará siempre, y en las sequías saciará tu alma; Él dará vigor a tus huesos, y serás como huerto de riego y manantial de vida. ¿Estás seco en tu alma, triste, desanimado y sin fuerzas? El Señor promete darle vida a tu alma cargada de cosas en medio de una sociedad moderna marcada por el estrés social. El Señor te invita a beber de su agua limpia, pura y fresca, sin bacterias, virus ni parásitos, que fluye como un río limpio. Jesús mismo te invita diciendo: 'El que tenga sed, venga a mí y beba, y de su interior correrán ríos de agua vida que potencian tu estado espiritual y avivan el fuego de tu alma, elevando tus defensas, porque un corazón alegre constituye un buen remedio. Bendiciones.",
    author: "Raúl Enrique",
    date: "28/01/2024",
    id: 76,
  },
  {
    title: "De la Fe ciega a la Fe verdadera",
    text: "Dice la Biblia que la fe es la convicción de lo que no se ve y la certeza de lo que se espera. La fe ciega es creer sin sentido, es no saber por qué crees lo que crees. Es como tirarse al vacío sin considerar la ley de la gravedad, que puede matarte. Pero la fe verdadera y bíblica está basada en razones y promesas del Dios vivo, único y verdadero. Un ejemplo: Dios dice: Clama a mí y yo te responderé. Esto es una promesa que será cumplida, y de hecho, el Señor responde al clamor de sus hijos. Por esto, se dice que la fe viene por el oír y el oír la palabra de Dios. ¿Por qué tenemos fe en Dios? Porque su palabra nos habla de promesas que son fieles y verdaderas. Jesús dijo: Para el que cree, todo es posible. Bendiciones.",
    author: "Raúl Enrique",
    date: "27/01/2024",
    id: 75,
  },
  {
    title: "El Poder Transformador de la Palabra",
    text: "Dice Jesús: Felices los que no hallen tropiezo en mí, el Señor predicaba y la gente oía sus palabras, que son espíritu y vida, cargadas de verdad, sabiduría y el poder del cielo. Porque su palabra es limpia, pura y dinámica. Es como un martillo que quebranta piedras. ¿Está tu corazón tan duro como una piedra? El martillo de su palabra te dará un corazón sensible a la voz de su Espíritu Santo, tanto que llorarás de amor, porque te dará un nuevo corazón. También, su palabra es como fuego que purifica tu alma, quitando toda impureza, como el metal que el fuego refina. Es como un clavo hincado en el corazón cuando tu alma percibe que Dios habla a tu corazón. También es viva y eficaz, tocando lo profundo de tu alma y tu espíritu.",
    author: "Raúl Enrique",
    date: "26/01/2024",
    id: 74,
  },
  {
    title: "Bajo Su Mirada",
    text: "Dice la Biblia: En Él vivimos, nos movemos y existimos. Vivimos rodeados por Dios como los peces en el mar. Su palabra pregunta: ¿Adónde escaparemos de su presencia, de su mirada? Él todo lo ve y dice: Aunque te remontares a las estrellas, de allí te bajaré; y si fueres a lo profundo del mar, allí estoy yo. Es imposible que el Señor no nos vea, así que debemos vivir en el temor reverencial a aquel que todo lo ve. También dice que en Él nos movemos. Todo en la vida es movimiento: el universo se mueve, las células se mueven, los átomos se mueven. Las aves en el cielo se mueven. Fuimos creados para el movimiento. Como cristianos, debemos movernos en la voluntad de Dios y saber que en Él tenemos vida porque Él vive.",
    author: "Raúl Enrique",
    date: "25/01/2024",
    id: 73,
  },
  {
    title: "Responsabilidad y Discernimiento",
    text: "Porque la ira de Dios se revela desde el cielo contra toda impiedad e injusticia de los hombres que detienen con injusticia la verdad. Pero grande es la verdad que se abre paso en medio de la mentira y cosas irreales, es como la luz que sobrepasa a las tinieblas. La verdad divina se presentó en Jesús a nuestras vidas como el camino, la verdad y la vida. Ya no buscamos otra verdad porque no la hay. Solo tenemos que interpretar todos los aspectos o facetas de la verdad única en Jesús para no caer en mentiras que se parecen mucho a la verdad, como un billete falso que se parece a lo verdadero. Pero es nuestra responsabilidad capacitarnos en Dios para discernir entre lo falso y lo verdadero, haciéndole honor a la Verdad.",
    author: "Raúl Enrique",
    date: "23/01/2024",
    id: 72,
  },
  {
    title: "La Grandeza de Dios Revelada",
    text: 'La verdad es que me regocijo en mi corazón al leer en su palabra que Dios nos invita a intimar con Él, siendo Él tan grande que no tenemos capacidad de imaginación de lo increíblemente poderoso que es Él. Con solo mirar el sol, la luna y las estrellas nos damos cuenta de su increíble grandeza. Su palabra, que es limpia, pura y fresca, dice que su eterno poder y divinidad se hacen claramente visibles siendo entendidos por medio de las cosas hechas, es decir, por todo lo creado que podemos observar. El mismo Dios dice: "Mis ojos contemplan toda la tierra para salir en favor del humilde de corazón y hacer revivir su espíritu". Jesús nos dice: "Vengan a mí, que soy manso y humilde de corazón, y hallarán descanso para sus almas". Bendiciones.',
    author: "Raúl Enrique",
    date: "22/01/2024",
    id: 71,
  },
  {
    title: "El Libro de la Vida: Obtén tu Pasaje a la Verdadera Vida con Jesús",
    text: "Dice Jesús: Por cuanto yo he venido, no tienen excusas antes de la creación del mundo. Dios creó ángeles, arcángeles, querubines y serafines, todos con diferencias de jerarquías. Uno de ellos se convirtió en diablo, es decir, calumniador; era el querubín, pero se reveló contra Dios. ¿Alguien podría decir cómo pecó si era perfecto? Lo mismo que Adán. Ejemplo: el ángel y el hombre eran perfectos pero no infalibles debido al libre albedrío que Dios, en su amor, les dio. Pero solo Dios es perfecto e infalible. Debido al pecado del ángel y el hombre, Dios los sentenció a muerte física y espiritual. Además, preparó una cárcel eterna para el diablo y el pecador no arrepentido que se va de este mundo sin Cristo. Solo Cristo te puede librar de la cárcel eterna. Para eso, tu nombre tiene que estar en el libro de la vida. Esto se logra cuando reconoces a Jesús en tu corazón, en arrepentimiento y fe. Solo en Él tendrás tu pasaje a la verdadera vida. Bendiciones.",
    author: "Raúl Enrique",
    date: "20/01/2024",
    id: 70,
  },
  {
    title: "Milagros con Propósito: La historia de Pedro, Juan y el mendigo",
    text: 'Pedro y Juan fueron a orar al templo, y un mendigo que era cojo les pidió limosna. Pedro le dijo: "Míranos", y le dijo: "No tengo plata ni oro. Lo que tengo, te doy en el nombre de Jesús. Levántate y anda". El hombre se levantó, enderezó sus pasos y corrió al templo glorificando a Dios. Ese sí que era un milagro con propósito. Así es como Dios nos levanta espiritualmente y endereza nuestros pasos para caminar en la verdad de su palabra, que es lámpara para nuestros pies y lumbrera para nuestro camino. ¿Qué tenían Pedro y Juan? Lo mismo que tenía Pablo cuando dijo: "Porque tenemos un tesoro en vasos de barro, para que la excelencia del poder sea de Dios". Somos frágiles, pero con el poder de Cristo en nuestro corazón, que nos fortalece.',
    author: "Raúl Enrique",
    date: "19/01/2024",
    id: 69,
  },
  {
    title:
      "La Adoración Genuina: Un Flujo Natural de Alegría en el Corazón Cristiano",
    text: "La alegría de adorar a Dios en un cristiano debería ser un fluir natural, no una imposición externa. De manera similar a cómo la alegría por ganar un premio surge espontáneamente, la adoración genuina nace del corazón y se manifiesta de forma auténtica. Intentar forzar este sentimiento podría distorsionar la esencia de la adoración, que debería ser una respuesta libre y agradecida a la relación con Dios. Así como la felicidad por un logro genuino es intrínseca, la adoración auténtica brota naturalmente del reconocimiento y amor hacia Dios. Si no sucede esto, pidamos ayuda a Dios para comprender la alegría de ser sus hijos.",
    author: "Leandro Emanuel",
    date: "19/01/2024",
    id: 68,
  },
  {
    title: "Decadencia y Renovación en la Sociedad Actual",
    text: "Dice Dios: Vendrán días en que enviaré a la tierra no hambre de pan ni sed de agua, sino de oír mi palabra. Cuando más nos acercamos al retorno de Jesús y los juicios de Dios a la tierra, así como en los días de Noé, Sodoma y Gomorra, la decadencia moral, social, política, religiosa y cultural de una sociedad marcada por el egoísmo y la violencia, donde a lo bueno lo llamaban malo y a lo malo bueno. Jesús dijo que por causa de la maldad, el amor de muchos se enfriará. Hoy podemos ver la falta de empatía y el poco valor a la vida. Millones de personas por el mundo con tristeza que no le encuentran el significado a la vida, almas sedientas, vacías y sin esperanza. La respuesta es la buena noticia de la palabra de Dios. Bendiciones.",
    author: "Raúl Enrique",
    date: "17/01/2024",
    id: 67,
  },
  {
    title: "La Resurrección: Un Milagro de Jesús, el Autor de la Vida",
    text: 'Una pobre mujer era viuda y tenía un hijo único. Pero tristemente, su hijo también murió. Vecinos y familiares salieron para consolarla, porque era grande su angustia mientras salían con el cuerpo del joven. Jesús venía con sus discípulos y mucha gente que lo seguía. Se encontró con esta mujer llorando y tuvo compasión de ella. Les dijo que paren, se acercó, tocó el féretro y le dijo a la mujer: "No llores". Luego, habló al joven diciendo: "Levántate", y fue resucitado. Imaginen ese cuadro, de la madre y su familia, de la tristeza a la alegría. Quedaron estupefactos; por un lado, la muerte, y por otro lado, venía el autor de la vida, que nos dice: "Yo soy la resurrección y la vida. El que cree en mí, aunque esté muerto, vivirá". Bendiciones.',
    author: "Raúl Enrique",
    date: "16/01/2024",
    id: 66,
  },
  {
    title: "Fuente de Agua Eterna",
    text: 'Jesús se sentó al lado de un pozo de agua y una mujer fue al mediodía a buscar agua cuando no había nadie. Tenía vergüenza de sus vecinos porque decían que era una mujer de mala vida. Estaba angustiada, pero se encontró sin saberlo con Jesús, que conocía todo de ella. Él le dice: "Mujer, dame de beber". Ella le dice: "¿Cómo me pides tú de beber? Soy samaritana". Pero Jesús no solo era maestro, sino el Salvador. Él le dice: "Si supieras quién es el que te dice "dame de beber", tú le pedirías a Él y te daría agua de vida". Ella le dice: "Señor, dame de esa agua". Él le dice: "Cualquiera que tome de esa agua volverá a tener sed, pero el que tome del agua que yo le daré será en él una fuente de agua que salte para vida eterna". Él es agua viva.',
    author: "Raúl Enrique",
    date: "15/01/2024",
    id: 65,
  },
  {
    title: "Fe Transformadora en Cristo",
    text: 'Ser un cristiano transformado por Cristo es una bendición. El mismo Señor Jesús los llama bienaventurados, doblemente feliz el cristiano que, más allá de las circunstancias o problemas de la vida que nos toca enfrentar, nos mantenemos firmes en la fe. Porque una fe fuerte ve lo invisible, cree lo increíble y recibe lo imposible. Por eso dice que Moisés se sostuvo frente al mar como viendo al invisible. La fe en Jesús es la fe que vence al mundo. Jesús dijo: "Si tuvieras fe como un grano de mostaza y dices a ese monte, muévete, y se moverá." No dijo que tengas una gran fe como una sandía, sino como una pequeña semilla. Porque el valor de la fe no está en lo que crees, sino en quien crees. Él moverá tus montañas de problemas.',
    author: "Raúl Enrique",
    date: "13/01/2024",
    id: 64,
  },
  {
    title: "De paso por este Mundo: Preparándonos para lo Eterno",
    text: "A todo hombre y mujer les es dado a vivir en dos mundos, uno es el mundo del tiempo y el espacio y el otro es el mundo del espíritu y las cosas eternas. Como individuos somos responsables de manera personal del estilo de vida que llevamos delante del creador. Quieras o no, estamos de paso por este mundo, o sea, de este planeta hermoso que es la antesala o la preparación para entrar a la verdadera vida de un paraíso eterno preparado desde antes de la fundación del mundo para aquellos que se arrepienten de sus pecados y por la fe en Jesús reciben de su gracia divina la salvación eterna. Él puede darle sentido, significado y contenido a tu vida, y a la mía. Él es nuestra Esperanza de vida. Bendiciones.",
    author: "Raúl Enrique",
    date: "11/01/2024",
    id: 63,
  },
  {
    title: "La Biblia: Palabra Inspirada por Dios",
    text: 'La Biblia es la palabra de Dios y la misma dice que toda la escritura es inspirada por Dios, útil para enseñar, porque contiene leyes, profecías, historia, ciencia, poemas, amor, misericordia, juicio, condenación, salvación y vida eterna a todo aquel que cree. Habla de un solo Dios y un solo mediador entre Dios y los hombres: Jesucristo, hombre. Es la número uno en ventas en todas las generaciones pasadas y la presente. Es un bestseller. Hubo muchos intentos por prohibirla, pero fue y será imposible que lo logren, Jesús dijo: "Cielo y tierra pasarán, pero mis palabras jamás pasarán". Es verdad que la escribieron unos 40 hombres, pero eran santos de Dios a los que el Espíritu Santo les puso sus palabras en sus mentes. Eran de diferentes edades, tiempos y culturas, y ni siquiera muchos de ellos se conocían entre sí. Pero, como es de procedencia divina, cuyo autor es el Espíritu Santo, no existe contradicción alguna. Solo hay que obedecer sin temor ni dudas. Bendiciones.',
    author: "Raúl Enrique",
    date: "11/01/2024",
    id: 62,
  },
  {
    title: "La Lógica del Diseño",
    text: "Dice la biblia: Toda casa es hecha por alguien, pero el que hizo todas las cosas es Dios(Hebreos 3:4). Te das cuenta esto es lógica pura, para que haya diseño tiene que haber un diseñador. Porque donde hay orden tiene que haber una mente ordenadora. Si se construye una casa en una zona desértica con todas las condiciones de vida y alguien pregunta. ¿Quién hizo esa casa? Y alguien responde, nadie, se hizo sola con la lluvia, el viento con polvo o el Big bang, eso sí que sería imposible e ilógico. Sería un disparate para el que usa el don de la razón. Una simple casa. Imagínate el planeta, el universo con todas las condiciones de vida. Es verdad que no estamos solos, Dios es nuestro creador y tiene propósito con tu vida y la mía. Bendiciones.",
    author: "Raúl Enrique",
    date: "09/01/2024",
    id: 61,
  },
  {
    title: "La Luz que Transforma: La Historia de Pablo",
    text: "Pablo era un líder judío con autoridad religiosa, un hombre formado y académico. Era enemigo de la cruz y el Evangelio de Cristo. Sin embargo, Jesús salió a su encuentro cuando perseguía a los cristianos para llevarlos presos. La luz de Cristo lo rodeo y fue transformado por el poder de Dios. Al grado que llegó a regocijarse. Cuando dijo no me avergüenzo del Evangelio porque es poder de Dios para salvar a todo aquel que en él cree, porque el reino de Dios no consiste en palabras, sino en poder. Que significa dinamita del cielo que cambia las vidas que estaban rotas sin esperanzas. Ahí se cumple la palabra que dice: Si alguno está en Cristo, nueva criatura es, las cosas viejas pasaron y en El son todas hechas nuevas. Bendiciones.",
    author: "Raúl Enrique",
    date: "08/01/2024",
    id: 60,
  },
  {
    title: "Preparados para la Salvación: El Regreso Triunfal",
    text: "Los cristianos renacidos tenemos lo que el apóstol Pablo Ilama una esperanza bendita. ¿Cuál es? La venida del Señor Jesús por su iglesia. Dice Pablo, he aquí os digo un misterio, no todos dormiremos, pero todos seremos transformados en un abrir y cerrar de ojos. O sea que seremos arrebatados en el aire en un pestañeo. Será para el mundo algo imperceptible. Solo se darán cuenta cuando ya no estemos en esta tierra, antes de que caiga la ira venidera por los juicios de Dios sobre la tierra. Además, dice que nosotros no fuimos puestos para ira, sino para salvación. El Señor mismo descenderá del cielo y los muertos en Cristo resucitarán primero y luego nosotros los que quedamos seremos transformados y arrebatados en el aire.",
    author: "Raúl Enrique",
    date: "07/01/2024",
    id: 59,
  },
  {
    title: "Salvación y Plenitud en Jesucristo",
    text: "Dios nos creó con la capacidad de amar, con la necesidad de ser amados, con el deseo de ser felices, con la expectativa de vida eterna. Eclesiastés 3:1 dice que Dios planto la eternidad en el corazón del hombre. Esos deseos se hacen realidad solo en Jesús. Lo dice el Eterno, porque en ningún otro hay salvación. Pedro dijo a quien iremos si solo tú tienes palabras de vida Eterna. Solo Él vivifica tu alma, tu espíritu, potenciando tu fe, dando a tu vida dirección, sentido y contenido. Sino presta atención a lo que él dice a los cristianos convertidos: Felices los pobres de espíritu, porque de ellos es el reino de los cielos, felices los misericordiosos, porque ellos alcanzarán misericordia. En Él hay plenitud de gozo y felicidad.",
    author: "Raúl Enrique",
    date: "06/01/2024",
    id: 58,
  },
  {
    title: "Luz sobre la Realidad",
    text: "Sé que a muchos no les gusta que le hablen del fin del mundo, de la muerte o de la ira de Dios. A mí tampoco, pero que no hable no quiere decir que no exista. Jesús dijo conoceréis la verdad y la verdad los hará libres. Él nos confrontó con esas realidades. Él dijo lo que yo os digo en secreto a vosotros publicadlo a la luz. Es por esto que muchos no se animan a decir toda la verdad del Evangelio. Solo hablan de, prosperidad y buena vida, entretienen con fabulas, pero más vale obedecer a Dios antes que a los hombres. Pero la bendición es cuando te enseñan la verdad de su palabra, porque ninguna mentira procede de la verdad y todo lo falso pasara, pero la verdad es eterna. Jesús nos anima a no vivir en temor e ignorancia.",
    author: "Raúl Enrique",
    date: "05/01/2024",
    id: 57,
  },
  {
    title: "De la creación a la redención: El camino espiritual",
    text: "En el principio creo Dios los cielos y la tierra. Habla con absoluta autoridad, creas o no fue así. Claro, no puede ser de otra manera, es Dios. Creo al hombre y a la mujer y vio Dios que todo era bueno. Los bendijo y les dio autoridad, sobre todo. Pero pecaron, por lo tanto, Dios los sentenció a muerte. Dios es Juez Justo, es por eso que la muerte paso a todos con la marca de esos moldes. Por lo tanto, somos pecadores al nacer, por elección y por práctica. Eso es en lo físico, pero tenemos vida espiritual, que continúa y necesita redención. Hay solo dos destinos, uno de vida y otro de condena. Por esto que Pablo dice que la paga del pecado es muerte, pero el regalo de Dios es vida Eterna en Cristo Jesús Señor nuestro. Bendiciones.",
    author: "Raúl Enrique",
    date: "04/01/2024",
    id: 56,
  },
  {
    title: "El Perfume del Evangelio",
    text: "Pablo dice: Gracias sean dadas a Dios que nos lleva en triunfo en Cristo Jesús y en todo lugar manifiesta por medio de nosotros el olor de su conocimiento. Porque para Dios somos grato olor de vida, para vida, pero para los que se pierden ciertamente olor de muerte, para muerte. Esto tiene que ver con mi reacción ante el mensaje poderoso de su palabra que entro por mis oídos físicos y fue hasta las cámaras más íntimas de mi espíritu, que fue olor fragante del Espíritu Santo que me convenció, me limpio y me perfumo con el lenguaje puro del evangelio. Por eso se hizo realidad la vida de Jesús en mi vida como la tuya hermano. En cambio, para los que rechazan la vida de Dios y su palabra. Se convierte en olor a muerte. Bendiciones.",
    author: "Raúl Enrique",
    date: "02/01/2024",
    id: 55,
  },
  {
    title: "Momentos de Alegría y Gratitud",
    text: "En estos días festivos, es lindo poder reunirnos como familia y disfrutar de quienes amamos. Nos pone felices en un estado de satisfacción con todo aquello que contribuye al bienestar del otro, y está muy bien disfrutar de esos buenos momentos. Es más, quisiéramos que nunca terminen esos momentos de felicidad que Dios nos da. Además, con perspectiva de una felicidad eterna en el cielo. La filosofía misma tiene mucha diferencias en cuanto definir la felicidad, pero mira lo que dice el Maestro: Una mujer se regocijó al oír a Jesús y le dijo. Señor feliz los senos que te alimentaron. Jesús le responde más bien felices son los que oyen la palabra de Dios y la guardan. Porque solo en Él hay felicidad y Vida Eterna.",
    author: "Raúl Enrique",
    date: "01/01/2024",
    id: 54,
  },
  {
    title: "Recordando los beneficios de Jehová",
    text: "Bendice alma mía a Jehová y bendiga todo mi ser su Santo nombre. Bendice alma mía a Jehová y no olvides ninguno de sus beneficios. Él es quien perdona todas tus iniquidades, Él es quien sana todas tus dolencias. El que rescata del hoyo tu vida. Es el que te corona de favores y misericordia. El que sacia de bien tu boca de modo que te rejuvenezcas como el águila. ¿Sabes una cosa? El amor no tiene competencia, es el vuelo puro del corazón hacia el otro. Es entrega, es sacrificio, es perdón. Además de eso va más allá del perdón. Te bendice, te favorece sanando las heridas de tu alma, es quien te rescata del pozo de la desesperación, te pone en las alturas como el águila y te rodea con su misericordia. Él es bueno. Bendiciones.",
    author: "Raúl Enrique",
    date: "31/12/2023",
    id: 53,
  },
  {
    title: "Deseándote lo mejor para este nuevo año en Cristo",
    text: "En este año que comienza quiero como hermano en Cristo, amigo o familiar, desearte lo bueno que tú deseas para tu vida y tu familia. Y si te pasaron algunas cosas desagradables en este año, hace como dice el apóstol Pablo: Olvidando lo que queda atrás, me extiendo hacia adelante al premio del supremo llamamiento. A lo que Dios nos tiene preparado. Porque a los que aman a Dios todas las cosas le ayudan a bien. El pasado es para aprender, el presente para vivir y el futuro es para proyectar y esperar en el tiempo de Dios, porque todo tiene su tiempo. Comencemos el año orando y siendo agradecidos, dándole gracias al Eterno. Por Jesús, por su Espíritu Santo, por su palabra y por sus promesas que son fieles y verdaderas. Bendiciones.",
    author: "Raúl Enrique",
    date: "30/12/2023",
    id: 52,
  },
  {
    title: "Ser justo para Dios: Justificado por gracia",
    text: "El camino de los justos es como la luz de la aurora, que va en aumento hasta que el día queda establecido. ¿Qué es ser justo para Dios? Es haber sido justificado por medio de nuestro Señor. No es porque seamos buenos o malos, o por algún mérito humano, sino por gracia por medio de la fe. Solo es creer en Jesús y aceptar su sacrificio perfecto, que nos hace justos delante de Dios. Es por su justicia que somos puestos delante de Dios en una correcta relación y considerados justos delante de Él. Es por esto que el justo por la fe vivirá. Esta fe se mueve como las olas del mar que van adelante y hacia arriba por la potencia del evangelio. Así que abre tu corazón como la flor que se abre para darle paso a la luz de Dios en tu vida.",
    author: "Raúl Enrique",
    date: "29/12/2023",
    id: 51,
  },
  {
    title: "Mirando al cielo: Encuentra alegría en las pequeñas cosas",
    text: "Bueno es mirar hacia el cielo, porque siempre hay algo por el cual estar con gozo. Que tus ojos y tu corazón estén siempre abiertos, de manera que podamos disfrutar de las pequeñas alegrías de la vida a tu alrededor. Cuando llevamos estas cosas a la práctica, rápidamente, todo se transforma en un regocijo interior y nuestro corazón quiere estallar de alegría por la felicidad de disfrutar de la familia de hermanos en Cristo o algún buen amigo. Esta alegría debe irradiar a otros para elevarlo a la alegría del reino de los cielos. Sí, por el contrario, no somos alegres y optimistas, seríamos un estorbo para alguien que lucha contra la depresión y la angustia. No hay mejor regalo que esparcir la alegría de la vida.",
    author: "Raúl Enrique",
    date: "27/12/2023",
    id: 50,
  },
  {
    title: "La esperanza de vida eterna en Cristo",
    text: "Dice Dios, yo soy el Dios de Abraham, de Isaac y de Jacob. Ellos habían muerto mucho tiempo atrás, pero Dios habla en tiempo presente porque ellos están vivos. Por eso Jesús les dijo a los incrédulos de la resurrección. Que Dios no es Dios de muertos, sino Dios de vivos. Pablo dijo estar ausentes del cuerpo, es estar presentes en el Señor. Osea cuando abandonamos el cuerpo estamos en presencia del Señor. Al ladrón arrepentido le dijo de cierto te digo, hoy estarás conmigo en el paraíso. También dijo porque yo vivo, vosotros también viviréis. A Marta, hermana del resucitado Lázaro, le dijo Yo soy la resurrección y la vida, el que cree en mí, aunque este muerto vivirá. Solo en Él hay esperanza de vida eterna. Bendiciones.",
    author: "Raúl Enrique",
    date: "25/12/2023",
    id: 49,
  },
  {
    title: "El universo como testigo de Dios",
    text: "Escuchaba a un periodista que le preguntaba a un abogado si creía en Dios. Le dijo hasta ahora no veo ninguna evidencia. A pesar de que es abogado, actúa como necio. Al contrario, el universo está lleno de evidencias que demuestran que es imposible que Dios no exista. Además, la Biblia declara: Dice el necio en su corazón, no hay Dios. Mira como El Señor se reveló gradualmente a la humanidad. Se revela por la naturaleza, por la creación, dijo David, los cielos cuentan la gloria de Dios, también por la conciencia. Por los 10 mandamientos, por el verbo divino Jesús, a quien envío como la máxima expresión de su amor y la realidad de su existencia. Se revela por toda la escritura a los creyentes, por el Espíritu Santo. Bendiciones.",
    author: "Raúl Enrique",
    date: "22/12/2023",
    id: 48,
  },
  {
    title: "Pablo y la visión celestial: Poniendo los ojos en lo alto",
    text: "Dice Jesús: La lámpara del cuerpo es el ojo, si tu ojo es bueno todo tu cuerpo estará en luz. Pero si tu ojo es malo, todo tu cuerpo estará en tinieblas. Si a luz que en ti hay es tinieblas, cuánto más serán las mismas tinieblas. Hermanos que maravilloso maestro es el Señor que nos enseña con esta metáfora para ayudarnos a reflexionar sobre donde ponemos nuestra mirada. Pablo decía puestos los ojos en Jesús, en las cosas de arriba. Jesús dijo. Porque dónde este vuestro tesoro allí estará tu corazón. Los ojos no tienen luz propia, sino que toman información de lo externo y la envía al interior de nuestro cuerpo para caminar seguros. Así debemos tomar la luz de Dios que iluminan el corazón, son los ojos del alma.",
    author: "Raúl Enrique",
    date: "21/12/2023",
    id: 47,
  },
  {
    title: "Amaos los unos a los otros",
    text: "Dice Jesús: Amaos los unos a los otros como yo los he amado. Nos dice que tengamos amor unos con otros, porque Él es la fuente del amor ágape definido por los griegos como un amor incondicional, un amor profundo de Dios. Tanto que dio a su Hijo Jesús en sacrificio para salvarnos de una condenación segura. Todos fuimos creados con la capacidad de amar y con la necesidad de ser amados. Él nos enseña a amarnos en su amor. Que es sufrido, benigno, todo lo puede, todo lo cree, todo lo soporta, no hace nada indebido, no se jacta, no tiene rencor, no tiene envidia, no busca lo suyo propio, no se goza del mal, llora con los que lloran y ríe con los que ríen. Que el Señor nos perfeccione en su amor. Dios bendiga tu vida siempre.",
    author: "Raúl Enrique",
    date: "20/12/2023",
    id: 46,
  },
  {
    title:
      "El resplandor en la oscuridad: Señales proféticas en la generación actual",
    text: "Cómo nunca antes, en toda la historia, en tan poco tiempo, hubo tantos cambios y movimientos a nivel global en el mundo. Eso es una prueba indudable de la veracidad de la palabra de Dios. Cambios de carácter social, cultural, político, religioso, científico y económico. Estamos viviendo en tiempos de cumplimientos proféticos con señales en el cielo. Jesús dijo que el mundo entraría en situación de dolores de parto, o sea principio de dolores. También dice que los entendidos de su palabra entenderán las señales porque resplandecerán en medio de una generación maldita y perversa que a lo malo lo llama bueno y a lo bueno lo llama malo. O sea todo al revés. Pero todo aquel que invocaré el nombre del Señor será salvó.",
    author: "Raúl Enrique",
    date: "18/12/2023",
    id: 45,
  },
  {
    title: "Adoración genuina: Más allá de las limitaciones terrenales",
    text: "Todos sabemos que hay muchas religiones por el mundo y muchas de carácter pagano que adoran a sus propios dioses, pero aunque haya muchos que se llaman Dioses con todo, nosotros tenemos un solo Dios y un solo mediador entre Dios y los hombres, a Jesucristo hombre. El mismo Dios nos dice en su palabra: El cielo es mi trono y la tierra es estrado de mis pies, ¿dónde está la casa que me han de edificar? Si yo he creado todas las cosas. Claro como vamos a limitar al Dios Altísimo a un templo o ídolos o escultura hecho por manos de hombre es pura vanidad del hombre. Pero Dios es espíritu y verdad y los que adoran en espíritu y verdad es necesario que le adoren. Adora a Dios y te dará vida y vida en abundancia. Bendiciones.",
    author: "Raúl Enrique",
    date: "17/12/2023",
    id: 44,
  },
  {
    title: "Palabras Eternas: La promesa inmutable de Jesús",
    text: "Dice Jesús: Escudriñad las escrituras porque en ellas os parece que tenéis vida eterna y ellas son las que dan testimonio de mí. Hermanos, al decir Jesús escudriñad, está afirmando la veracidad de la biblia de su palabra escrita, esto significa que creer en él por la fe es haber encontrado la verdad absoluta. El mismo dijo Yo soy el camino, la verdad y la vida. Ya no tenemos necesidad de buscar otro camino ni otra verdad porque no la hay fuera de Él. ¿Tampoco hay vida espiritual sin Cristo porque es así? Porque en Él habitan todos los tesoros de la sabiduría. Además, dijo cielo y tierra pasarán, pero mis palabras jamás pasarán ni una jota ni una tilde, o sea, ni un punto de su palabra pasará. Solo en Él hay esperanzas. Bendiciones.",
    author: "Raúl Enrique",
    date: "16/12/2023",
    id: 43,
  },
  {
    title:
      "Ríos de agua viva: La promesa de Jesús para nutrir el alma sedienta",
    text: "El Señor dice: A todos los sedientos venid a las aguas. El Señor nos invita a El mismo como fuente de agua viva, porque alguien que está sediento al grado de estar seco o deshidratado es estar en peligro de muerte. Vivir sin Dios es vivir con el alma sedienta, aunque no te des cuenta. Es por esto que el rey David en una situación de depresión dijo: Como el ciervo brama por la corriente de las aguas, así clama por ti, oh Dios, el alma mía. David buscaba saciar su sed del alma tal como ese siervo. Por eso Jesús sabe de la necesidad de nuestra alma y nos invita diciendo: El que tenga sed venga a mí y beba y de su interior correrán ríos de agua viva. Agua limpia, pura y fresca que sacia la sed del cansado y sediento. ",
    author: "Raúl Enrique",
    date: "15/12/2023",
    id: 42,
  },
  {
    title: "La importancia del Evangelio: Un llamado a la vida eterna",
    text: "Dice Dios en Deuteronomio cap. 32. Que pongamos atención al único mensaje que puede cambiar el corazón. El evangelio. Porque debe ser así, porque es un mensaje para todas las generaciones, y además no es un mensaje vano, sino para prolongar nuestros días, o sea vida eterna. Esas promesas no son una expresión de deseo o una utopía vana. Si no una realidad, porque después de esta vida hay otra. Contrario a los que dicen los escépticos. Jesús dijo que después de la muerte física solo hay dos caminos a transitar, uno es un camino de vida y otro es un camino de condenación. Si no mira lo que dice Jesús. Yo soy la resurrección y la vida, el que cree en mí, aunque este muerto vivirá, y el que vive y cree en mí, no morirá eternamente. Jesús es vida. ",
    author: "Raúl Enrique",
    date: "13/12/2023",
    id: 41,
  },
  {
    title: "De la creación al juicio final: Un viaje espiritual",
    text: "En la vida todo tiene un principio y un fin, tuvimos un comienzo y nos dirigimos hacia un fin en la historia de la humanidad. A lo que Dios llama el fin de este mundo y el comienzo de una nueva era, la que será precedida por el juicio de Dios al mundo para que empiece el reinado de 1000 años en cielo nuevo y tierra nueva. Donde entraran solo los que lavaron sus ropas en la sangre del cordero y nacieron de nuevo para Cristo. Alguien le pregunto a Jesús: ¿Señor son pocos los que se salvan? Él dijo esforzaos a entrar por la puerta angosta. Porque os digo que muchos procurarán entrar y no podrán. En los días de Noé ocurrió el diluvio y casi nadie creyó hasta que el agua les llegó al cuello. Jesús es tu Salvación y la mía. ",
    author: "Raúl Enrique",
    date: "12/12/2023",
    id: 40,
  },
  {
    title:
      "Cuando la salud nos recuerda nuestra limitación y la Importancia de la Fe",
    text: `La salud. Es uno de los medios que al fallar, con el permiso de Dios, justamente utiliza esto para llamar a nuestra puerta y recordarnos que el control verdadero no está en nuestras manos. Que justamente al querer controlarla, ni aun con la orden de nuestra mente basta para sanar. Trasladando esto a cada área de nuestra vida, quizás algunas cosas menores podamos 'tener el control', pero en los temas fuertes, importantes, trascendentes, decisivos, recurrimos a nuestra mente finita que solo nos hará fallar una y otra vez, sin ver a veces ni siquiera una mejora y hasta quizás tenemos la intención de darle el control a Dios, pero no sabemos hacerlo. Alinearnos a su Palabra, Orar a nuestro Dios, obediencia, aprender a vivir el amor, paciencia, tenacidad, esperar, obediencia, perdón, esperanza, fe, agradecimiento, y acción de decisión. Nada de esto es fácil, pero más difícil es, si no comenzamos a hacerlo. Dios es bueno.`,
    author: "Leandro Emanuel",
    date: "11/12/2023",
    id: 39,
  },
  {
    title: "No se dormirá el que te guarda",
    text: "Hermanos en Cristo y amigos, quiero mostrarles por la palabra de Dios en números 6:24, el especial interés que tiene el Señor de bendecirnos. Dios le dice a Moisés: Dile a Aaron que así bendecirá a mi pueblo, diciéndoles, El Señor te bendiga. Hermano que lindo es cuando alguien te dice que Dios te bendiga. Cuando decimos esto es una expresión de deseo que Dios te favorezca en todo siempre. Luego dice que El Señor te guarde, porque él es quien cuida tu entrada y tu salida. Porque no se dormirá el que guarda a Israel. Jesús dijo que estaría contigo siempre. Que haga resplandecer su rostro sobre ti y tenga misericordia. O sea, que nos ilumine y renueve su misericordia. Además, que alce su rostro, nos mire y ponga paz en nosotros. Bendiciones. ",
    author: "Raúl Enrique",
    date: "10/12/2023",
    id: 38,
  },
  {
    title: "El amor divino: Entre la libertad y la justicia",
    text: "Dios es Amor. Es por esto que nos dio el famoso libre albedrío con la libertad de elección y acción, pero no nos deja sin consejo porque él lo sabe todo y sabe lo que nos hace bien o mal. Porque dónde existe el amor tiene que existir la libertad de expresión y pensamiento. Porque de lo contrario sería una contradicción del amor. Pero Dios no es un dictador y no desea la esclavitud. Pero así como Dios es amor, también es Santo y además Juez eterno que no tendrá por inocente al malvado. Su palabra dice que cuando el hombre va más allá de sus límites, Dios ejerce su soberanía y estableció un día en que juzgara a este mundo con juicio y castigará toda injusticia y salvará a los redimidos por Jesús. Bendiciones.",
    author: "Raúl Enrique",
    date: "09/12/2023",
    id: 37,
  },
  {
    title:
      "Antorchas en la oscuridad: Entendiendo la luz de Jesús en nuestra vida",
    text: "Dice Jesús: Yo soy la luz del mundo, el que a mí viene no andará en tinieblas, sino que  tendrá la luz de la vida. En la antigua Jerusalén se encendían grandes antorchas para iluminar la ciudad y aun así quedaban rincones dónde no llegaba la luz a todos. Es por eso que Jesús dice en sentido espiritual, Yo soy la luz verdadera que alumbra a todo hombre. Esta luz de Jesús es la que revela la condición de tu corazón, porque estamos expuestos a la luz de Dios que todo lo ve y muchos no quieren venir a mí, dice porque sus obras son malas y no quieren que sean reveladas. Pero al que a mí viene no andará a ciegas ni tropezara, y caminará seguro por la luz de la vida. El Señor te ilumine a vos y tu familia. Bendiciones.",
    author: "Raúl Enrique",
    date: "08/12/2023",
    id: 36,
  },
  {
    title: "Salvación por Gracia: El regalo del amor de Dios",
    text: "Pablo dice: No me avergüenzo del Evangelio porque es poder de Dios para todo aquel que cree. También dice: Porque la paga del pecado es muerte. Fíjate que esa es la mala noticia, pero la buena noticia es que la dádiva de Dios es vida eterna en Cristo Jesús Señor nuestro. Esto no es por obra para que ninguno se gloríe, no por lo bueno que soy, sino por medio de la fe. Soy salvo por pura gracia, por ese don inmerecido, al ser Justificado por su sangre a través de arrepentimiento, fe en El Señor que ofrendo su vida por nosotros. No desprecies el regalo del amor, que se entregó por ti en sacrificio vivo. Pablo dijo: La cruz es locura para los que se pierden, pero para los que se salvan es poder de Dios.",
    author: "Raúl Enrique",
    date: "07/12/2023",
    id: 35,
  },
  {
    title: "Lecciones de humildad: El diálogo revelador con Jesús",
    text: "Un Joven rico aristócrata ve a Jesús mientras predicaba, corre hacia Él en un rapto de emoción violenta y se arrodilla a los pies de Jesús, de aquel humilde carpintero de Nazaret. Le dice maestro bueno, que bien haré para heredar vida eterna. Porque me llamas bueno, uno solo es bueno, dice Jesús Dios. Él creía en la resurrección, pero estaba preocupado por cuál sería su destino. Jesús primero, es como si le pusiera paño de agua fría a su emoción juvenil, y dice nada de halagos, reserva esas palabras para Dios. Jesús hizo lo que todo predicador y maestro debe hacer, dirigir la atención a Dios por encima del ego y la vanagloria del hombre. Solo Él es digno de toda gloria.",
    author: "Raúl Enrique",
    date: "06/12/2023",
    id: 34,
  },
  {
    title:
      "Dos caminos, dos destinos: La elección entre el impío y el creyente",
    text: "Dice Dios: Muchos dolores habrá para el impío, más el que espera en Jehová le rodea la misericordia. Fíjate la diferencia que establece el Señor entre el impío que es contrario a la piedad, a todo lo santo que es, necio, escarnecedor, aborrecedor de Dios. Y, por otro lado, el creyente que es amante de Dios, de todo lo bueno, la piedad, la santidad, la humildad, el amor, la adoración, a ser agradecido a Dios por Jesús, el Espíritu Santo, su palabra y sus promesas fieles y verdaderas. Sin dudas se entra por dos puertas, se transita por dos caminos, dos grupos de personas y dos destinos diferentes. O a la salvación o a la condenación. Pero Jesús te rodea con su misericordia. Bendiciones",
    author: "Raúl Enrique",
    date: "03/12/2023",
    id: 33,
  },
  {
    title: "Reunidos en su Nombre: Bendición, enseñanza y fortaleza",
    text: "Mirad cuan hermoso y cuan delicioso es habitar los hermanos juntos y en armonía, porque allí envía Jehová salvación y vida eterna, Salmos 133:1. Cuando nos reunimos en comunidad con nuestros hermanos para adorar a al que es Digno de toda gloria, nuestro corazón se regocija. Porque allí el protagonista y digno de adoración es el Rey de Reyes y Señor de Señores. Es estar en un pedazo de cielo, porque la gloria de Dios se hace presente. Jesús prometió su presencia divina cuando dijo: Dónde hubiere dos o tres congregados en mi nombre, allí estoy yo. Su espíritu nos habla y nos enseña para edificación, consuelo y fortaleza. Te invito a compartir su bendición.",
    author: "Raúl Enrique",
    date: "02/12/2023",
    id: 32,
  },
  {
    title: "Sabiduría Divina: Teme a Dios y aléjate del mal",
    text: "El Señor dice: No seas sabio en tu propia opinión. Teme a Dios y apártate del mal, porque será medicina a tu cuerpo y refrigerio para tus huesos. El temor que nosotros conocemos es esa reacción emocional ante un peligro inminente, pero existe una clase de temor reverencial ante lo desconocido por ser sobrenatural y santo, a la que nos rendimos con admiración. Cuando Jesús detuvo al viento y le dio orden al mar, todo se calmó. Los discípulos estaban con temor, pero los invadió otra clase de temor, y dijeron que clase de hombre es este que le da órdenes al mar y le obedece. Eso dice que si obedecemos, Él será remedio y descanso para nuestros huesos. Bendiciones.",
    author: "Raúl Enrique",
    date: "01/12/2023",
    id: 31,
  },
  {
    title: "El Evangelio en acción: Amar, arrepentirse y creer en Cristo",
    text: "Dios dice. Yo amo a los que me aman, y me hallan los que temprano me buscan. Buscadme mientras pueda ser hallado hoy, es el día de salvación. Cada día de vida que Dios te da en este presente, es una oportunidad para que nos pongamos a cuentas con Dios a través de reconocer a Dios en actitud de arrepentimiento y fe en Él. Su bondad nos guía al arrepentimiento, sabemos que Dios ama. Pero dice que al que no cree en Jesús, su ira está sobre él. Léelo en Juan 3:36, y también lee Salmos 7:11, que dice Dios es juez justo, y está airado contra el impío todos los días. Romanos 1: Decimos al inconverso, Jesús te ama, pero Jesús, Pedro, Juan y Pablo decían arrepentíos y creed en el Evangelio. Bendiciones.",
    author: "Raúl Enrique",
    date: "30/11/2023",
    id: 30,
  },
  {
    title: "Navegando las aguas de la degradación hacia la gracia",
    text: "Al observar el mundo. Vemos el amor, el buen gusto, la diversidad, la ingeniería y la perfección de la creación del Dios, el soberano y altísimo dueño de todo lo que se ve y lo que no vemos. Por esto decía pablo: Oh profundidad de las riquezas de la sabiduría y de la ciencia de Dios, cuan insondables e inescrutables son sus caminos. Además, está lleno de vida este planeta, y Dios creo al hombre y a la mujer, para que después tengan hijos que poblarían toda la tierra. Pero debido al pecado del hombre, la degradación moral, social, política, cultural y religiosa, las familias sufren. Pero te diré algo, Dios no falla. El ideo, la familia, y las que están en Cristo serán benditas. Bendiciones.",
    author: "Raúl Enrique",
    date: "28/11/2023",
    id: 29,
  },
  {
    title:
      "Vestiduras blancas y aceite abundante: La sabiduría de Dios para la Vida",
    text: "Dice Dios, en todo tiempo sean blancos tus vestidos y nunca falte aceite en tu cabeza, gózate con la mujer de tu juventud todos los días de tu vida, que Dios te da para que vivas. Qué consejo maravilloso de aquel que todo lo sabe que mantengamos la pureza, la blancura, el brillo de nuestra vestidura, además que no falte el aceite que representa el espíritu y el poder de Dios en tu vida. Que disfrutes con la mujer de tu juventud. Mantener ese vínculo de amor en santidad y honor por el cual el amor es tan grande que no se puede explicar en palabras. Mira lo que dice Dios: Si alguno va contra uno, dos le resistirán y cordón de tres no se rompe, el tercero es Dios como el centro de tu vida. Bendiciones.",
    author: "Raúl Enrique",
    date: "27/11/2023",
    id: 28,
  },
  {
    title: "Enoc: Un hombre que eligió caminar con Dios",
    text: "Hay caminos que al hombre les parecen derecho, pero su fin es camino de muerte. Por el contrario, caminar con Dios es caminar con la vida misma no solo en esta vida, sino en la vida que trasciende este tiempo y espacio más allá de la retina de estos ojos físicos. Un hombre llamado Enoc, después que nació su hijo, decidió caminar con Dios. Algo sucedió en su vida que tomo esta decisión sabia. No solo camino con Dios, sino en pos de Dios. Porque una cosa es caminar con alguien y otra cosa es siguiendo a alguien, esto le agrado a Dios que se lo llevó al cielo porque tenía testimonio de haber agradado a Dios en fe. Porque sin fe es imposible agradar a Dios.",
    author: "Raúl Enrique",
    date: "26/11/2023",
    id: 27,
  },
  {
    title: "El libro de Apocalipsis: Un mensaje de juicio y esperanza",
    text: "El libro de apocalipsis no habla solo de tragedias y juicio. También habla de bendiciones para los convertidos a Cristo. Mira lo que dice en apocalipsis 20:1 1 sobre el juicio del trono blanco: Vi un trono, dice Juan, y al que estaba sentado en el trono. Vi a los muertos grandes y pequeños de pie ante Dios, y los libros fueron abiertos, y otro libro fue abierto, el cual es el libro de la vida, y fueron juzgados los muertos cada uno según sus obras. El versículo 15 dice: El que no se halló inscripto en el libro de la vida fue lanzado al lago de fuego. Pero dice felices, los que tienen parte en la primera resurrección, porque la muerte segunda no tiene poder sobre estos. Si Jesús está en vida, tu nombre estará ahí. Bendiciones.",
    author: "Raúl Enrique",
    date: "26/11/2023",
    id: 26,
  },
  {
    title: "Dios y el tiempo: Sabiduría para una vida significativa",
    text: "Dice el predicador. Vanidad de vanidades, todo es vanidad. Porque hay un tiempo para cada cosa (Eclesiastés 3). Todo tiene su tiempo. Tiempo de nacer, tiempo de morir, tiempo de sembrar y tiempo de cosechar. Dios nos impuso el régimen del tiempo y debemos aprovechar el tiempo porque los días son malos, los días están contados y se acaban. Dios abrió una ventana en el tiempo y nos dio vida, por la que somos conscientes de la vida y la muerte. Como él es el dador del tiempo, sabe lo que nos conviene y nos llama a ser sabios y prudentes, invirtiendo el tiempo para la vida que nos promete con Él. Sembrando la semilla de su palabra que transforma los corazones. Bendiciones.",
    author: "Raúl Enrique",
    date: "25/11/2023",
    id: 25,
  },
  {
    title: "Jesús y las lágrimas: Encontrando consuelo en medio de las pruebas",
    text: "Dice el rey Salomón. Tiempo de llorar, tiempo de reír. Tiempo de lamentar, tiempo de alegrarse. En este mundo en el que vivimos no podemos escapar de no llorar. Alguna vez hemos llorado y lloraremos. También nos hemos reído, pero tanto el llorar como reír pasará porque es temporal, a pesar de llorar por algún dolor en el corazón. Debido a que estamos rodeados de seres queridos, familiares, amigos o hermanos en Cristo a quienes amamos. En medio de este mundo donde el bien tiene que obrar en contra del mal y el enemigo de nuestra alma. Pero Jesús nos alienta y nos dice. Felices los que lloran porque ellos recibirán consolación. Bendiciones.",
    author: "Raúl Enrique",
    date: "23/11/2023",
    id: 24,
  },
  {
    title: "Venciendo Montañas: El Desafío de la Fe en la Enseñanza de Jesús",
    text: "Dice la biblia que Dios llama las cosas que no son como si fueran. Porque por fe entendemos haber sido constituido en el universo, de modo que lo que fue hecho de lo que no se veía. Porque su eterno poder y deidad se hace claramente visible, siendo entendida por medio de las cosas hechas. Además, la fe es un don de Dios porque creemos sin ver. Es la convicción de lo que no se ve y la certeza de lo que se espera. Jesús dijo, si tuvieras fe como un grano de mostaza y le dijeres a este monte, muévete a otro lado y se moverá. Por supuesto que no sería literal, aunque el todo lo puede, se refería a que si tenemos montañas de problemas con fe venceremos. Bendiciones",
    author: "Raúl Enrique",
    date: "22/11/2023",
    id: 23,
  },
  {
    title: "Amarte igual",
    text: "Si no me dieras lo que te pido, hasta dejarme en el olvido, ¿Sería yo capaz de amarte igual? ¡Desde ya quiero que el mundo sepa que no te busco por conveniencia! ¡Si estoy aquí, no es coincidencia, pues eres dueño de mi existencia, y yo te amo sin entenderlo, pero yo te amo! ¡No se trata de lo que me das ni de lo que haces! ¡Y yo te amo! ¡Sin entenderlo, pero yo te amo por quien tú eres, Te amo porque eres mi Dios!",
    author: "Canción - Amarte Igual",
    date: "17/11/2023",
    id: 22,
  },
  {
    title: "Cara a Cara con Jesús: La Promesa de un Encuentro Celestial",
    text: "Hemos hablamos del cielo que es un lugar literal, que es un paraíso celestial, al cual Jesús dijo bienaventurados los que entren por las puertas de la ciudad. Alguien dijo que esto sería sorprendente. Y así es, porque Dios es un Dios Todopoderoso que creo esta galaxia y las miles que hay en el universo. Muchos se preguntan, ¿Tendremos cuerpos? Y Pablo dijo, una es la gloria del sol, otra la gloria de la luna y otras la gloria de las estrellas, y hay cuerpos terrenales y cuerpos espirituales. ¿Será que nos vamos a reconocer unos con otros? Jesús dice que lo veremos cara a cara. Pablo dice, conoceré como fui conocido. No habrá dolor porque Él enjugará toda lágrima. Bendiciones.",
    author: "Raúl Enrique",
    date: "12/11/2023",
    id: 21,
  },
  {
    title: "Moradas celestiales: El mensaje reconfortante del Señor",
    text: "El Señor le dijo a sus discípulos, no se turbe vuestro corazón, ni tenga miedo, creéis en Dios, creed también en mí. En la casa de mi padre muchas moradas hay. Si así no fuera, yo se los hubiera dicho. Él es la verdad y no puede mentir. Para confirmar también Pablo dijo que él fue al paraíso, al tercer cielo donde vio y oyó cosas inefables. Que cosas que ojo no vio ni oído oyó ni ha subido al corazón del hombre son las que Dios ha preparado para los que lo aman. Bendiciones.",
    author: "Raúl Enrique",
    date: "10/11/2023",
    id: 20,
  },
  {
    title: "Nuestro hogar es el cielo: Más allá de este mundo",
    text: "Buscad las cosas de arriba donde está Cristo sentado a la diestra de Dios. Poned la mira en las cosas de arriba, no en las de la tierra. Dice que pongamos nuestra mirada en el cielo, y no atarnos a este mundo aunque vivimos aquí. Jesús dijo que nosotros estamos en el mundo, pero no somos del mundo, o sea del sistema de este mundo que se mueve sin la voluntad de Dios. Además, es temporal y pasajero que no tiene futuro sin el Creador. Por hermoso que sea este planeta es como un gran hotel cinco estrellas, por bonito que sea nos tenemos que marchar. Porque este mundo es la antesala de la entrada al cielo, que es eterno. Bendiciones.",
    author: "Raúl Enrique",
    date: "08/11/2023",
    id: 19,
  },
  {
    title: "Sal de vida: Un llamado a la pureza en un mundo corrupto.",
    text: "Dice Jesús, vosotros sois la sal de la tierra. Si la sal se hiciere insípida con que será salada, no sirve más para nada, sino para que sea pisoteada por los hombres. En la antigüedad la sal era un elemento esencial para las vidas de las personas al grado de cambiarla por oro. Dicen que a los trabajadores se les pagaba con sal, de dónde viene la palabra salario. Era muy importante para conservar la carne para evitar que se pudra y además darle buen gusto a la comida. La sal es sacada del mar, es blanca, refulgente, y  eso nos habla de llevar una vida de pureza, frescura, sabor y buen testimonio en medio de este mundo corrupto. Bendiciones.",
    author: "Raúl Enrique",
    date: "07/11/2023",
    id: 18,
  },
  {
    title: "La importancia de la misericordia en la vida Cristiana",
    text: "Dice el Señor Jesús. Bienaventurados, los misericordiosos por ellos alcanzarán misericordia. Jesús felicita a los cristianos diciendo que son doblemente felices al poner en práctica la misericordia. Somos llamados por Dios a ser perdonadores, a no guardar rencor para que nosotros también seamos perdonados. Porque juicio sin misericordia se hará con los que no hagan misericordia y la misericordia triunfa sobre el juicio. Además, no se trata de una simple simpatía con el otro, sino de la capacidad de identificarse con el que llora y sufre. Es lo que hizo Jesús por nosotros, siendo Dios vino como hombre para ver y sentir como nosotros. Bendiciones.",
    author: "Raúl Enrique",
    date: "04/11/2023",
    id: 17,
  },
  {
    title: "La esperanza de una vida transformada: Un corazón puro en Cristo",
    text: "Dice Jesús. Bienaventurados los de limpio corazón, porque ellos verán a Dios. Qué hermosa esperanza tenemos los cristianos. Su palabra dice que algún día lo veremos tal como Él es. También dice que todo aquel que tiene esa esperanza en sí mismo se purifica. Nuestro corazón es mucho más que una bomba biológica que emana sangre por venas, arterias o tubos capilares, ya que debido a la caída de Adam y Eva somos pecadores de nacimiento por elección y por práctica, entonces mi corazón debe ser regenerado. Tiene que haber un nuevo nacimiento espiritual a través del arrepentimiento en mi vida, por la gracia de Jesús. Un corazón puro es sin agregados, con motivaciones puras. Bendiciones.",
    author: "Raúl Enrique",
    date: "02/11/2023",
    id: 16,
  },
  {
    title: "La búsqueda de la perla preciosa",
    text: "Dice Jesús. El Reino de los Cielos es semejante a un mercader que busca buenas perlas, que habiendo hallado una perla preciosa, fue y vendió todo lo que tenía y la compro. Mateo 13:44. Una parábola es un relato terrenal con un significado espiritual. Fíjate que el mercader en su búsqueda encontró la perla del gran precio, así también nosotros buscamos cosas buenas que nos satisfagan y nos dé felicidad en un mundo donde también existe el mal, pero así como el mercader encontró la perla preciosa, nosotros encontramos a Jesús su evangelio y su Reino. Que es puro, brilloso, consistente y eterno, contrario a lo pasajero y efímero de esta vida. Bendiciones.",
    author: "Raúl Enrique",
    date: "30/10/2023",
    id: 15,
  },
  {
    title: "La importancia de la aprobación divina en nuestras acciones",
    text: "Muchas veces estamos llamando bueno, a lo malo (Quizás sin darnos cuenta). Y nuestra justificación inmediata a esto, es que no le estamos haciendo mal a nadie. Y puede que a nuestros ojos, o mismo en los sentimientos y emociones, sea así. Pero, nunca dejemos de entender que hay algo más importante que no hacerle mal al ser humano, que es que nuestro accionar no sea reprobado por el Dueño de todo. Por eso hasta nuestro último suspiro deberíamos estudiar lo que Él enseña, y es su Palabra.",
    author: "Leandro Emanuel",
    date: "28/10/2023",
    id: 14,
  },
  {
    title: "El Mensaje Profético: Paz, Guerra y Redención",
    text: "Dice Dios. Hay caminos que al hombre les parecen derechos, pero que su fin es camino de muerte. El hombre habla de paz y se prepara para la guerra. Podemos ver y oír los tambores de guerra. Jesús dijo que se levantará nación contra nación, que cuando veamos a Jerusalén o Israel rodeada de ejércitos erguíos y levantad vuestras cabezas, porque vuestra redención se acerca y no naturalicemos el cumplimiento de las profecías, Pablo dijo, no menosprecies las profecías, o sea lo que está escrito, porque Jesús dijo que antes de su venida va a haber una convergencia de acontecimientos a nivel global. Oremos por arrepentimiento y fe en Cristo. Bendiciones.",
    author: "Raúl Enrique",
    date: "25/10/2023",
    id: 13,
  },
  {
    title: "Una Vida con Sentido: Tener a Jesús en el Corazón",
    text: "Ante los tiempos y la realidad que nos toca vivir quiero pedirte de todo corazón que busquemos la presencia de Dios en nuestra vida a entrar en intimidad con Jesús de manera personal te digo esto como hermano en Cristo como papá como amigo no te alejes de la persona más maravillosa de todo el universo si te vas de este mundo que sea con Él en tu corazón porque sin él en  tu vida pasarías por este mundo como una estrella fugaz, en cambio, con Jesús tendrás vida eterna con Él y con los tuyos que abrazaron a Jesús para salvación en esta vida. La biblia dice: El que tiene al hijo tiene la vida y el que no tiene al hijo no tiene la vida, porque tener al hijo a Jesús en tu corazón le da a tu vida sentido, contenido y significado. Que el Señor te guarde siempre, tal como lo prometió cuando dijo, al que venciere caminara conmigo en el paraíso. Bendiciones.",
    author: "Raúl Enrique",
    date: "20/10/2023",
    id: 12,
  },
  {
    title: "La Esperanza Gloriosa de los Cristianos",
    text: "En el contexto de esta palabra, el apóstol Pedro viene diciendo que el mundo como lo conocemos va a ser destruido por fuego, esto nos hace pensar por ejemplo en el calentamiento global reconocido por el mundo, científico y la sociedad humana en la que vivimos que va en aumento. Pero a pesar de lo que vendrá y lo inevitable de estas cosas, Pedro nos alienta con estas palabras para mantener viva nuestra fe y esperanza en El Señor, diciendo más nosotros esperamos según sus promesas, cielo nuevo y tierra nueva dónde mora la justicia.2p 3:13. Te das cuenta de que esperanza gloriosa tenemos los cristianos. Jesús no falla. Ánimo El Viene.",
    author: "Raúl Enrique",
    date: "19/10/2023",
    id: 11,
  },
  {
    title: "La seguridad en Jehová",
    text: "Jehová es mi pastor y nada me faltará, en lugares de delicados pastos, me hará descansar y junto a aguas de reposo me pastoreará. Qué hermosa figura del pastor y obispo de nuestra alma. Dice que Él es mi pastor, mi presente, mi posesión, es mi protector y también mi proveedor. Todos nosotros en algún momento de nuestra vida pasamos por situaciones de crisis, adversidades debido a lo inestable de este mundo que nos toca vivir, pero que no deja de ser una enseñanza para recordar las promesas del Señor que dice nunca te dejaré ni te desampararé. Porque a los que aman a Dios todas las cosas le ayudan a bien. Bendiciones.",
    author: "Raúl Enrique",
    date: "16/10/2023",
    id: 10,
  },
  {
    title: "Jesús: Fuente de Gozo y Fortaleza en la Vida",
    text: "Torre fuerte es el nombre del Señor, a ella corre el justo y se le da protección, porque muchas son las aflicciones del justo, pero de todas ellas los librará El Señor. Buscadme, dice el Señor, y me dejaré hallar, porque el que temprano me busca me hallará, y el que me halle hallará la vida. Mis ojos recorren la tierra para salir a favor de los humildes de corazón, porque el cielo es mi trono y la tierra es estrado de mis pies. Pero miraré al que es pobre de espíritu y tiembla a mi palabra, porque mis palabras, dijo Jesús, son espíritu y vida. Hermano y amigo Jesús en tu vida es un poderoso motivo de gozo que te fortalece para vencer y ser victorioso en Él. Bendiciones.",
    author: "Raúl Enrique",
    date: "15/10/2023",
    id: 9,
  },
  {
    title: "Profecías y Realidad",
    text: "Días de tristeza para el mundo, días grises con un futuro inmediato oscuro, Jesús dijo que para el tiempo del fin de este mundo habría, guerras, y rumores de guerras, pero el fin no será inmediatamente y más cuando se trata de Israel, que sería el reloj profético del cumplimiento de las profecías, pero hay quienes prefieren ignorar voluntariamente como dijo Pedro. Por temor, por vergüenza o conveniencia o para que no lo tilden de religioso fanático, pero Jesús advirtió sobre esto, para orar, porque la muerte, el dolor y las lágrimas no tienen fronteras. Es sufrimiento para judíos, palestinos o cristianos. Oremos.",
    author: "Raúl Enrique",
    date: "13/10/2023",
    id: 8,
  },
  {
    title: "El Único Mediador y Salvador",
    text: "Jesús dice. Escudriñad las escrituras porque en ella hay vida eterna y ellas dan testimonio de mí. Claro, desde el comienzo, con la caída de Adam y Eva, se habla de Jesús como la simiente preciosa que salvaría a todo aquel que cree en Él. Desde génesis hasta apocalipsis se habla de El cómo único mediador entre Dios y los hombres, por eso es el deseado de todas las naciones, y no hay otro nombre dado a los hombres en quien podamos ser salvos, sino en el nombre de Jesús. Por eso Él te invita en este día a beber de su agua fresca, limpia y pura de la palabra de Dios para andar en armonía con su voluntad. Bendiciones.",
    author: "Raúl Enrique",
    date: "12/10/2023",
    id: 7,
  },
  {
    title:
      "Felicidad a través de las Bienaventuranzas: Encontrando Alegría en la Fe",
    text: "Las bienaventuranzas nos hablan de la felicidad que se encuentra en la persona que se ha rendido a Cristo y fue transformado por el poder de Dios. Es por esto que Pablo dice si alguno está en Cristo, nueva criatura es las cosas viejas, pasaron y en El son todas hechas nuevas. El Señor felicita y dice que son doblemente felices al haber reconocido a Dios en su vida y añade. Felices los pobres de espíritu, porque de ellos es el reino de los cielos. Felices los que lloran porque ellos recibirán consolación. Felices los puros de corazón porque ellos verán a Dios. Felices los misericordiosos porque ellos alcanzarán misericordia. Bendiciones.",
    author: "Raúl Enrique",
    date: "11/10/2023",
    id: 6,
  },
  {
    title: "La Parábola de la Higuera: Señales Proféticas del Futuro",
    text: "Jesús dijo: De la higuera aprended la parábola cuando su rama está tierna y brotan las hojas, sabe que el verano está cerca. Una parábola es un relato terrenal con un significado espiritual. Y aquí la higuera representa a Israel, el brote de las hojas es el crecimiento y la prosperidad que tendría Israel  desde que en el 14 de mayo de 1948 fue declarado como un estado, esas son las hojas que brotaron y el verano nos habla de la venida de Jesús, eso significa que está muy cerca la venida de Señor. De cierto os digo que no pasará esta generación sin que estás acontezcan, el mismo Señor dijo cuando vea a Jerusalén rodeadas de ejércitos erguíos y levantada vuestras cabezas porque vuestra redención se acerca. Yo sé que muchos pueden decir eso siempre paso, pero estamos en la era de la inteligencia artificial en tiempos de cumplimientos, profecías bíblicas dónde se habla de guerras químicas, bacteriológicas y radiactivas. Lo podemos ver con nuestros propios ojos. Jesús dijo cielo y tierra pasará, pero mis palabras nunca pasarán.",
    author: "Raúl Enrique",
    date: "10/10/2023",
    id: 5,
  },
  {
    title: "El Amor: La Palabra Hermosa que Transforma Vidas",
    text: "Qué palabra hermosa es la palabra amor y amor es amar, es lo contrario al odio, al rencor, a la falta de perdón. El amor es el vuelo puro del corazón hacia otros, como lo demostró El Señor en la cruz, tomando nuestro lugar, entregando su propia vida para salvarnos de una condenación segura. Al ser la máxima expresión del amor de Dios, porque su amor tiene características perfectas, por ser El mismo perfecto, pero nosotros por ser imperfectos, nuestro amor es imperfecto, pero debemos imitar el amor de Jesús que es sufrido, es benigno todo lo puede todo lo cree todo lo soporta no hace nada indebido no se jacta no tiene rencor no tiene envidia no busca lo suyo propio y no se goza del mal. Bendiciones.",
    author: "Raúl Enrique",
    date: "09/10/2023",
    id: 4,
  },
  {
    title:
      "Felicidad Espiritual: Encontrando la Verdadera Plenitud en el Corazón",
    text: "Naturalmente, todos los seres humanos estamos en búsqueda de la felicidad, es decir, estar plenos y satisfechos en armonía y en paz, pero la realidad no es así, aunque la apariencia diga otra cosa. Lo cual no quiere decir que no tengamos momentos de felicidad junto a nuestros seres queridos y amigos. Si sumamos estos lindos momentos sería como la felicidad de toda la vida. Pero hay un tipo de felicidad y de regocijo interior que va más allá del plano físico. Al decir Jesús  felices los que oyen la palabra de Dios y la guardan en su corazón. Bendiciones.",
    author: "Raúl Enrique",
    date: "09/10/2023",
    id: 3,
  },
  {
    title: "El Poder de la Paz Interior en la Salud: Un Enfoque Espiritual",
    text: "Espíritu triste seca los huesos. Los Médicos de antes no tenían en claro que las emociones negativas enfermaban el cuerpo y hoy sabemos por la medicina moderna que cuando uno se pone triste se deprime y sufre por el estrés sicológico y las presiones de la vida. Automáticamente, se bajan nuestras defensas e inmediatamente se dejan ver las consecuencias. Cuando enferma nuestro cuerpo y hay riesgo de vida. Busquemos la paz en Cristo. Bendiciones.",
    author: "Raúl Enrique",
    date: "08/10/2023",
    id: 2,
  },
  {
    title: "Tesoros en el Cielo: El Verdadero Significado de la Riqueza",
    text: "Dice Jesús: no hagan tesoros en la tierra, más bien hagan tesoros en el cielo donde la polilla ni el orín corrompen. Porque la vida del hombre no consiste en los bienes que posee, sino en ser ricos para con Dios, porque raíz de todo mal es el amor al dinero. Sin duda, como dice la biblia, desnudos vinimos al mundo, desnudos volveremos. Podemos ver que no solo lo dice, sino que lo vemos en la vida real. Ni ricos ni pobres nada llevaremos. Porque todo es de Dios que es El Eterno. Jesús siendo rico se hizo pobre para que nosotros seamos ricos en fe. Bendiciones.",
    author: "Raúl Enrique",
    date: "07/10/2023",
    id: 1,
  },
];
