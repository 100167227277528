import React, { useEffect, useState } from "react";
import Navbar from "../navNar";
import styled from "styled-components";
import MainMessage from "../mainMessage";
import Footer from "../footer";
import axios from "axios";
import { numberVersesBook } from "../../utils/versesData";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Logo, LogoWrapper } from "../styles/shared";
import { keysToSnake } from "../../helpers/humps";
import { useWindowSize } from "../../hooks/windowHook";
import { detectOS } from "../../utils/home";
import { useAnalytics } from "../../hooks/analyticsHook";

const Layout = styled.main`
  background: #800040;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 113px !important;
  padding-left: 16px;
  padding-right: 16px;
  background: #ffffff;

  > button {
    cursor: pointer;
    padding: 4px;
    border-radius: 16px;
    border: 1px solid;
    margin-bottom: 16px;
    font-size: 32px;
  }

  h1,
  h2 {
    grid-column: 1 / -1;
    text-align: center;
  }

  > h1 {
    font-size: 40px;
    margin-bottom: 40px;
    margin-top: 0;
  }

  @media (max-width: 500px) {
    padding-top: 20px !important;
  }
`;

const BooksContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
  margin-bottom: 80px;

  h2 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  p {
    margin: 0;
    text-align: center;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const CapsContainer = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 80px;

  > p {
    text-align: center;
    cursor: pointer;
    border: 1px solid #000000;
    padding: 8px 4px;
    border-radius: 40px;
    font-weight: 700;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const VersesContainer = styled.div`
  margin-bottom: 80px;
`;

const BackButton = styled.button`
  position: fixed;
  left: 16px;
  bottom: 24px;
  width: 40px;
  height: 40px;
  margin: 0 !important;
  cursor: pointer;
  border: none;
  display: none;
  outline: none;

  @media (max-width: 500px) {
    display: block;
  }

  :focus {
    outline: none;
  }
`;

const apiUrl =
  "https://ajphchgh0i.execute-api.us-west-2.amazonaws.com/dev/api/books";

const Bible = () => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const [booksData, setBooksData] = useState<any>([]);
  const [bookData, setBookData] = useState<any>([]);
  const [statusPage, setStatusPage] = useState(0);
  const [numberVersesData, setNumberVersesData] = useState(0);
  const [bookId, setBookId] = useState("");
  const [nameBook, setNameBook] = useState("");
  const [numberCaps, setNumberCaps] = useState(0);

  useEffect(() => {
    axios
      .get(apiUrl)
      .then((response) => {
        const sortedArray = response?.data?.sort(
          (a: any, b: any) => a.order - b.order
        );
        setBooksData(sortedArray);
      })
      .catch((error) => {
        console.error("Error al obtener datos de la API:", error);
      });
  }, []);

  const { width } = useWindowSize();
  const isMobile = width <= 500;
  const tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (!!width) {
      track(
        "Page Viewed",
        keysToSnake({
          name: "Bible",
          timeZone: tz,
          mobile: isMobile,
          os: detectOS(),
        })
      );
    }
  }, [width]);

  const getVerses = (id: string, verses: any) => {
    axios
      .get(`${apiUrl}/${id}/verses/${verses}`)
      .then((response) => {
        setStatusPage(2);
        if (typeof window !== "undefined") {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        setBookData(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de la API:", error);
      });
  };

  return (
    <Layout>
      <MainMessage />
      <Navbar />
      <LogoWrapper>
        <Logo
          src="https://res.cloudinary.com/dlcgloggr/image/upload/v1697739287/logo-19-10_nyh2m0.png"
          alt="logo"
          width={80}
          onClick={() => navigate("/")}
        />
      </LogoWrapper>
      <Container>
        <h1>Biblia</h1>
        {statusPage > 0 && (
          <button onClick={() => setStatusPage(statusPage - 1)}>
            {statusPage === 1 ? "Ver libros" : "Ver capítulos"}
          </button>
        )}
        {statusPage === 0 ? (
          <BooksContainer>
            {booksData?.map((books: any, index: number) => {
              const filterBook: any = numberVersesBook?.filter(
                (book: any) => book.name === books.name
              )?.[0];

              return (
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setNumberVersesData(filterBook?.verses);
                    setBookId(filterBook?.id);
                    setNameBook(books.name);
                    setStatusPage(1);
                    if (typeof window !== "undefined") {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }
                  }}
                  key={index}
                >
                  {books?.name}
                </p>
              );
            })}
          </BooksContainer>
        ) : statusPage === 1 ? (
          <CapsContainer>
            <h2>Capítulos de {nameBook}</h2>
            {new Array(numberVersesData)
              .fill("")
              ?.map((books: any, index: number) => {
                return (
                  <p
                    onClick={() => {
                      setNumberCaps(index + 1);
                      getVerses(bookId, index + 1);
                    }}
                    key={index}
                  >
                    {index + 1}
                  </p>
                );
              })}
          </CapsContainer>
        ) : statusPage === 2 ? (
          <VersesContainer>
            <h2>
              {nameBook} {numberCaps}
            </h2>
            {bookData?.map((books: any, index: number) => {
              const htmlObject = { __html: books?.text };
              return (
                <div dangerouslySetInnerHTML={htmlObject} key={index}></div>
              );
            })}
          </VersesContainer>
        ) : null}
        {/*        {statusPage > 0 && (
          <BackButton
            onClick={() => {
              setStatusPage(statusPage - 1);
              if (typeof window !== "undefined") {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }
            }}
          >
            <FaArrowLeft />
          </BackButton>
        )} */}
      </Container>
      <Footer />
    </Layout>
  );
};

export default Bible;
