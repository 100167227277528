import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../navNar";
import styled from "styled-components";
import MainMessage from "../mainMessage";
import Footer from "../footer";
import Short from "../short";
import { shorts } from "../../utils/shortsData";
import { useNavigate } from "react-router-dom";
import { Logo, LogoWrapper } from "../styles/shared";
import { useWindowSize } from "../../hooks/windowHook";
import { RenderLogo } from "../../assets/icons/RenderLogo";
import { keysToSnake } from "../../helpers/humps";
import { detectOS } from "../../utils/home";
import { useAnalytics } from "../../hooks/analyticsHook";

const Layout = styled.main`
  background: #800040;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 113px !important;
  background: #ffffff !important;
  padding-bottom: 80px;
  padding-left: 16px;
  padding-right: 16px;

  .container-search {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin: 32px 0 0 0;

    .result-text {
      position: absolute;
      bottom: -40px;
      font-weight: 600;
      font-size: 14px;
    }

    svg {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 12px;
      top: 7px;
    }

    span {
      position: absolute;
      right: 16px;
      top: 8px;
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        position: initial !important;
        width: 20px;
        height: 20px;
      }
    }

    > div {
      position: relative;
      max-width: 350px;
      width: 100%;

      @media (max-width: 500px) {
        max-width: 100%;
      }
    }
  }

  > h1 {
    font-size: 40px;
    margin-bottom: 0px;
    margin-top: 0;
  }

  @media (max-width: 500px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px !important;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  border-radius: 24px;
  border: 1px solid #800040;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  padding: 0 12px 0 42px;
  box-sizing: border-box;
  max-width: 400px;

  ::placeholder {
    font-size: 16px;
  }
`;

const NoSearch = styled.div`
  h1 {
    margin: 200px 0;
    text-align: center;
  }

  @media (max-width: 500px) {
    h1 {
      margin: 100px 0;
    }
  }
`;

const Shorts = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { track } = useAnalytics();
  const url = new URL(window.location.href);
  const param = url?.searchParams.get("r") || "";
  const q = url?.searchParams.get("q") || "";

  const [search, setSearch] = useState(shorts);
  const [word, setWord] = useState("");

  useEffect(() => {
    const elem = document.getElementById(`reflexion-${param}` || "");
    if (elem) {
      const rect = elem.getBoundingClientRect();
      const mobile = width <= 500 ? 130 : 200;
      const posY = rect.top + window.scrollY - mobile;
      window.scrollTo({
        top: posY,
        behavior: "smooth",
      });
    }
  }, [param, width]);

  const searchText = (text: string, word: string) => {
    const searchWord = word.split(" ");

    const result = searchWord.every((w) => {
      const expReg = new RegExp(`\\b${w.toLowerCase().normalize("NFD")}`, "i");
      return expReg.test(text);
    });

    return result;
  };

  const isMobile = width <= 500;
  const tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (!!width) {
      track(
        "Page Viewed",
        keysToSnake({
          name: "Shorts",
          timeZone: tz,
          mobile: isMobile,
          os: detectOS(),
        })
      );
    }
  }, [width]);

  useEffect(() => {
    if (q.length) {
      const res = shorts.filter((item) => {
        const joinText = `${item.text} ${item.title} ${item.author} ${item.date}`;
        return searchText(
          joinText
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          q
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
      });

      setSearch(res);
    } else {
      setWord("");
    }
  }, [q, word]);

  return (
    <Layout>
      <MainMessage />
      <Navbar />
      <LogoWrapper>
        <Logo
          src="https://res.cloudinary.com/dlcgloggr/image/upload/v1697739287/logo-19-10_nyh2m0.png"
          alt="logo"
          width={80}
          onClick={() => navigate("/")}
        />
      </LogoWrapper>
      <Container>
        <h1>Reflexiones</h1>
        <div className="container-search">
          <div>
            <SearchInput
              type="text"
              placeholder="Buscar..."
              onChange={(e) => {
                setWord(e.target.value);
                navigate(`/reflexiones?q=${e.target.value}`);
              }}
              value={word}
              defaultValue={""}
            />
            {RenderLogo("search-icon")}
            {word && (
              <span
                onClick={() => {
                  setWord("");
                  setSearch(shorts);
                  navigate(`/reflexiones?q=`);
                }}
              >
                {RenderLogo("close-icon")}
              </span>
            )}
          </div>
          {!!search.length && (
            <p className="result-text">
              {`${search.length}`} Refle{search.length > 1 ? "xiones" : "xión"}
            </p>
          )}
        </div>
        {!!search?.length ? (
          <Fragment>
            {search?.map((shortItem, index) => (
              <Short
                key={index}
                title={shortItem.title}
                text={shortItem.text}
                author={shortItem.author}
                date={shortItem.date}
                line={index < shorts?.length - 1}
                id={shortItem.id}
                linked={param === shortItem.id.toString()}
              />
            ))}
          </Fragment>
        ) : (
          <NoSearch>
            <h1>
              {!search.length ? "No hay" : `${search.length}`} Resultado
              {search.length > 1 ? "s" : !search.length ? "s" : ""}. Intenta
              nuevamente con otra palabra.
            </h1>
          </NoSearch>
        )}
      </Container>
      <Footer />
    </Layout>
  );
};

export default Shorts;
