export const musics = [
  /*  
 {
    id: "",
    date: "07/09/2024",
    seconds: 22,
    autoplay: 1,
    title: "",
    link: "",
  }, 
  */ {
    id: "FRUVz1ntIeQ",
    date: "10/09/2024",
    seconds: 41,
    autoplay: 1,
    title: "Sobre el Agua",
    link: "soag",
  },
  {
    id: "x4lM8MU3NZM",
    date: "06/09/2024",
    seconds: 100,
    autoplay: 0,
    title: "Están cayendo los muros",
    link: "eclm",
  },
  {
    id: "HRWUiwcD_Nw",
    date: "30/08/2024",
    seconds: 125,
    autoplay: 0,
    title: "Your Name is Holy",
    link: "ynih",
  },
  {
    id: "sNAW9vbJzEs",
    date: "19/08/2024",
    seconds: 480,
    autoplay: 0,
    title: "Santo Jesús",
    link: "saje",
  },
  {
    id: "CMSN-n9jh2U",
    date: "26/07/2024",
    seconds: 54,
    autoplay: 0,
    title: "Amamos Tanto tu Presencia",
    link: "attp",
  },
  {
    id: "rqvRSws82gk",
    date: "16/07/2024",
    seconds: 48,
    autoplay: 0,
    title: "Hermoso Nombre",
    link: "heno",
  },
  {
    id: "wJlyFOViq1Q",
    date: "28/06/2024",
    seconds: 101,
    autoplay: 0,
    title: "Lo que viene de ti",
    link: "lqvt",
  },
  {
    id: "6RZwH5xjn7w",
    date: "21/06/2024",
    seconds: 207,
    autoplay: 0,
    title: "Estoy convencido",
    link: "esco",
  },
  {
    id: "zUTHgUQthaA",
    date: "30/05/2024",
    seconds: 22,
    autoplay: 0,
    title: "Otra Vez",
    link: "otve",
  },
  {
    id: "6q-eds5bbLo",
    date: "15/05/2024",
    seconds: 137,
    autoplay: 0,
    title: "El Nombre",
    link: "elno",
  },
  {
    id: "ZFDOHMn01EE",
    date: "27/04/2024",
    seconds: 128,
    autoplay: 0,
    title: "Me Rindo a Ti",
    link: "mrat",
  },
  {
    id: "-gcg_6qw2Ug",
    date: "21/04/2024",
    seconds: 574,
    autoplay: 0,
    title: "Inmenso amor",
    link: "inam",
  },
  {
    id: "e1Amrb9MxBM",
    date: "12/04/2024",
    seconds: 36,
    autoplay: 0,
    title: "Tú Proveerás",
    link: "tupr",
  },
  {
    id: "bNeP0xgCuoc",
    date: "05/03/2024",
    seconds: 331,
    autoplay: 0,
    title: "Vive Hoy",
    link: "viho",
  },
  {
    id: "DNY9fl9drss",
    date: "29/03/2024",
    seconds: 51,
    autoplay: 0,
    title: "Cantaré",
    link: "cant",
  },
  {
    id: "ecAJaKVT2UQ",
    date: "24/03/2024",
    seconds: 11,
    autoplay: 0,
    title: "Deixa",
    link: "deix",
  },
  {
    id: "By1D67IzxI4",
    date: "12/03/2024",
    seconds: 105,
    autoplay: 0,
    title: "Socorro",
    link: "soco",
  },
  {
    id: "wrVIznHJvl8",
    date: "20/02/2024",
    seconds: 51,
    autoplay: 0,
    title: "Raridade",
    link: "rari",
  },
  {
    id: "AbMJX-x09cE",
    date: "15/02/2024",
    seconds: 89,
    autoplay: 0,
    title: "Tantas Historias",
    link: "this",
  },
  {
    id: "C1uMiacEESE",
    date: "08/02/2024",
    seconds: 138,
    autoplay: 0,
    title: "De Gloria En Gloria",
    link: "dgeg",
  },
  {
    id: "TQPhS3pYvek",
    date: "04/02/2024",
    seconds: 130,
    autoplay: 0,
    title: "Hosanna",
    link: "hossa",
  },
  {
    id: "raYythjsbBs",
    date: "22/01/2024",
    seconds: 93,
    autoplay: 0,
    title: "Aquí estoy",
    link: "aest",
  },
  {
    id: "dZh03iD3WpI",
    date: "19/01/2024",
    seconds: 6,
    autoplay: 0,
    title: "Yo me rindo a Él",
    link: "ymrae",
  },
  {
    id: "SOekjVOkUWQ",
    date: "17/01/2024",
    seconds: 16,
    autoplay: 0,
    title: "Renuévame",
    link: "renu",
  },
  {
    id: "aRYp-MW6Jmw",
    date: "16/01/2024",
    seconds: 114,
    autoplay: 0,
    title: "Gloria en lo Alto",
    link: "gela",
  },
  {
    id: "fy8Sp0gX30w",
    date: "11/01/2024",
    seconds: 210,
    autoplay: 0,
    title: "Te Anhelo",
    link: "tanh",
  },
  {
    id: "-zRnmtoSQoI",
    date: "09/01/2024",
    seconds: 125,
    autoplay: 0,
    title: "Gracias Dios",
    link: "gdio",
  },
  {
    id: "gfdbgHXm7ow",
    date: "07/01/2024",
    seconds: 71,
    autoplay: 0,
    title: "Gloria al Padre",
    link: "gapa",
  },
  {
    id: "y9PdOMjghfA",
    date: "02/01/2024",
    seconds: 235,
    autoplay: 0,
    title: "Ten Fe Corazón",
    link: "tfe",
  },
  {
    id: "ZKGkuWFOkxU",
    date: "29/12/2023",
    seconds: 227,
    autoplay: 0,
    title: "Reinas Para Siempre",
    link: "rpsi",
  },
  {
    id: "6gO9rCFJ1wk",
    date: "27/12/2023",
    seconds: 354,
    autoplay: 0,
    title: "Preciosa Sangre",
    link: "psan",
  },
  {
    id: "T3V6x4kD9XQ",
    date: "23/12/2023",
    seconds: 249,
    autoplay: 0,
    title: "Perfume a tus pies",
    link: "patp",
  },
  {
    id: "laerPZDrA7o",
    date: "18/12/2023",
    seconds: 120,
    autoplay: 0,
    title: "Vai ser tão lindo",
    link: "vstl",
  },
  {
    id: "-ONO3oI7G6I",
    date: "15/12/2023",
    seconds: 15,
    autoplay: 0,
    title: "Dios está aquí",
    link: "dea",
  },
  {
    id: "8AOMOM5dU-w",
    date: "12/12/2023",
    seconds: 88,
    autoplay: 0,
    title: "Soy nuevo",
    link: "snu",
  },
  {
    id: "N4Su58iT7UY",
    date: "11/12/2023",
    seconds: 94,
    autoplay: 0,
    title: "¿Quién me separará?",
    link: "qms",
  },
  {
    id: "EUGbmka_Ipo",
    date: "08/12/2023",
    seconds: 218,
    autoplay: 0,
    title: "Soy tu hijo",
    link: "sth",
  },
  {
    id: "m9BDXFKMOuU",
    date: "06/12/2023",
    seconds: 111,
    autoplay: 0,
    title: "Foi na Cruz",
    link: "fnc",
  },
  {
    id: "UfP0saUN3GE",
    date: "28/11/2023",
    seconds: 152,
    autoplay: 0,
    title: "Cristo me ha sanado",
    link: "cmhs",
  },
  {
    id: "Pn_uspdZcHM",
    date: "25/11/2023",
    seconds: 131,
    autoplay: 0,
    title: "En Lo Alto",
    link: "ela",
  },
  {
    id: "v_zcvFofipg",
    date: "23/11/2023",
    seconds: 253,
    autoplay: 0,
    title: "Santo Espíritu",
    link: "ses",
  },
  {
    id: "sMaEBiaMosM",
    date: "17/11/2023",
    seconds: 49,
    autoplay: 0,
    title: "Amarte Igual",
    link: "aig",
  },
  {
    id: "aC4M4SxJexg",
    date: "15/11/2023",
    seconds: 153,
    autoplay: 0,
    title: "Derramo el Perfume",
    link: "dep",
  },
  {
    id: "zT6ymWnzNf8",
    date: "12/11/2023",
    seconds: 293,
    autoplay: 0,
    title: "Tú Proveerás",
    link: "tpr",
  },
  {
    id: "rGr6TiKq_yI",
    date: "10/11/2023",
    seconds: 216,
    autoplay: 0,
    title: "Abba",
    link: "abb",
  },
  {
    id: "Z3KqXRIdAYk",
    date: "08/11/2023",
    seconds: 222,
    autoplay: 0,
    title: "Mi esperanza está en Jesús",
    link: "meeej",
  },
  {
    id: "KcuC7uLb-Sc",
    date: "07/11/2023",
    seconds: 240,
    autoplay: 0,
    title: "Primero nos amó",
    link: "pna",
  },
  {
    id: "ahdhO01ZD48",
    date: "02/11/2023",
    seconds: 330,
    autoplay: 0,
    title: "Cuan grande es Él",
    link: "cgee",
  },
  {
    id: "mq_1aTYFzqM",
    date: "02/11/2023",
    seconds: 583,
    autoplay: 0,
    title: "Mi padre me ama",
    link: "mpma",
  },
  {
    id: "SIJwBLz3XOg",
    date: "01/11/2023",
    seconds: 227,
    autoplay: 0,
    title: "Es por ti",
    link: "ept",
  },
  {
    id: "p4HDxqF9YhE",
    date: "01/11/2023",
    seconds: 244,
    autoplay: 0,
    title: "Ya no soy esclavo",
    link: "ynse",
  },
  {
    id: "27p0RV296i0",
    date: "30/10/2023",
    seconds: 375,
    autoplay: 0,
    title: "Digno de adorar",
    link: "dda",
  },
  {
    id: "kH5kHwKWBY4",
    date: "29/10/2023",
    seconds: 80,
    autoplay: 0,
    title: "En el camino",
    link: "eec",
  },
  {
    id: "X0KN0tYu5u0",
    date: "28/10/2023",
    seconds: 1400,
    autoplay: 0,
    title: "Quiero conocerte más",
    link: "qcm",
  },
  {
    id: "X0KN0tYu5u0",
    date: "25/10/2023",
    seconds: 964,
    autoplay: 0,
    title: "En el trono está",
    link: "eete",
  },
  {
    id: "ARjw5eP_aXs",
    date: "24/10/2023",
    seconds: 89,
    autoplay: 0,
    title: "La Paloma",
    link: "lp",
  },
  {
    id: "77ij-Xh8NCY",
    date: "23/10/2023",
    seconds: 53,
    autoplay: 0,
    title: "Tanto Amor",
    link: "ta",
  },
  {
    id: "SIJwBLz3XOg",
    date: "20/10/2023",
    seconds: 49,
    autoplay: 0,
    title: "Motivo de mi canción",
    link: "mdmc",
  },
  {
    id: "sJiRfztXcJc",
    date: "20/10/2023",
    seconds: 125,
    autoplay: 0,
    title: "Suelto",
    link: "sue",
  },
  {
    id: "OfiCmCCR0vg",
    date: "19/10/2023",
    seconds: 134,
    autoplay: 0,
    title: "Lléname",
    link: "lle",
  },
  {
    id: "c_fNwUIVt34",
    date: "18/10/2023",
    seconds: 156,
    autoplay: 0,
    title: "Un día a la vez",
    link: "udalv",
  },
  {
    id: "X0KN0tYu5u0",
    date: "17/10/2023",
    seconds: 1698,
    autoplay: 0,
    title: "Tú eres la imagen",
    link: "teli",
  },
  {
    id: "bL5ZdYpLcB0",
    date: "16/10/2023",
    seconds: 190,
    autoplay: 0,
    title: "Soy sano",
    link: "ss",
  },
  {
    id: "USnII6kyZdk",
    date: "15/10/2023",
    seconds: 251,
    autoplay: 0,
    title: "Yo no abandono",
    link: "yna",
  },
  {
    id: "q1EbBkGPf6s",
    date: "14/10/2023",
    seconds: 110,
    autoplay: 0,
    title: "La historia de un carpintero",
    link: "lhduc",
  },
  {
    id: "UPd-fIf9uwk",
    date: "13/10/2023",
    seconds: 30,
    autoplay: 0,
    title: "Dios de Israel",
    link: "ddi",
  },
  {
    id: "flVsCeTxnDo",
    date: "10/10/2023",
    seconds: 58,
    autoplay: 0,
    title: "Quitamancha",
    link: "qui",
  },
  {
    id: "HQ1q53S3gvc",
    date: "09/10/2023",
    seconds: 313,
    autoplay: 0,
    title: "Poderoso Dios",
    link: "pd",
  },
  {
    id: "IvXZ7AhVsSQ",
    date: "08/10/2023",
    seconds: 9,
    autoplay: 0,
    title: "El Cordero Y El León",
    link: "ecyel",
  },
  {
    id: "__w8OZju52A",
    date: "08/10/2023",
    seconds: 49,
    autoplay: 0,
    title: "Indudablemente",
    link: "in",
  },
  {
    id: "oLGRDk5lugA",
    date: "07/10/2023",
    seconds: 90,
    autoplay: 0,
    title: "Estar contigo",
    link: "ec",
  },
  {
    id: "w66bfbeKny0",
    date: "06/10/2023",
    seconds: 87,
    autoplay: 0,
    title: "Fiel",
    link: "fi",
  },
];
