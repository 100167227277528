import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../hooks/windowHook";
import { keysToSnake } from "../../helpers/humps";
import { detectOS } from "../../utils/home";
import { useAnalytics } from "../../hooks/analyticsHook";

const Container = styled.div<{ featured: boolean; linked: boolean }>`
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  background: ${({ linked }) => (linked ? "#D3D3D3" : "transparent")};
  padding: ${({ linked }) => (linked ? "8px" : "0")};

  > h1 {
    font-size: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  img {
    width: 40%;
    margin: 0 auto;
    margin-bottom: 32px;
  }

  p {
    max-width: 90%;
    margin: ${({ featured }) => (featured ? "0" : "0 auto")};
    margin-bottom: 16px;
  }

  span {
    max-width: 90%;
    font-weight: 600;
  }

  button {
    width: max-content;
    padding: 4px 8px;
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: #800040;
    color: #ffffff;
    font-weight: 700;
    margin-top: 24px;
    cursor: pointer;
    width: 100%;

    @media (min-width: 500px) {
      width: max-content;
      margin: ${({ featured }) => (featured ? "inherit" : "0 auto")};
      margin-top: 24px;
    }
  }

  @media (max-width: 500px) {
    img {
      width: 100%;
      margin-bottom: 16px;
    }

    p {
      max-width: 100%;
    }
  }

  @media (min-width: 500px) {
    span {
      width: 100%;
      margin: ${({ featured }) => (featured ? "0" : "0 auto")};
    }
  }

  @keyframes moveTr {
    0% {
      left: 0;
    }
    50% {
      left: 6px;
    }
    100% {
      left: 0;
    }
  }
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #800040;
  transform: rotate(90deg);
  margin-right: 10px;
  position: relative;
  animation: moveTr 2s linear infinite;
`;

const Header = styled.header`
  h1 {
    font-size: 48px;
    text-align: center;
    margin: 0;
    max-width: 1260px;
    margin: 0 auto;
    margin-bottom: 32px !important;

    @media (max-width: 500px) {
      font-size: 24px;
    }
  }
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background: #000000;
  margin: 32px 0 32px 0;
`;

const ModalClipBoard = styled.div`
  width: 150px;
  height: 40px;
  background: #008080;
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
`;

interface NewsProps {
  title?: string;
  text?: string;
  featured?: boolean;
  line?: boolean;
  author?: string;
  featuredTitle?: string;
  date?: string;
  id?: number;
  linked?: boolean;
}

const Short = ({
  title,
  text,
  featured = false,
  line,
  author,
  featuredTitle,
  date,
  id = 0,
  linked = false,
}: NewsProps) => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const url = new URL(window.location.href);
  const [isVisibleModalClipBoard, setIsVisibleModalClipBoard] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width <= 500;
  const tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  const copyToClipboard = () => {
    track(
      "Link Copied Short",
      keysToSnake({
        pathName: url?.pathname,
        timeZone: tz,
        mobile: isMobile,
        urlShort: `${url.origin}/reflexiones?r=${id}`,
        title,
        os: detectOS(),
      })
    );
    const textArea = document.createElement("textarea");
    textArea.value = `${url.origin}/reflexiones?r=${id}`;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setIsVisibleModalClipBoard(true);
    setTimeout(() => {
      setIsVisibleModalClipBoard(false);
    }, 2000);
  };

  const redirect = () => {
    navigate("/reflexiones");
  };

  return (
    <Fragment>
      <Container linked={linked} featured={featured} id={`reflexion-${id}`}>
        {featured && (
          <h1 onClick={redirect}>
            <Triangle />
            {featuredTitle}
          </h1>
        )}
        {title && (
          <Header>
            <h1>{title}</h1>
          </Header>
        )}
        <p>{text}</p>
        <span>
          Escrito por {author} {date ? `| ${date}` : ""}
        </span>
        <button onClick={copyToClipboard}>
          Copiar enlace a esta reflexión
        </button>
        {line && <Line />}
      </Container>
      {isVisibleModalClipBoard && (
        <ModalClipBoard>Enlace copiado</ModalClipBoard>
      )}
    </Fragment>
  );
};

export default Short;
