import React, { useEffect, useRef, useState } from "react";
import Navbar from "../navNar";
import styled, { keyframes } from "styled-components";
import MainMessage from "../mainMessage";
import Footer from "../footer";
import Media from "../media";
import { musics } from "../../utils/musicData";
import { useNavigate } from "react-router-dom";
import { Logo, LogoWrapper } from "../styles/shared";
import { useWindowSize } from "../../hooks/windowHook";
import { keysToSnake } from "../../helpers/humps";
import { detectOS } from "../../utils/home";
import { useAnalytics } from "../../hooks/analyticsHook";

const Layout = styled.main`
  background: #800040;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 113px !important;
  padding: 0 32px;
  background: #ffffff;
  padding-bottom: 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding-bottom: 80px !important;

  h1 {
    font-size: 40px;
    margin-bottom: 20px;
    margin-top: 0;
    grid-column: 1 / -1;
    text-align: center;
  }

  iframe {
    min-width: 1000px;
  }

  @media (max-width: 500px) {
    padding: 0 16px;
    padding-top: 20px !important;

    iframe {
      margin-top: 16px;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    align-items: center;

    .media {
      padding: 0;
      iframe {
        min-width: 100%;
        padding: 0;
      }
    }
  }

  @media (max-width: 1024px) {
    iframe {
      min-width: 600px;
    }
  }

  @media (min-width: 1000px) {
    .media {
      padding: 0;
    }
  }
`;

const LoadMore = styled.button`
  background: #ffffff;
  width: 100%;
  height: 60px;
  border-radius: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000000;
  margin: 80px 0;

  > span {
    color: #000000;
    font-weight: 700;
    font-size: 32px;
  }
`;

const ListMusic = styled.div`
  max-height: 500px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0.5px solid #000000;
  border-radius: 8px 0 0 8px;

  > button:first-child {
    border-radius: 8px 8px 0 0 !important;
  }

  > button:last-child {
    border-radius: 0 0 8px 8px;
    border-bottom: 0.5px solid #000000 !important;
  }

  @media (max-width: 768px) {
    max-height: 100%;
    border: none;
    ::-webkit-scrollbar {
      display: none !important;
    }
  }
`;

const ItemMusic = styled.button<{ current: boolean }>`
  font-size: 16px;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: ${({ current }) => (current ? "#ffffff" : "#000000")} !important;
  background: ${({ current }) => (current ? "blue" : "#ffffff")} !important;
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-bottom: none;
  border-left: ${({ current }) =>
    current ? "blue" : "0.5px solid #000000"} !important;
  border-right: ${({ current }) =>
    current ? "blue" : "0.5px solid #000000"} !important;
  border-top: ${({ current }) =>
    current ? "blue" : "0.5px solid #000000"} !important;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const moveNote = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0);
  }
`;

const MusicIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 4px;
`;

const Note = styled.div`
  width: 2px;
  height: 4px;
  background-color: #ffffff;
  border-radius: 50% 50% 0 0;
  position: absolute;
  top: 10px;
  animation: ${moveNote} 1s ease-in-out infinite;
`;

const SkeletonMusics = styled.div`
  width: 100%;
  background: #eeeeee;
  border-radius: 12px;
  height: 500px;

  @media (min-width: 1024px) {
    min-width: 600px;
  }

  @media (min-width: 1440px) {
    min-width: 1000px;
  }

  @media (max-width: 1024px) {
    height: 400px;
  }

  @media (max-width: 500px) {
    height: 300px;
  }
`;

const Note2 = styled(Note)`
  left: 6px;
  animation-delay: 0.2s;
`;

const Note3 = styled(Note)`
  left: 12px;
  animation-delay: 0.4s;
`;

const Music = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const [currentVideo, setCurrentVideo] = useState(musics[1]);
  const [isVisibleYT, setIsVisibleYT] = useState(true);
  const url = new URL(window.location.href);
  const param = url.searchParams.get("t");
  const { width } = useWindowSize();
  const isMobile = width <= 500;
  const tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (!!width) {
      track(
        "Page Viewed",
        keysToSnake({
          name: "Music",
          timeZone: tz,
          mobile: isMobile,
          os: detectOS(),
        })
      );
    }
  }, [width]);

  useEffect(() => {
    if (param) {
      const filter = musics?.filter((music) => music.link === param);
      setCurrentVideo(filter?.[0]);
    }
  }, [param]);

  return (
    <Layout>
      <MainMessage />
      <Navbar />
      <LogoWrapper>
        <Logo
          src="https://res.cloudinary.com/dlcgloggr/image/upload/v1697739287/logo-19-10_nyh2m0.png"
          alt="logo"
          width={80}
          onClick={() => navigate("/")}
        />
      </LogoWrapper>
      <Container>
        <h1>Música</h1>
        {!isVisibleYT ? (
          <SkeletonMusics />
        ) : (
          <Media
            id={currentVideo?.id}
            autoPlay={currentVideo?.autoplay}
            seconds={currentVideo?.seconds}
            currentVideo={currentVideo}
          />
        )}
        <ListMusic>
          {musics?.map((music) => (
            <ItemMusic
              onClick={() => {
                setIsVisibleYT(false);
                setTimeout(() => {
                  setIsVisibleYT(true);
                  setCurrentVideo(music);
                }, 1000);
                if (typeof window !== "undefined") {
                  window.scrollTo({
                    top: width <= 500 ? 150 : width <= 768 ? 20 : 0,
                    behavior: "smooth",
                  });
                }
              }}
              current={music?.title === currentVideo?.title}
            >
              {music?.title === currentVideo?.title && (
                <MusicIconWrapper>
                  <Note />
                  <Note2 />
                  <Note3 />
                </MusicIconWrapper>
              )}
              {music.title}
            </ItemMusic>
          ))}
        </ListMusic>
      </Container>
      <Footer />
    </Layout>
  );
};

export default Music;
