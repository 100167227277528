import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { DisplayOverlay } from "../styles/shared";
import { FaBars, FaHome } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const NavbarContainer = styled.nav`
  background-color: #800040;
  color: #ffffff;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ffffff;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1000;

  @media (max-width: 500px) {
    bottom: 0;
    border-top: none;
    border-bottom: none;
  }
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 57%;

  li {
    display: none;
  }

  @media (min-width: 768px) {
    li {
      display: block;
    }
  }
  @media (max-width: 500px) {
    max-width: 100%;
    height: 100%;
  }
`;

const NavItem = styled.li<{ current: boolean }>`
  margin-right: 16px;

  > a {
    text-decoration: ${({ current }) => (current ? "underline" : "none")};
    font-weight: ${({ current }) => (current ? "700" : "600")};
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: white;
  transition: font-size 0.3s;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: #f0f0f0;
    font-size: 20px;
  }
`;

const MobileMenuButton = styled.div`
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  display: none;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0 16px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > span {
      font-size: 10px;
    }
  }

  img {
    cursor: pointer;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 16px;
    display: none;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const MobileMenu = styled.div<{ isOpen: boolean }>`
  display: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #800040;
    background: #ffffff;
    border-bottom: 1px solid #800040;
    font-weight: 600;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
    background-color: #000000;
    position: absolute;
    bottom: 80px;
    left: 0;
    width: 100%;
  }
`;

const MobileItem = styled.div<{ current: boolean }>`
  background: ${({ current }) => (current ? "#800040" : "#ffffff")} !important;
  color: ${({ current }) => (current ? "#ffffff" : "#800040")} !important;
`;

const IconHome = styled.div`
  position: fixed;
  bottom: 4px;
  right: 16px;
  background-color: #800040;
  padding: 10px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: none;

  @media (max-width: 500px) {
    display: block;
  }
`;

const Logo = styled.img`
  position: fixed;
  left: 32px;
  display: block;

  @media (max-width: 500px) {
    display: none;
  }
`;

const Navbar = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const redirect = (route: string) => {
    navigate(route);
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsMobileMenuOpen(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <NavbarContainer>
        <NavList>
          <MobileMenuButton onClick={toggleMobileMenu}>
            <div>
              <FaBars size={36} color="white" />
              <span>MENÚ</span>
            </div>
            <img
              src="https://res.cloudinary.com/dlcgloggr/image/upload/v1697718284/logo-2_pg7kuz.png"
              alt="logo"
              width={80}
              onClick={() => navigate("/")}
            />
          </MobileMenuButton>
          <Logo
            src="https://res.cloudinary.com/dlcgloggr/image/upload/v1697740668/logo-19-10-white_jyxkff.png"
            alt="logo"
            width={80}
            onClick={() => navigate("/")}
          />
          <NavItem current={location?.pathname === "/"}>
            <NavLink href="/">Incio</NavLink>
          </NavItem>
          <NavItem current={location?.pathname === "/creencias"}>
            <NavLink href="/creencias">¿En qué creo?</NavLink>
          </NavItem>
          <NavItem current={location?.pathname === "/musica"}>
            <NavLink href="/musica">Música</NavLink>
          </NavItem>
          <NavItem current={location?.pathname === "/reflexiones"}>
            <NavLink href="/reflexiones">Reflexiones</NavLink>
          </NavItem>
          <NavItem current={location?.pathname === "/noticias"}>
            <NavLink href="/noticias">Noticias</NavLink>
          </NavItem>
          <NavItem current={location?.pathname === "/biblia"}>
            <NavLink href="/biblia">Biblia</NavLink>
          </NavItem>
          <NavItem current={location?.pathname === "/contacto"}>
            <NavLink href="/contacto">Contacto</NavLink>
          </NavItem>
        </NavList>
        <MobileMenu isOpen={isMobileMenuOpen}>
          <MobileItem
            current={location?.pathname === "/"}
            onClick={() => redirect("/")}
          >
            Inicio
          </MobileItem>
          <MobileItem
            current={location?.pathname === "/creencias"}
            onClick={() => redirect("/creencias")}
          >
            ¿En qué creo?
          </MobileItem>
          <MobileItem
            current={location?.pathname === "/musica"}
            onClick={() => redirect("/musica")}
          >
            Música
          </MobileItem>
          <MobileItem
            current={location?.pathname === "/reflexiones"}
            onClick={() => redirect("/reflexiones")}
          >
            Reflexiones
          </MobileItem>
          <MobileItem
            current={location?.pathname === "/biblia"}
            onClick={() => redirect("/biblia")}
          >
            Biblia
          </MobileItem>
          <MobileItem
            current={location?.pathname === "/noticias"}
            onClick={() => redirect("/noticias")}
          >
            Noticias
          </MobileItem>
          <MobileItem
            current={location?.pathname === "/contacto"}
            onClick={() => redirect("/contacto")}
          >
            Contacto
          </MobileItem>
        </MobileMenu>
        {location?.pathname !== "/" && (
          <IconHome
            onClick={() => {
              navigate("/");
            }}
          >
            <FaHome size={50} color="white" />
          </IconHome>
        )}
      </NavbarContainer>
      {isMobileMenuOpen && (
        <DisplayOverlay
          className="overlay"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          background="#000000"
          opacity="0.3"
        />
      )}
    </>
  );
};

export default Navbar;
