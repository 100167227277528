import React, { useEffect, useState } from "react";
import Navbar from "../navNar";
import styled from "styled-components";
import MainMessage from "../mainMessage";
import Footer from "../footer";
import { sendEmail as Email } from "../../aws-config";
import GoogleMap from "../googleMap";
import { useNavigate } from "react-router-dom";
import { Logo, LogoWrapper } from "../styles/shared";
import { keysToSnake } from "../../helpers/humps";
import { useWindowSize } from "../../hooks/windowHook";
import { detectOS } from "../../utils/home";
import { useAnalytics } from "../../hooks/analyticsHook";

const Layout = styled.main`
  background: #800040;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 113px !important;
  padding-left: 32px;
  padding-right: 32px;
  background: #ffffff;
  padding-bottom: 80px;

  h1 {
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 40px;
    grid-column: 1 / -1;
  }

  .google-map {
    height: 500px !important;
    margin-bottom: 48px;
  }

  @media (max-width: 500px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px !important;
  }

  @media (max-width: 768px) {
    .google-map {
      height: 500px !important;
      order: 1;
    }
  }

  @media (min-width: 1440px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  height: 460px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 48px;

  @media (max-width: 500px) {
    max-width: 100%;
    width: auto;
  }

  @media (max-width: 768px) {
    max-width: 700px;
    width: auto;
    order: 0;
  }

  @media (min-width: 769px) {
    max-width: inherit;
    width: inherit;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const FormLabel = styled.label`
  font-weight: bold;
`;

const FormInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
`;

const FormTextarea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 100px;
  resize: none;
`;

const FormButton = styled.button<{ messageSent: boolean }>`
  background-color: ${({ messageSent }) =>
    messageSent ? "#12a14b" : "#000000"};
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  margin-top: 16px;
  outline: none;

  :focus {
    outline: none;
  }
`;

const Contact = () => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = (e: any) => {
    e.preventDefault();
    track(
      "Email Contact",
      keysToSnake({
        name,
        subject,
        email,
        comment,
        timeZone: tz,
        mobile: isMobile,
        os: detectOS(),
      })
    );

    const params: any = {
      Source: `leandroemanuelpaez2010@gmail.com`,
      Destination: {
        ToAddresses: ["leandroemanuelpaez2010@gmail.com"],
      },
      Message: {
        Subject: { Data: subject },
        Body: {
          Html: {
            Data: `Nombre: ${name} - - - Email: ${email} - - - Comentario: ${comment}`,
          },
        },
      },
    };
    Email(params);
    setMessageSent(true);
    setName("");
    setSubject("");
    setEmail("");
    setComment("");
    setTimeout(() => {
      setMessageSent(false);
    }, 3000);
  };

  const isMobile = width <= 500;
  const tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (!!width) {
      track(
        "Page Viewed",
        keysToSnake({
          name: "Contact",
          timeZone: tz,
          mobile: isMobile,
        })
      );
    }
  }, [width]);

  return (
    <Layout>
      <MainMessage />
      <Navbar />
      <LogoWrapper>
        <Logo
          src="https://res.cloudinary.com/dlcgloggr/image/upload/v1697739287/logo-19-10_nyh2m0.png"
          alt="logo"
          width={80}
          onClick={() => navigate("/")}
        />
      </LogoWrapper>
      <Container>
        <h1>Contacto</h1>
        <GoogleMap />
        <FormWrapper>
          <Form onSubmit={sendEmail}>
            <FormLabel htmlFor="name">Nombre:</FormLabel>
            <FormInput
              type="text"
              id="name"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              required
            />
            <FormLabel htmlFor="subject">Asunto:</FormLabel>
            <FormInput
              type="text"
              id="subject"
              value={subject}
              onChange={(e: any) => setSubject(e.target.value)}
              required
            />
            <FormLabel htmlFor="email">Email</FormLabel>
            <FormInput
              type="email"
              id="email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              required
            />
            <FormLabel htmlFor="comment">Comentario:</FormLabel>
            <FormTextarea
              id="comment"
              value={comment}
              onChange={(e: any) => setComment(e.target.value)}
              required
            />
            <FormButton type="submit" messageSent={messageSent}>
              {messageSent ? "Tu mensaje se ha enviado" : "Enviar Comentario"}
            </FormButton>
          </Form>
        </FormWrapper>
      </Container>
      <Footer />
    </Layout>
  );
};

export default Contact;
