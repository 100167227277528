import AWS from "aws-sdk";

// Set the AWS region
AWS.config.update({
  region: "us-east-2", // Cambia esto a la región de tu elección
  accessKeyId: "AKIAXY4N57OKKAEUMF5L",
  secretAccessKey: "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",
});

const ses = new AWS.SES({ apiVersion: "2010-12-01" });

interface EmailParams {
  Source: string;
  Destination: {
    ToAddresses: string[];
  };
  Message: {
    Subject: { Data: string };
    Body: { Html: { Data: string } };
  };
}

const sendEmail = async (params: EmailParams): Promise<void> => {
  return new Promise((resolve, reject) => {
    ses.sendEmail(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve();
      }
    });
  });
};

export { sendEmail };
