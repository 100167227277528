import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div<{ featured: boolean }>`
  margin-top: 50px;
  width: 100%;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: ${({ featured }) =>
    featured ? "repeat(2, 1fr)" : "repeat(1, 1fr)"};
  gap: 8px;

  > h1 {
    font-size: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    grid-column: 1 / -1;
  }

  img {
    width: ${({ featured }) => (featured ? "100%" : "40%")};
    margin: 0 auto;
    grid-column: ${({ featured }) => (featured ? "inherit" : "1 / -1")};
    margin-bottom: ${({ featured }) => (featured ? "0" : "32px")};
  }

  p {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-size: ${({ featured }) => (featured ? "22px" : "20px")};
    grid-column: 1 / -1;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);

    img {
      width: 100%;
      margin-bottom: 16px;
      grid-column: ${({ featured }) => (featured ? "inherit" : "1 / -1")};
    }

    p {
      max-width: 100%;
      font-size: 14px;
    }
  }

  @media (min-width: 769px) {
    gap: 16px;

    p {
      max-width: 95%;
      font-size: 18px;
      grid-column: inherit !important;
    }
  }

  @keyframes moveTr {
    0% {
      left: 0;
    }
    50% {
      left: 6px;
    }
    100% {
      left: 0;
    }
  }
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #800040;
  transform: rotate(90deg);
  margin-right: 10px;
  position: relative;
  animation: moveTr 2s linear infinite;
`;

const Header = styled.header`
  grid-column: 1 / -1;
  h1 {
    font-size: 48px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 32px;
    max-width: 90%;

    @media (max-width: 500px) {
      font-size: 24px;
    }
  }
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background: #000000;
  margin: 32px 0 32px 0;
  grid-column: 1 / -1;
`;

interface NewsProps {
  title?: string;
  srcImg?: any;
  text?: string;
  featured?: boolean;
  line?: boolean;
  featuredTitle?: string;
}

const New = ({
  title,
  srcImg,
  text,
  featured = false,
  line,
  featuredTitle,
}: NewsProps) => {
  const navigate = useNavigate();

  const redirect = () => {
    navigate("/noticias");
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);
  return (
    <Container featured={featured}>
      {featured && (
        <h1 onClick={redirect}>
          <Triangle />
          {featuredTitle}
        </h1>
      )}
      {title && (
        <Header>
          <h1>{title}</h1>
        </Header>
      )}
      <img src={srcImg} alt="" />
      <p>{text}</p>
      {line && <Line />}
    </Container>
  );
};

export default New;
