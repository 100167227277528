export const beliefsData = [
  {
    title: "Acerca de Dios",
    description:
      "Dios es el creador y regidor del Universo. Ha existido eternamente en tres personas: el Padre, el Hijo y el Espíritu Santo. Estos tres son iguales y un solo Dios.",
    verses: [
      "Génesis 1:1",
      "Génesis 1:26-27",
      "Génesis 3:22",
      "Salmo 90:2",
      "Mateo 28:19",
      "1 Pedro 1:2",
      "2 Corintios 13:14",
    ],
  },
  {
    title: "Acerca de Jesucristo",
    description:
      "Jesucristo es el Hijo de Dios, es co-igual con el Padre. Jesucristo vivió una vida humana sin pecado, y se ofreció a sí mismo como el sacrificio perfecto por los pecados del mundo al morir en la cruz. Resucitó de entre los muertos al tercer día, para demostrar su poder sobre el pecado y la muerte. Ascendió al cielo y volverá otra vez a la tierra para reinar como Rey de Reyes y Señor de Señores.",
    verses: [
      "Mateo 1:22-23",
      "Isaías 9:6",
      "Juan 1:1-5",
      "Juan 14:10-30",
      "Hebreos 4:14-15",
      "1 Corintios 15:3-4",
      "Romanos 1:3-4",
      "Hechos 1:9-11",
      "1 Timoteo 6:14-15",
      "Tito 2:13",
    ],
  },
  {
    title: "Acerca del Espíritu Santo",
    description:
      "El Espíritu Santo es co-igual con el Padre y el Hijo de Dios. Está presente en el mundo para hacer conscientes a los hombres de su necesidad de Cristo Jesús. Vive en cada cristiano desde el momento de la salvación. Provee al cristiano de entendimiento para vivir, entendimiento de las verdades espirituales y dirección hacia lo que es correcto. Da a cada creyente un don espiritual al momento de su salvación. Como cristianos, debemos vivir bajo su control diariamente.",
    verses: [
      "2 Corintios 3:17",
      "Juan 16:7-13",
      "Juan 14:16,17",
      "Hechos 1:8 ",
      "1 Corintios 2:12,3:16",
      "Efesios 1:13",
      "Gálatas 5:25",
      "Efesios 5:18",
    ],
  },
  {
    title: "Acerca de la Biblia",
    description:
      "La Biblia es la Palabra de Dios para nosotros, fue escrita por autores humanos bajo la inspiración del Espíritu Santo. Es el recurso supremo de verdad para la creencia y vida cristiana.",
    verses: [
      "2 Timoteo 3:16",
      "2 Pedro 1:20,21",
      "2 Timoteo 1:13",
      "Salmo 119:105,160,12:6",
      "Proverbios 30:5",
    ],
  },
  {
    title: "Acerca del hombre",
    description:
      "El hombre está hecho a la imagen de Dios para ser como Él en carácter. El hombre es el objeto supremo de la creación de Dios. Aunque el hombre tiene tremendo potencial para el bien, está manchado por la tendencia de desobedecer a Dios. Esta desobediencia, se llama “pecado”. El pecado separa a las personas de Dios y causa muchos problemas en la vida.",
    verses: [
      "Génesis 1:17",
      "Salmo 8:3-6",
      "Isaías 53:6",
      "Romanos 3:23",
      "Isaías 59:1-2",
    ],
  },
  {
    title: "Acerca de la Salvación",
    description:
      "La Salvación es el regalo de Dios para nosotros, pero es necesario aceptarlo. No podemos compensar nuestros pecados por medio de las buenas obras o mejoramiento personal. Sólo al confiar en que Jesucristo es el regalo de Dios para el perdón de nuestros pecados, podemos ser salvos de la penalidad del pecado. Cuando nos volvemos de una vida auto controlada, a una vida controlada por Cristo, somos salvos. La vida eterna empieza en el momento en que uno recibe a Cristo como su Salvador y Señor de su vida.",
    verses: [
      "Romanos 6:23",
      "Efesios 2:8-9",
      "Juan 14:6;1:12",
      "Tito 3:5",
      "Gálatas 3:26",
      "Romanos 5:1",
    ],
  },
  {
    title: "Acerca de la segunda venida de Cristo",
    description:
      "El Señor descenderá del Cielo tal como se fue. Los muertos en Cristo resucitarán y los vivos serán transformados y llevados al cielo",
    verses: ["1 Tesalonicenses 4:16-17", "1 Corintios 15:51-52"],
  },
  {
    title: "Acerca de la Resurrección",
    description:
      "Creo en la resurrección corporal de todos los muertos, justos e injustos, los injustos para perdición eterna y los justos a vida eterna.",
    verses: ["Hechos 24:15", "Mateo 25:46"],
  },
  {
    title: "Acerca de la eternidad",
    description:
      "Las personas fueron creadas para existir por siempre. Existimos separados de Dios por el pecado o unidos eternamente en Dios por su Perdón y Salvación. La separación eterna de Dios es el infierno. El vivir en unión eterna con Él es la vida eterna. El cielo y el infierno son lugares reales para una existencia eterna.",
    verses: [
      "Juan 3:16",
      "Juan 2:25",
      "Juan 5:11-13",
      "Romanos 6:23",
      "Apocalipsis 20:15",
      "Mateo 25:44-46",
    ],
  },
];
