import React from "react";
import styled from "styled-components";
import { FaWhatsapp } from "react-icons/fa";

const Button = styled.a`
  z-index: 100000;
  position: fixed;
  bottom: 16px;
  right: 16px;
  background-color: green;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  :focus {
    outline: none;
  }

  @media (max-width: 500px) {
    bottom: 11px;
    display: none;
  }
`;

const WhatsAppLink = () => {
  const phoneNumber = "1234567890";

  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}`;

  return (
    <Button href={whatsappUrl} target="_blank" rel="noopener noreferrer">
      <FaWhatsapp size={36} color="white" />
    </Button>
  );
};

export default WhatsAppLink;
