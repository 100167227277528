import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { sendEmail as Email } from "../../aws-config";
import { DisplayOverlay } from "../styles/shared";
import { keysToSnake } from "../../helpers/humps";
import { useWindowSize } from "../../hooks/windowHook";
import { detectOS } from "../../utils/home";
import { useAnalytics } from "../../hooks/analyticsHook";

const ModalSuggestionContainer = styled.div<{ messageThanks: boolean }>`
  width: 90%;
  max-width: ${({ messageThanks }) =>
    messageThanks ? "400px" : "600px"} !important;
  height: min-content;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  margin: auto;
  border-radius: 16px;
  background: #ffffff;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 1);
  z-index: 10001;

  @media (min-width: 768px) {
    padding: 24px;
  }

  h1 {
    font-size: 20px;
    margin-top: 40px;
    color: #800040;

    @media (min-width: 768px) {
      font-size: 28px;
    }
  }

  p {
    font-size: 14px;
    text-align: center;
    color: #800040;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`;

const ButtonContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: auto;

  > button {
    border: none;
    border-radius: 16px;
    padding: 10px;
    color: #ffffff;
    width: 100%;
    background: transparent;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
  }

  > button:nth-child(1) {
    border: none;
    border-radius: 16px;
    padding: 10px;
    background: #800040;
    cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")} !important;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  > button:nth-child(2) {
    border: none;
    border-radius: 16px;
    padding: 10px;
    color: #800040;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`;

const Close = styled.div`
  width: 28px;
  height: 28px;
  font-size: 14px;
  border-radius: 1000px;
  background: #800040;
  color: #ffffff;
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }
`;

const MessageThanks = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  h1 {
    font-size: 20px;
    color: #800040;
    margin: 0 !important;
    text-align: center;
  }
`;

const FormTextarea = styled.textarea`
  padding: 10px;
  border-radius: 3px;
  height: 132px;
  resize: none;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  font-weight: 600;
  border-radius: 10px;
  margin: 10px 0 32px 0;
  color: #800040;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

interface ModalSuggestionProps {
  onClose: () => void;
}

const ModalSuggestion = ({ onClose }: ModalSuggestionProps) => {
  const { track } = useAnalytics();
  const [comment, setComment] = useState("");
  const [messageThanks, setMessageThanks] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width <= 500;
  const tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  const sendEmail = (e: any) => {
    e.preventDefault();
    const params: any = {
      Source: `leandroemanuelpaez2010@gmail.com`,
      Destination: {
        ToAddresses: ["leandroemanuelpaez2010@gmail.com"],
      },
      Message: {
        Subject: { Data: "Suggestion" },
        Body: {
          Html: {
            Data: `Nombre: ${"Suggestion"} - - - Email: ${"leandroemanuelpaez2010@hotmail.com"} - - - Comentario: ${comment}`,
          },
        },
      },
    };
    track(
      "Suggestion",
      keysToSnake({
        name: "Suggestion",
        comment,
        timeZone: tz,
        mobile: isMobile,
        os: detectOS(),
      })
    );
    Email(params);
    setComment("");
    setMessageThanks(true);
    setTimeout(() => {
      setMessageThanks(false);
      onClose();
    }, 3500);
  };

  return (
    <>
      <ModalSuggestionContainer messageThanks={messageThanks}>
        {messageThanks ? (
          <MessageThanks>
            <h1>
              ¡Gracias por tu sugerencia! <br />
              La tendremos en cuenta.
            </h1>
          </MessageThanks>
        ) : (
          <>
            <h1>¿Tienes alguna sugerencia?</h1>
            <p>
              ¡Nos encantaría mejorar con tu ayuda!
              <br />
              Si tienes alguna idea, compártela con nosotros
            </p>
            <FormTextarea
              id="comment"
              value={comment}
              onChange={(e: any) => setComment(e.target.value)}
              required
            />
            <ButtonContainer disabled={!comment.length}>
              <button onClick={sendEmail} disabled={!comment.length}>
                Enviar sugerencia
              </button>
              <button
                onClick={() => {
                  setMessageThanks(false);
                  onClose();
                }}
              >
                Quizás más tarde
              </button>
            </ButtonContainer>
            <Close
              onClick={() => {
                setMessageThanks(false);
                onClose();
              }}
            >
              X
            </Close>{" "}
          </>
        )}
      </ModalSuggestionContainer>
      <DisplayOverlay
        className="overlay"
        onClick={() => {
          setMessageThanks(false);
          onClose();
        }}
        background="#000000"
        opacity="0.3"
        index={10000}
      />
    </>
  );
};

export default ModalSuggestion;
