export const verses = [
  "Lámpara es a mis pies tu palabra, y lumbrera a mi camino. (Salmos 119:105)",
  "Porque yo sé los pensamientos que tengo acerca de vosotros, dice Jehová, pensamientos de paz, y no de mal, para daros el fin que esperáis. (Jeremías 29:11)",
  "Y sabemos que a los que aman a Dios, todas las cosas les ayudan a bien.(Romanos 8:28)",
  "Toda la Escritura es inspirada por Dios, y útil para enseñar, para redargüir, para corregir, para instruir en justicia.(2 Timoteo 3:16)",
  "Fíate de Jehová de todo tu corazón, y no te apoyes en tu propia prudencia. Reconócele en todos tus caminos, y él enderezará tus veredas. (Proverbios 3:5-6)",
  "Venid a mí todos los que estáis trabajados y cargados, y yo os haré descansar. (Mateo 11:28)",
  "Jesús dijo: Yo soy la resurrección y la vida; el que cree en mí, aunque muera, vivirá.",
  "Jesús dijo: Yo soy el camino, y la verdad, y la vida; nadie viene al Padre, sino por mí.",
  "Porque tanto amó Dios al mundo que dio a su Hijo unigénito, para que todo el que cree en él no se pierda, sino que tenga vida eterna (Juan 3:16)",
  "Ya te lo he ordenado: ¡Sé fuerte y valiente! ¡No tengas miedo ni te desanimes! Porque el Señor tu Dios te acompañará dondequiera que vayas.(Josué 1:9)",
  "Porque por gracia ustedes han sido salvados mediante la fe; esto no procede de ustedes, sino que es el regalo de Dios, no por obras, para que nadie se jacte.(Efesios 2:8-9)",
  "El ladrón no viene más que a robar, matar y destruir; yo he venido para que tengan vida, y la tengan en abundancia.(Juan 10:10)",
];

export const numberVersesBook = [
  {
    _id: "5d9b56c784b1296fcb80c1c6",
    id: "spa-RVR1960:Ruth",
    name: "Rut",
    order: 8,
    testament: "OT",
    verses: 4,
  },
  {
    _id: "5d9b56c784b1296fcb80c1bf",
    id: "spa-RVR1960:Gen",
    name: "Génesis",
    order: 1,
    testament: "OT",
    verses: 50,
  },
  {
    _id: "5d9b56c784b1296fcb80c1c7",
    id: "spa-RVR1960:1Sam",
    name: "1 Samuel",
    order: 9,
    testament: "OT",
    verses: 31,
  },
  {
    _id: "5d9b56c784b1296fcb80c1cf",
    id: "spa-RVR1960:Esth",
    name: "Ester",
    order: 17,
    testament: "OT",
    verses: 10,
  },
  {
    _id: "5d9b56c784b1296fcb80c1d3",
    id: "spa-RVR1960:Eccl",
    name: "Eclesiastés",
    order: 21,
    testament: "OT",
    verses: 12,
  },
  {
    _id: "5d9b56c784b1296fcb80c1f0",
    id: "spa-RVR1960:Phil",
    name: "Filipenses",
    order: 50,
    testament: "NT",
    verses: 4,
  },
  {
    _id: "5d9b56c784b1296fcb80c1f7",
    id: "spa-RVR1960:Phlm",
    name: "Filemón",
    order: 57,
    testament: "NT",
    verses: 1,
  },
  {
    _id: "5d9b56c784b1296fcb80c1c4",
    id: "spa-RVR1960:Josh",
    name: "Josué",
    order: 6,
    testament: "OT",
    verses: 24,
  },
  {
    _id: "5d9b56c784b1296fcb80c1d7",
    id: "spa-RVR1960:Lam",
    name: "Lamentaciones",
    order: 25,
    testament: "OT",
    verses: 5,
  },
  {
    _id: "5d9b56c784b1296fcb80c1cd",
    id: "spa-RVR1960:Ezra",
    name: "Esdras",
    order: 15,
    testament: "OT",
    verses: 10,
  },
  {
    _id: "5d9b56c784b1296fcb80c1d2",
    id: "spa-RVR1960:Prov",
    name: "Proverbios",
    order: 20,
    testament: "OT",
    verses: 31,
  },
  {
    _id: "5d9b56c784b1296fcb80c1da",
    id: "spa-RVR1960:Hos",
    name: "Oseas",
    order: 28,
    testament: "OT",
    verses: 14,
  },
  {
    _id: "5d9b56c784b1296fcb80c1e4",
    id: "spa-RVR1960:Zech",
    name: "Zacarías",
    order: 38,
    testament: "OT",
    verses: 14,
  },
  {
    _id: "5d9b56c784b1296fcb80c1ed",
    id: "spa-RVR1960:2Cor",
    name: "2 Corintios",
    order: 47,
    testament: "NT",
    verses: 13,
  },
  {
    _id: "5d9b56c784b1296fcb80c1fd",
    id: "spa-RVR1960:2John",
    name: "2 Juan",
    order: 63,
    testament: "NT",
    verses: 1,
  },
  {
    _id: "5d9b56c784b1296fcb80c1cc",
    id: "spa-RVR1960:2Chr",
    name: "2 Crónicas",
    order: 14,
    testament: "OT",
    verses: 36,
  },
  {
    _id: "5d9b56c784b1296fcb80c1c3",
    id: "spa-RVR1960:Deut",
    name: "Deuteronomio",
    order: 5,
    testament: "OT",
    verses: 34,
  },
  {
    _id: "5d9b56c784b1296fcb80c1d0",
    id: "spa-RVR1960:Job",
    name: "Job",
    order: 18,
    testament: "OT",
    verses: 42,
  },
  {
    _id: "5d9b56c784b1296fcb80c1e2",
    id: "spa-RVR1960:Zeph",
    name: "Sofonías",
    order: 36,
    testament: "OT",
    verses: 3,
  },
  {
    _id: "5d9b56c784b1296fcb80c1f3",
    id: "spa-RVR1960:2Thess",
    name: "2 Tesalonicenses",
    order: 53,
    testament: "NT",
    verses: 3,
  },
  {
    _id: "5d9b56c784b1296fcb80c1c1",
    id: "spa-RVR1960:Lev",
    name: "Levítico",
    order: 3,
    testament: "OT",
    verses: 27,
  },
  {
    _id: "5d9b56c784b1296fcb80c1de",
    id: "spa-RVR1960:Jonah",
    name: "Jonás",
    order: 32,
    testament: "OT",
    verses: 4,
  },
  {
    _id: "5d9b56c784b1296fcb80c1c5",
    id: "spa-RVR1960:Judg",
    name: "Jueces",
    order: 7,
    testament: "OT",
    verses: 21,
  },
  {
    _id: "5d9b56c784b1296fcb80c1d8",
    id: "spa-RVR1960:Ezek",
    name: "Ezequiel",
    order: 26,
    testament: "OT",
    verses: 48,
  },
  {
    _id: "5d9b56c784b1296fcb80c1dc",
    id: "spa-RVR1960:Amos",
    name: "Amós",
    order: 30,
    testament: "OT",
    verses: 9,
  },
  {
    _id: "5d9b56c784b1296fcb80c1e3",
    id: "spa-RVR1960:Hag",
    name: "Hageo",
    order: 37,
    testament: "OT",
    verses: 2,
  },
  {
    _id: "5d9b56c784b1296fcb80c1fb",
    id: "spa-RVR1960:2Pet",
    name: "2 Pedro",
    order: 61,
    testament: "NT",
    verses: 3,
  },
  {
    _id: "5d9b56c784b1296fcb80c1d6",
    id: "spa-RVR1960:Jer",
    name: "Jeremías",
    order: 24,
    testament: "OT",
    verses: 52,
  },
  {
    _id: "5d9b56c784b1296fcb80c1d9",
    id: "spa-RVR1960:Dan",
    name: "Daniel",
    order: 27,
    testament: "OT",
    verses: 12,
  },
  {
    _id: "5d9b56c784b1296fcb80c1df",
    id: "spa-RVR1960:Mic",
    name: "Miqueas",
    order: 33,
    testament: "OT",
    verses: 7,
  },
  {
    _id: "5d9b56c784b1296fcb80c1e0",
    id: "spa-RVR1960:Nah",
    name: "Nahúm",
    order: 34,
    testament: "OT",
    verses: 3,
  },
  {
    _id: "5d9b56c784b1296fcb80c1e1",
    id: "spa-RVR1960:Hab",
    name: "Habacuc",
    order: 35,
    testament: "OT",
    verses: 3,
  },
  {
    _id: "5d9b56c784b1296fcb80c1eb",
    id: "spa-RVR1960:Rom",
    name: "Romanos",
    order: 45,
    testament: "NT",
    verses: 16,
  },
  {
    _id: "5d9b56c784b1296fcb80c1ca",
    id: "spa-RVR1960:2Kgs",
    name: "2 Reyes",
    order: 12,
    testament: "OT",
    verses: 25,
  },
  {
    _id: "5d9b56c784b1296fcb80c1d4",
    id: "spa-RVR1960:Song",
    name: "Cantares",
    order: 22,
    testament: "OT",
    verses: 8,
  },
  {
    _id: "5d9b56c784b1296fcb80c1f6",
    id: "spa-RVR1960:Titus",
    name: "Tito",
    order: 56,
    testament: "NT",
    verses: 3,
  },
  {
    _id: "5d9b56c784b1296fcb80c1f8",
    id: "spa-RVR1960:Heb",
    name: "Hebreos",
    order: 58,
    testament: "NT",
    verses: 13,
  },
  {
    _id: "5d9b56c784b1296fcb80c1cb",
    id: "spa-RVR1960:1Chr",
    name: "1 Crónicas",
    order: 13,
    testament: "OT",
    verses: 29,
  },
  {
    _id: "5d9b56c784b1296fcb80c1e6",
    id: "spa-RVR1960:Matt",
    name: "S. Mateo",
    order: 40,
    testament: "NT",
    verses: 28,
  },
  {
    _id: "5d9b56c784b1296fcb80c1ec",
    id: "spa-RVR1960:1Cor",
    name: "1 Corintios",
    order: 46,
    testament: "NT",
    verses: 16,
  },
  {
    _id: "5d9b56c784b1296fcb80c1c9",
    id: "spa-RVR1960:1Kgs",
    name: "1 Reyes",
    order: 11,
    testament: "OT",
    verses: 22,
  },
  {
    _id: "5d9b56c784b1296fcb80c1c0",
    id: "spa-RVR1960:Exod",
    name: "Éxodo",
    order: 2,
    testament: "OT",
    verses: 40,
  },
  {
    _id: "5d9b56c784b1296fcb80c1ea",
    id: "spa-RVR1960:Acts",
    name: "Hechos",
    order: 44,
    testament: "NT",
    verses: 28,
  },
  {
    _id: "5d9b56c784b1296fcb80c1e8",
    id: "spa-RVR1960:Luke",
    name: "S. Lucas",
    order: 42,
    testament: "NT",
    verses: 24,
  },
  {
    _id: "5d9b56c784b1296fcb80c1e5",
    id: "spa-RVR1960:Mal",
    name: "Malaquías",
    order: 39,
    testament: "OT",
    verses: 4,
  },
  {
    _id: "5d9b56c784b1296fcb80c1e7",
    id: "spa-RVR1960:Mark",
    name: "S. Marcos",
    order: 41,
    testament: "NT",
    verses: 16,
  },
  {
    _id: "5d9b56c784b1296fcb80c1f9",
    id: "spa-RVR1960:Jas",
    name: "Santiago",
    order: 59,
    testament: "NT",
    verses: 5,
  },
  {
    _id: "5d9b56c784b1296fcb80c1fa",
    id: "spa-RVR1960:1Pet",
    name: "1 Pedro",
    order: 60,
    testament: "NT",
    verses: 5,
  },
  {
    _id: "5d9b56c784b1296fcb80c1ee",
    id: "spa-RVR1960:Gal",
    name: "Gálatas",
    order: 48,
    testament: "NT",
    verses: 6,
  },
  {
    _id: "5d9b56c784b1296fcb80c1fc",
    id: "spa-RVR1960:1John",
    name: "1 Juan",
    order: 62,
    testament: "NT",
    verses: 5,
  },
  {
    _id: "5d9b56c784b1296fcb80c1c8",
    id: "spa-RVR1960:2Sam",
    name: "2 Samuel",
    order: 10,
    testament: "OT",
    verses: 24,
  },
  {
    _id: "5d9b56c784b1296fcb80c1c2",
    id: "spa-RVR1960:Num",
    name: "Números",
    order: 4,
    testament: "OT",
    verses: 36,
  },
  {
    _id: "5d9b56c784b1296fcb80c1ce",
    id: "spa-RVR1960:Neh",
    name: "Nehemías",
    order: 16,
    testament: "OT",
    verses: 13,
  },
  {
    _id: "5d9b56c784b1296fcb80c1d1",
    id: "spa-RVR1960:Ps",
    name: "Salmos",
    order: 19,
    testament: "OT",
    verses: 150,
  },
  {
    _id: "5d9b56c784b1296fcb80c1dd",
    id: "spa-RVR1960:Obad",
    name: "Abdías",
    order: 31,
    testament: "OT",
    verses: 1,
  },
  {
    _id: "5d9b56c784b1296fcb80c1e9",
    id: "spa-RVR1960:John",
    name: "S.Juan",
    order: 43,
    testament: "NT",
    verses: 21,
  },
  {
    _id: "5d9b56c784b1296fcb80c1fe",
    id: "spa-RVR1960:3John",
    name: "3 Juan",
    order: 64,
    testament: "NT",
    verses: 1,
  },
  {
    _id: "5d9b56c784b1296fcb80c1ef",
    id: "spa-RVR1960:Eph",
    name: "Efesios",
    order: 49,
    testament: "NT",
    verses: 6,
  },
  {
    _id: "5d9b56c784b1296fcb80c1f1",
    id: "spa-RVR1960:Col",
    name: "Colosenses",
    order: 51,
    testament: "NT",
    verses: 4,
  },

  {
    _id: "5d9b56c784b1296fcb80c1f2",
    id: "spa-RVR1960:1Thess",
    name: "1 Tesalonicenses",
    order: 52,
    testament: "NT",
    verses: 5,
  },
  {
    _id: "5d9b56c784b1296fcb80c1f5",
    id: "spa-RVR1960:2Tim",
    name: "2 Timoteo",
    order: 55,
    testament: "NT",
    verses: 4,
  },
  {
    _id: "5d9b56c784b1296fcb80c1d5",
    id: "spa-RVR1960:Isa",
    name: "Isaías",
    order: 23,
    testament: "OT",
    verses: 66,
  },
  {
    _id: "5d9b56c784b1296fcb80c1db",
    id: "spa-RVR1960:Joel",
    name: "Joel",
    order: 29,
    testament: "OT",
    verses: 3,
  },
  {
    _id: "5d9b56c784b1296fcb80c1f4",
    id: "spa-RVR1960:1Tim",
    name: "1 Timoteo",
    order: 54,
    testament: "NT",
    verses: 6,
  },
  {
    _id: "5d9b56c784b1296fcb80c200",
    id: "spa-RVR1960:Rev",
    name: "Apocalipsis",
    order: 66,
    testament: "NT",
    verses: 22,
  },
  {
    _id: "5d9b56c784b1296fcb80c1ff",
    id: "spa-RVR1960:Jude",
    name: "Judas",
    order: 65,
    testament: "NT",
    verses: 1,
  },
];
