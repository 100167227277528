import styled from "styled-components";

export const DisplayOverlay = styled.div<{
  index?: number;
  background?: string;
  opacity?: string;
}>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ index }) => (index ? index : 3)};
  cursor: default;
  width: 100%;
  height: 100%;
  background: ${({ background }) => (background ? background : "inherit")};
  opacity: ${({ opacity }) => (opacity ? opacity : "1")};
`;

export const LogoWrapper = styled.div`
  width: 100&;
  background: #ffffff;

  @media (min-width: 500px) {
    display: none;
  }
`;

export const Logo = styled.img`
  position: relative;
  display: block;
  margin: 0 auto;
  width: 120px;
`;
