import React, { useEffect, useState } from "react";
import Navbar from "../navNar";
import styled from "styled-components";
import MainMessage from "../mainMessage";
import Footer from "../footer";
import Media from "../media";
import News from "../new";
import Short from "../short";
import { shorts } from "../../utils/shortsData";
import { news } from "../../utils/newsData";
import { musics } from "../../utils/musicData";
import { useNavigate } from "react-router-dom";
import { Logo, LogoWrapper } from "../styles/shared";
import { keysToSnake, toSnake } from "../../helpers/humps";
import { useWindowSize } from "../../hooks/windowHook";
import { detectOS } from "../../utils/home";
import { useAnalytics } from "../../hooks/analyticsHook";

declare global {
  interface Window {
    analytics: any;
    opera: any;
    MSStream: any;
  }
}

const Layout = styled.main`
  background: #800040;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 113px !important;
  padding-left: 32px;
  padding-right: 32px;
  background: #ffffff;
  padding-bottom: 80px;

  #container {
    color: #999;
    text-transform: uppercase;
    font-size: 38px;
    font-weight: 700;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;

    span {
      text-transform: initial;

      @media (max-width: 500px) {
        font-size: 18px;
      }
    }
  }

  #flip {
    height: 44px;
    overflow: hidden;
    width: 300px;
    margin-top: 16px;
  }

  #flip > div > div {
    color: #ffffff;
    margin-bottom: 46px;
    display: inline-flex;
    height: 100% !important;
  }

  #flip div:first-child {
    animation: show 7s linear infinite;
  }

  #flip div {
    position: relative;
    div {
      background: #42c58a;
      min-width: 100%;
      text-align: center;
      cursor: pointer;
    }
  }
  #flip div:first-child div {
    background: #4ec7f3;
  }

  #flip div:nth-child(2) div {
    background: blue;
  }

  #flip div:last-child div {
    background: #dc143c;
  }

  .text {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  @keyframes show {
    0% {
      margin-top: -270px;
    }
    5% {
      margin-top: -180px;
    }
    33% {
      margin-top: -180px;
    }
    38% {
      margin-top: -90px;
    }
    66% {
      margin-top: -90px;
    }
    71% {
      margin-top: 0px;
    }
    99% {
      margin-top: 0px;
    }
    100% {
      margin-top: -270px;
    }
  }

  @media (max-width: 500px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px !important;
  }
`;

const Overlay = styled.div`
  position: absolute;
  background: #ffffff;
  bottom: 19px;
  height: 14px;
  width: 300px;
`;

const Home = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { width } = useWindowSize();
  const isMobile = width <= 500;
  const tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (!!width) {
      track(
        "Page Viewed",
        keysToSnake({
          name: "Home",
          timeZone: tz,
          mobile: isMobile,
          os: detectOS(),
        })
      );
    }
  }, [width]);

  /*   useEffect(() => {
    if (typeof window !== undefined && navigator.geolocation && width > 0) {
      navigator?.geolocation?.getCurrentPosition(
        (position) => {
           track(
              "Page Viewed",
              keysToSnake({
                name: "Home",
                timeZone: tz,
                mobile: isMobile,
                latitude: position?.coords?.latitude,
                longitude: position?.coords?.longitude,
              })
            );
         },
        (error) => {
         track(
              "Page Viewed",
              keysToSnake({
                name: "Home",
                timeZone: tz,
                mobile: isMobile,
                geolocation: "denied",
              })
            );
         }
      );
    } else {
   
    track(
          "Page Viewed",
          keysToSnake({
            name: "Home",
            timeZone: tz,
            mobile: isMobile,
            geolocation: "not compatible",
          })
        );
      }
  }, [width]); */

  return (
    <Layout>
      {/*      <MainMessage /> */}
      <Navbar />
      <LogoWrapper>
        <Logo
          src="https://res.cloudinary.com/dlcgloggr/image/upload/v1697739287/logo-19-10_nyh2m0.png"
          alt="logo"
          width={80}
          onClick={() => navigate("/")}
        />
      </LogoWrapper>
      <Container>
        <div id="container">
          {/*  <Overlay /> */}
          <span>Publicaré tu grandeza. (Salmos 145:6)</span>
          <div id="flip">
            <div onClick={() => navigate("/noticias")}>
              <div className="text">Noticias</div>
            </div>
            <div onClick={() => navigate("/musica")}>
              <div className="text">Música</div>
            </div>
            <div onClick={() => navigate("/reflexiones")}>
              <div className="text">Reflexiones</div>
            </div>
          </div>
          <span style={{ marginTop: "16px" }}>Destacados de la semana!</span>
        </div>
        <Media
          id={musics?.[0]?.id}
          autoPlay={musics?.[0]?.autoplay}
          seconds={musics?.[0]?.seconds}
          featured
          title={musics?.[0]?.title}
          currentVideo={musics?.[0]}
        />
        <Short
          featuredTitle={shorts[0]?.title}
          text={shorts[0]?.text}
          author={shorts[0]?.author}
          date={shorts[0]?.date}
          featured
        />
        <News
          featuredTitle={news[0]?.title}
          srcImg={news[0]?.image}
          text={news[0]?.text}
          featured
        />
      </Container>
      <Footer />
    </Layout>
  );
};

export default Home;
