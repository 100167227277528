import React, { useEffect } from "react";
import Navbar from "../navNar";
import styled from "styled-components";
import MainMessage from "../mainMessage";
import Footer from "../footer";
import New from "../new";
import { news } from "../../utils/newsData";
import { useNavigate } from "react-router-dom";
import { Logo, LogoWrapper } from "../styles/shared";
import { keysToSnake } from "../../helpers/humps";
import { useWindowSize } from "../../hooks/windowHook";
import { detectOS } from "../../utils/home";
import { useAnalytics } from "../../hooks/analyticsHook";

const Layout = styled.main`
  background: #800040;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 113px !important;
  background: #ffffff;
  padding-bottom: 80px;

  > h1 {
    font-size: 40px;
    margin-bottom: 0px;
    margin-top: 0;
  }

  @media (max-width: 500px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px !important;
  }
`;

const News = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { width } = useWindowSize();
  const isMobile = width <= 500;
  const tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (!!width) {
      track(
        "Page Viewed",
        keysToSnake({
          name: "News",
          timeZone: tz,
          mobile: isMobile,
          os: detectOS(),
        })
      );
    }
  }, [width]);

  return (
    <Layout>
      <MainMessage />
      <Navbar />
      <LogoWrapper>
        <Logo
          src="https://res.cloudinary.com/dlcgloggr/image/upload/v1697739287/logo-19-10_nyh2m0.png"
          alt="logo"
          width={80}
          onClick={() => navigate("/")}
        />
      </LogoWrapper>
      <Container>
        <h1>Noticias</h1>
        {news?.map((newsItem, index) => (
          <New
            key={index}
            title={newsItem.title}
            srcImg={newsItem.image}
            text={newsItem.text}
            line={index < news?.length - 1}
          />
        ))}
      </Container>
      <Footer />
    </Layout>
  );
};

export default News;
