export const news = [
  {
    title:
      "¿Cómo actuó? Diputado Británico Cristiano Rechaza Juramento sobre la Biblia Citando Razones Bíblicas",
    image:
      "https://res.cloudinary.com/dlcgloggr/image/upload/v1722000614/tim_farron_pzhsox.png",
    text: `Durante la inauguración del Parlamento británico, Tim Farron, un diputado cristiano reelegido, optó por no jurar sobre la Biblia, alegando que sus promesas son ante una autoridad superior al Rey de Inglaterra. Esta decisión generó controversia, ya que el juramento es necesario para participar en debates, votar y recibir salario. Farron, que había prestado juramento en siete ocasiones anteriores, decidió afirmar su lealtad al rey sin usar la Biblia, destacando su postura de fe. Citando Mateo 5:34-37, explicó que Jesús enseñó a no jurar por nada, sino a dejar que el "Sí" sea "Sí" y el "No" sea "No". Para Farron, jurar sobre la Biblia sugiere que algunas promesas son más sagradas que otras, cuando los cristianos deben decir la verdad en todo momento. Expresó que cada palabra es escuchada por Dios y pidió a los cristianos orar por una política de verdad compasiva, citando Efesios 4:15. Farron también pidió oración para que los políticos cristianos sean coherentes y valientes en decir la verdad del Evangelio. En el Parlamento, los diputados pueden elegir sobre qué texto religioso jurar, como la Biblia, el Corán, el Bhagavad Gita o la Torá.`,
    date: "26/07/2024",
  },
  {
    title: "Trascendiendo Épocas: La Biblia como el Bestseller Eterno en 2024",
    image:
      "https://res.cloudinary.com/dlcgloggr/image/upload/v1704977817/bible_kfoe3o.jpg",
    text: "En 2024, la Biblia superó los 100 millones de ejemplares impresos en 100 países y 23 idiomas. Aunque la tecnología ha facilitado el acceso a las Sagradas Escrituras a través de plataformas como YouVersion, un informe revela que en el siglo XXI se imprimen alrededor de 80 millones de Biblias al año. A pesar de no ser un libro actual, la Biblia fue el más vendido en 2022, con más de 100 millones de ejemplares vendidos en 100 países y 23 idiomas. A lo largo de los siglos, se estima que se han vendido entre 5 y 7 mil millones de copias. En 2021, según el Libro Guinness de los Récords, se alcanzó esta cifra, sumando las estimaciones anuales de Sociedades Bíblicas Unidas. Misioneros y organizaciones dedicadas a transcribir y distribuir la Palabra contribuyeron a este logro, pero el reconocimiento se atribuye a Cristo, demostrando que su Palabra es atemporal y trasciende fronteras en un mundo con abundante contenido no edificante.",
    date: "11/01/2024",
  },
  {
    title:
      "Terror en Nigeria: Oleada de ataques deja más de 160 víctimas en comunidades cristianas",
    image:
      "https://res.cloudinary.com/dlcgloggr/image/upload/v1703717949/97_np4zjy.jpg",
    text: "Durante la Navidad, entre el 24 y el 25 de diciembre, hombres armados atacaron más de veinte comunidades en Plateau, Nigeria, incendiando la mayoría de las viviendas y dejando al menos 140 muertos, según Amnistía Internacional. La violencia se concentró en áreas cristianas como Plateau, Bokkos y Barkin-Ladi. Morning Star News elevó la cifra de víctimas a 160, incluyendo varios pastores cuyas casas fueron destruidas. Entre las víctimas se encuentra el pastor Solomon Gushe y nueve miembros de su familia. Dawzino Mallau, residente del condado de Bokkos, lamentó la pérdida de pastores y sus familias durante los ataques, perpetrados por cientos de terroristas mientras los cristianos se preparaban para programas navideños. La mayoría de los fallecidos eran mujeres, niños y ancianos que no pudieron escapar. Alfred Mashat, residente de Bokkos, reportó la destrucción de cientos de casas y afirmó que unos 160 cristianos fueron asesinados, sugiriendo la posible colaboración de pastores musulmanes fulani armados en los ataques.",
    date: "27/12/2023",
  },
  {
    title:
      "Más allá de la ciencia: La trayectoria del Dr. Ming Wang desde el ateísmo hasta la fe",
    image:
      "https://res.cloudinary.com/dlcgloggr/image/upload/v1702040403/dr_ateo_un2bo9.png",
    text: "El Dr. Ming Wang emigró a Estados Unidos desde China con apenas 50 dólares, transformándose de inmigrante pobre a cirujano ocular internacionalmente reconocido. Inicialmente sin contactos y con limitado dominio del inglés, encontró libertad en su nueva patria. A pesar de ser inicialmente un ateo dedicado a la ciencia, su perspectiva cambió al estudiar la compleja estructura del ojo humano durante sus doctorados en el MIT y Harvard. La revelación de la intricada creación del ojo llevó al Dr. Wang a cuestionar la posibilidad de su formación por casualidad. La respuesta de un profesor, comparando la complejidad del ojo con la fabricación aleatoria de un automóvil, lo llevó a aceptar la existencia de un creador. Este cambio espiritual lo condujo a abrazar la fe cristiana, creyendo en Jesucristo como el Hijo de Dios. Posteriormente, el Dr. Wang fundó el Wang Vision Institute y dedicó su carrera a realizar más de 55,000 correcciones de visión con láser, incluyendo a más de 4,000 médicos. Además, estableció una organización humanitaria que proporciona cirugías oculares a niños huérfanos en todo el mundo. Su testimonio busca inspirar a los jóvenes a reconciliar la ciencia y la fe, demostrando que no es necesario renunciar a una para abrazar la otra.",
    date: "08/12/2023",
  },
  {
    title: "Los países donde es más difícil ser cristiano",
    image:
      "https://res.cloudinary.com/dlcgloggr/image/upload/v1699662313/1484741886_921746_1484743657_noticia_normal_oowh53.jpg",
    text: "El informe sobre la persecución cristiana en 2023 destaca que Afganistán ocupa ahora el primer lugar en la Lista Mundial de la Persecución, superando a Corea del Norte. Nigeria lidera en términos de cristianos asesinados, secuestrados, acosados sexualmente y en ataques a hogares y negocios. China tiene la mayor cantidad de ataques a iglesias. Myanmar subió en la clasificación debido al aumento de la violencia después del golpe de Estado. Qatar prohibió la reapertura de iglesias durante el confinamiento por la COVID-19. Indonesia y Cuba experimentaron cambios significativos en su posición debido a ataques y acciones contra líderes cristianos. En general, los 10 países principales en la lista no cambiaron con respecto al año anterior. Somalia, Libia, Eritrea e India mantuvieron sus posiciones. Nigeria sorprendió al ser eliminada de la lista de Países de Especial Preocupación de EE. UU. en 2022, pero sigue siendo un punto crítico según Puertas Abiertas. En total, 360 millones de cristianos viven en lugares con niveles de persecución muy altos o extremos, afectando a 1 de cada 7 cristianos en todo el mundo.",
    date: "10/11/2023",
  },
  {
    title:
      "Predicador Cristiano detenido por mensaje Bíblico cerca de clínica de aborto",
    image:
      "https://res.cloudinary.com/dlcgloggr/image/upload/v1698708677/stephen_green_nnnsqi.jpg",
    text: "Un predicador cristiano de Gales, Stephen Green, enfrenta la posibilidad de prisión por sostener un cartel con un versículo bíblico cerca de una clínica de aborto en Ealing, Londres. La clínica tiene una Orden de Protección de Espacios Públicos que prohíbe la protesta y oración en su área. Green fue citado bajo la Ley de conducta policial, delincuencia y comportamiento antisocial y se enfrenta a una pena de prisión de seis meses y una multa. Se considera que este caso es un ataque a la libertad de expresión y una lucha por la defensa de los derechos religiosos en zonas de amortiguamiento. El Christian Legal Centre apoya a Green en su defensa y critica la criminalización de la disidencia sobre el aborto en el Reino Unido.",
    date: "30/10/2023",
  },
  {
    title: "El Fracaso de la Inteligencia Israelí en Gaza",
    image:
      "https://res.cloudinary.com/dlcgloggr/image/upload/v1697812080/gaza_sow9ve.jpg",
    text: "La inteligencia israelí enfrenta un desafío importante después del reciente ataque mortal de Hamás. A pesar de tener una extensa red de informantes y espías en Gaza, así como la capacidad de interceptar las comunicaciones de Hamás, no pudo prevenir el ataque, marcando un grave fracaso. En los últimos días, los servicios de inteligencia han trabajado intensamente para ayudar al ejército a localizar a los rehenes de Hamás y a identificar los lugares donde se esconden. Han solicitado más tiempo para asegurarse de que las fuerzas terrestres entren en Gaza con información precisa en lugar de moverse a ciegas entre los escombros. Además, enfrentan el desafío de posibles emboscadas y trampas por parte de los grupos militantes, especialmente en los túneles de Hamás. La inteligencia israelí busca descubrir la ubicación de estas trampas para proteger a las fuerzas en el terreno.",
    date: "20/10/2023",
  },
  {
    title: "El Iron Dome y su Papel en la Seguridad de Israel",
    image:
      "https://res.cloudinary.com/dlcgloggr/image/upload/v1697497514/israel-gaza-iron-dome_dz6ihx.webp",
    text: "El sistema de defensa antimisiles Iron Dome de Israel es altamente efectivo y ha tenido un éxito del 95,6% al interceptar cohetes en ataques pasados. Se desarrolló a partir de 2007 y se ha actualizado varias veces desde su implementación en 2011. El Iron Dome detecta cohetes entrantes mediante radar, determina si son una amenaza y, en caso afirmativo, dispara misiles para destruirlos en el aire. Hay 10 baterías Iron Dome en Israel, y el sistema es altamente transportable. A diferencia de los sistemas de defensa contra misiles balísticos, el Iron Dome se enfoca en cohetes no guiados disparados a baja altura, como los lanzados por grupos militantes en Gaza. Cada misil interceptor cuesta alrededor de $40,000, y los Estados Unidos han gastado más de $1.500 millones en el programa y la investigación relacionada. Tras un reciente ataque, es probable que Israel solicite más interceptores y asistencia militar adicional de los Estados Unidos.",
    date: "16/10/2023",
  },
  {
    title:
      "Nayib Bukele se une al rechazo mundial del ataque de Hamás a Israel",
    image:
      "https://res.cloudinary.com/dlcgloggr/image/upload/v1697115346/bukele.png",
    text: "Nayib Bukele, presidente de El Salvador y de ascendencia palestina, condenó el ataque de Hamás a Israel y llamó a la desaparición de Hamás, alegando que no representa a los palestinos. Bukele afirmó que apoyar a Hamás sería un error, comparándolo con respaldar a una pandilla violenta como la MS13 en El Salvador. Hizo referencia a su política de combate contra las pandillas en El Salvador y sugirió que los palestinos deberían seguir un camino similar, deshaciéndose de Hamás para permitir que la gente buena prospere. El ataque de Hamás a Israel llevó a una declaración de estado de guerra por parte de Israel, con un alto número de víctimas en ambos lados y un conflicto en curso.",
    date: "12/10/2023",
  },
  {
    title:
      "Confirman la Muerte de Dos Altos Líderes de Hamas en el Conflicto en Gaza",
    image:
      "https://res.cloudinary.com/dlcgloggr/image/upload/v1696965293/775683-000-33xx6ev_lqo1lk.jpg",
    text: "El Ejército de Israel anunció la muerte de dos altos funcionarios del grupo Hamas, luego de una serie de ataques aéreos. Se trata de Jawad Abu Shamala, ministro de Economía de la Franja de Gaza y administrador los fondos de la organización, y de Zakaria Abu Ma'amer, uno de los altos miembros del Buró Político de la organización. En una nueva ofensiva de Hamas en Gaza, Israel responde con estado de alerta máxima. La comunidad global se ve conmocionada, con Estados Unidos, China y Rusia en el centro de atención.",
    date: "10/10/2023",
  },
  {
    title: "La Responsabilidad Cristiana: Orar por la Paz en Israel",
    image:
      "https://res.cloudinary.com/dlcgloggr/image/upload/v1696859003/israel_pivwtu.jpg",
    text: 'El Primer Ministro de Israel, Benjamín Netanyahu, declaró "Estamos en guerra" después del sorpresivo ataque de Hamás desde la Franja de Gaza el 7 de octubre. Hasta ahora, se informa que 970 israelíes, incluyendo dos argentinos, han muerto en los ataques y cientos resultaron heridos. El ejército israelí está evacuando a todos los israelíes que viven cerca de la Franja de Gaza y combatiendo a milicianos palestinos. Han recuperado el control de 29 lugares tomados por Hamás, pero continúan los combates en ocho puntos. La Alianza Cristiana de Iglesias Evangélicas de Argentina repudia el atentado y llama a la oración por la paz y el cese de la violencia en Israel.',
    date: "09/10/2023",
  },
  {
    title:
      "NIGERIA: En los últimos cuatro años, se han reportado 23 asesinatos de pastores y el cierre de 200 iglesias.",
    image:
      "https://asset.cloudinary.com/dlcgloggr/2beb1314b20f491faef2105f8b47fed0",
    text: "En los últimos cuatro años, en el estado de Kaduna, Nigeria, terroristas han perpetrado 23 asesinatos de pastores y han forzado el cierre de más de 200 iglesias, según lo informado por el reverendo Joseph Hayab, presidente de la Asociación Cristiana de Nigeria en Kaduna. Durante una reunión con el comisionado de policía Musa Garba y otros líderes religiosos, Hayab también señaló que más de 215 cristianos permanecen secuestrados por bandidos en el bosque de Birnin Gwari. En la misma reunión, el reverendo Yunusa Nmadu y otros pastores instaron a tomar medidas contra los líderes religiosos que promueven el discurso de odio y a abordar el problema del abuso de sustancias vinculado a actividades criminales en el estado. A pesar de la creciente violencia y persecución, el comisionado Garba enfatizó que la seguridad es responsabilidad de todos y se esfuerza por fortalecer la relación entre la policía y los líderes religiosos para buscar soluciones.El estado de Kaduna ha sido gravemente afectado por actividades de bandidos en los últimos años, resultando en numerosos cierres de iglesias y secuestros. La persecución de los cristianos en Nigeria es severa, y el país representa la mayoría de las víctimas de asesinatos religiosos en el mundo.A pesar de las preocupaciones de grupos de derechos humanos, el gobierno nigeriano ha rechazado las afirmaciones de que la violencia tiene motivaciones religiosas, argumentando que se trata de enfrentamientos entre agricultores y pastores.",
    date: "08/10/2023",
  },
];
