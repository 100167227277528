import React, { useEffect, useState } from "react";
import Navbar from "../navNar";
import styled from "styled-components";
import MainMessage from "../mainMessage";
import Footer from "../footer";
import { beliefsData } from "../../utils/beliefsData";
import { DisplayOverlay, Logo, LogoWrapper } from "../styles/shared";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../hooks/windowHook";
import { keysToSnake } from "../../helpers/humps";
import { detectOS } from "../../utils/home";
import { useAnalytics } from "../../hooks/analyticsHook";

const Layout = styled.main`
  background: #800040;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 113px !important;
  padding-left: 32px;
  padding-right: 32px;
  background: #ffffff;

  h1 {
    font-size: 40px;
    margin-bottom: 20px;
    margin-top: 0;
  }

  @media (max-width: 500px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px !important;
  }
`;

const BeliefsContainer = styled.div`
  h2 {
    font-size: 22px;
    text-transform: initial;
  }

  p {
    margin-top: 0;
    font-size: 16px;

    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  > div {
    margin-bottom: 120px;
  }
`;

const VersesContainer = styled.div<{ showVerses: boolean }>`
  color: blue;
  cursor: pointer;
  position: relative;
  position: absolute;
  z-index: 999;
  > div {
    display: ${({ showVerses }) => (showVerses ? "grid" : "none")};
  }
`;

const VersesModal = styled.div`
  height: max-content;
  width: max-content;
  background: #ffffff;
  position: absolute;
  top: 20px;
  left: 130px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 8px;
  gap: 16px;
  z-index: 999;

  span {
    font-size: 14px;
  }

  @media (max-width: 500px) {
    left: 0;
    margin: 0 auto;
    top: 30px;
    gap: 8px;
  }
`;

const Beliefs = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const [showVerses, setShowVerses] = useState<any>(-1);
  const [showOverlay, setShowOverlay] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width <= 500;
  const tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (!!width) {
      track(
        "Page Viewed",
        keysToSnake({
          name: "Belief",
          timeZone: tz,
          mobile: isMobile,
          os: detectOS(),
        })
      );
    }
  }, [width]);

  /*   useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (showOverlay) {
          setShowOverlay(false);
          setShowVerses(-1);
        }
      });
    }
  }, [showOverlay]); */
  return (
    <Layout>
      <MainMessage />
      <Navbar />
      <LogoWrapper>
        <Logo
          src="https://res.cloudinary.com/dlcgloggr/image/upload/v1697739287/logo-19-10_nyh2m0.png"
          alt="logo"
          width={80}
          onClick={() => navigate("/")}
        />
      </LogoWrapper>
      <Container>
        <h1>¿En qué creo?</h1>
        <BeliefsContainer>
          {beliefsData?.map((belief, index) => (
            <div key={index}>
              <div>
                <h2>{belief.title}</h2>
              </div>
              <p>{belief.description}</p>
              <VersesContainer
                onClick={() => {
                  setShowVerses(index);
                  setShowOverlay(true);
                }}
                showVerses={index === showVerses}
              >
                Ver Versículos
                <VersesModal>
                  {belief.verses?.map((verse) => (
                    <span>{verse}</span>
                  ))}
                </VersesModal>
              </VersesContainer>
            </div>
          ))}
        </BeliefsContainer>
      </Container>
      <Footer />
      {showOverlay && (
        <DisplayOverlay
          className="overlay"
          onClick={() => {
            setShowOverlay(!showOverlay);
            setShowVerses(-1);
          }}
          background="#000000"
          opacity="0.6"
        />
      )}
    </Layout>
  );
};

export default Beliefs;
