import { keysToSnake } from "../helpers/humps";

export type SegmentEventType =
  | "Link Copied Music"
  | "Page Viewed"
  | "Suggestion"
  | "Link Copied Short"
  | "Email Contact";

export const useAnalytics = () => {
  const track = (event: SegmentEventType, properties: any = {}) => {
    if (typeof window !== undefined) {
      window.analytics?.track(
        event,
        keysToSnake({
          ...properties,
        })
      );
    }
  };

  return { track };
};
